/* eslint-disable no-underscore-dangle */

import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial';

import * as PaneUtilities from './paneutilities';

import {
    mapParseType,
    mapAttribute,
    mapAttributeName,
    mapNotifyChange,
} from './CameraView.Helpers.Attributes';

export default {
    methods: {
        /**
         * Applies material settings from custom attributes.
         */
        applyMaterialSettings(custom, materials, materialsOnly, meshes, changes) {
            const materialFps = 30;
            const materialFrames = 15;

            // Apply component specific material overrides.
            Object.keys(custom).forEach((k) => {
                if (k.startsWith('three-material-use-')) {
                    const attribute = custom[k];
                    const set = custom[k.replace(/^three-material-use-/, 'three-material-set-')];

                    const materialName = custom[k.replace(/^three-material-use-/, 'three-material-material-')];
                    const meshName = custom[k.replace(/^three-material-use-/, 'three-material-mesh-')];

                    let applied = false;

                    const resolvedAttribute = mapAttributeName[attribute] || attribute;

                    if (meshName) {
                        const applyToMesh = materials[meshName];
                        if (applyToMesh) {
                            const applyTo = applyToMesh[materialName];

                            if (applyTo && set) {
                                applied = true;

                                applyTo.forEach((material) => {
                                    if (mapAttribute[attribute] && mapParseType[mapAttribute[attribute]]) {
                                        if (changes) {
                                            const oldValue = PaneUtilities.getValuePath(material, resolvedAttribute);

                                            changes.push({
                                                attribute: resolvedAttribute,
                                                oldValue,
                                                revert: () => {
                                                    if (typeof oldValue === 'boolean') {
                                                        PaneUtilities.setValuePath(material, resolvedAttribute, oldValue);
                                                    } else {
                                                        // Apply animations. Hopefully animation system can sort out short term animations.
                                                        this.animateCurrent(
                                                            `${meshName}-${material.id}-${resolvedAttribute}`,
                                                            material,
                                                            resolvedAttribute,
                                                            oldValue,
                                                            materialFps,
                                                            materialFrames,
                                                        );
                                                    }
                                                },
                                            });
                                        }

                                        const newValue = mapParseType[mapAttribute[attribute]](set, material, this.scene);

                                        // console.log('Set', resolvedAttribute, newValue, materialName, meshName);

                                        if (newValue == null) {
                                            // Nothing.
                                        } else if (typeof newValue === 'boolean') {
                                            PaneUtilities.setValuePath(material, resolvedAttribute, newValue);
                                        } else {
                                            if (material.metadata == null) {
                                                material.metadata = {};
                                            }

                                            if (material.metadata.animatedValues == null) {
                                                material.metadata.animatedValues = {};
                                            }

                                            material.metadata.animatedValues[resolvedAttribute] = newValue;

                                            this.animateCurrent(
                                                `${meshName}-${material.id}-${resolvedAttribute}`,
                                                material,
                                                resolvedAttribute,
                                                newValue,
                                                materialFps,
                                                materialFrames,
                                            );
                                        }

                                        if (mapNotifyChange[mapAttribute[attribute]] || mapNotifyChange[mapAttributeName[attribute]]) {
                                            if (meshes[material.id]) {
                                                meshes[material.id].forEach((mesh) => {
                                                    if (mesh.onMaterialChangedObservable.hasObservers()) {
                                                        mesh.onMaterialChangedObservable.notifyObservers(mesh);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }

                    if (!applied) {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const meshMaterialKey of Object.keys(materials)) {
                            const meshMaterial = materials[meshMaterialKey];

                            const applyTo = meshMaterial[materialName];

                            if (applyTo && set) {
                                // eslint-disable-next-line no-restricted-syntax
                                for (const material of applyTo) {
                                    if (mapAttribute[attribute] && mapParseType[mapAttribute[attribute]]) {
                                        if (changes) {
                                            const oldValue = PaneUtilities.getValuePath(material, resolvedAttribute);

                                            changes.push({
                                                attribute: resolvedAttribute,
                                                oldValue,
                                                revert: () => {
                                                    // Direct method.
                                                    // this.setValuePath(material, resolvedAttribute, oldValue),

                                                    // Apply animations. Hopefully animation system can sort out short term animations.
                                                    this.animateCurrent(
                                                        `${meshMaterialKey}-${material.uniqueId}-${resolvedAttribute}`,
                                                        material,
                                                        resolvedAttribute,
                                                        oldValue,
                                                        materialFps,
                                                        materialFrames,
                                                    );
                                                },
                                            });
                                        }

                                        // Direct settings:
                                        // this.setValuePath(material, resolvedAttribute, mapParseType[mapAttribute[attribute]](set));

                                        const newValue = mapParseType[mapAttribute[attribute]](set, material, this.scene);

                                        if (newValue == null) {
                                            // Nothing.
                                        } else if (typeof newValue === 'boolean') {
                                            PaneUtilities.setValuePath(material, resolvedAttribute, newValue);
                                        } else {
                                            this.animateCurrent(
                                                `${meshMaterialKey}-${material.uniqueId}-${resolvedAttribute}`,
                                                material,
                                                resolvedAttribute,
                                                newValue,
                                                materialFps,
                                                materialFrames,
                                            );
                                        }

                                        if (mapNotifyChange[mapAttribute[attribute]] || mapNotifyChange[mapAttributeName[attribute]]) {
                                            if (meshes[material.id]) {
                                                meshes[material.id].forEach((mesh) => {
                                                    if (mesh.onMaterialChangedObservable.hasObservers()) {
                                                        mesh.onMaterialChangedObservable.notifyObservers(mesh);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });

            // Apply settings tune-ups.
            Object.values(materialsOnly).forEach((material) => {
                const m = Array.isArray(material) ? material : [material];

                m.forEach((one) => {
                    let alpha = one.alpha;

                    if (one.metadata?.animatedValues?.alpha) {
                        alpha = one.metadata?.animatedValues?.alpha;
                    }

                    if (alpha < 1) {
                        if (one.subSurface.isRefractionEnabled) {
                            one.needDepthPrePass = true;
                        } else {
                            one.needDepthPrePass = false;
                        }
                        one.transparencyMode = PBRMaterial.PBRMATERIAL_ALPHABLEND;
                    } else {
                        one.needDepthPrePass = false;
                        one.transparencyMode = PBRMaterial.MATERIAL_OPAQUE;
                    }
                });
            });
        },

        /**
         * Apply material overrides.
         */
        applyMaterials() {
            // First, undo all previous changes.
            if (this.materialChanges) {
                this.materialChanges.forEach((change) => {
                    if (change.revert) {
                        change.revert();
                    }
                });

                this.materialChanges = null;
            }

            // Collate all materials.
            const materials = {};
            const materialsOnly = {};
            const meshes = {};

            Object.values(this.placements).forEach((placement) => {
                placement.meshes.forEach((mesh) => {
                    this.collectMaterials(mesh.mesh, materials, materialsOnly, meshes);
                });
            });

            // Scan through the selected state and find material settings overrides.
            this.materialChanges = [];

            this.forAllControllers((controller) => {
                controller.state.forEach((s) => {
                    if (!s.component || !s.component.custom) {
                        return;
                    }

                    const applyToMaterial = s.component.custom['three-material-apply-to'];

                    if (applyToMaterial) {
                        this.applyMaterialSettings(s.component.custom, materials, materialsOnly, meshes, this.materialChanges);
                    }
                });
            });
        },
    },
};
