<template>
    <div class="cz3__navigation"
        :class="{
            'cz3__navigation--with-device-color': deviceColor,
            'cz3__navigation--device-color-toggling': deviceColorToggling,
        }"
    >
        <div class="cz3__navigation__mini cz3__renderer-exclude">
            <div class="cz3__navigation-left">
                <transition name="cz3__fade">
                    <button v-if="previousStep != null && deviceColor === false"
                        id="nav-back"
                        class="cz3__grey-button"
                        :disabled="!canGoBack"
                        @click.prevent="goBack"
                    >
                        {{$t('BACK')}}
                    </button>
                </transition>
            </div>

            <div class="cz3__navigation-center">
                <span>
                    <span v-if="productDisplayName"
                        class="cz3__navigation__product-name">
                        {{productDisplayName}}: {{selectedBundlePrice}}
                    </span>
                    <span v-if="productSize"
                        class="cz3__navigation__product-size">
                        {{productSize}}
                    </span>
                    <span v-if="useLooks"
                        class="cz3__navigation__product-looks">
                        {{$t('PLUS_LOOKS')}}
                    </span>
                </span>
            </div>

            <div class="cz3__navigation-right">
                <transition name="cz3__fade">
                    <button v-if="nextStep != null && deviceColor === false"
                        id="nav-next"
                        class="cz3__black-button cz3__has-tooltip"
                        :disabled="!canGoNext"
                        @click.prevent="goNext"
                    >
                        <span v-if="nextStep === 'addToCart'">
                            {{$t('ADD_TO_CART')}}
                        </span>
                        <span v-else>
                            {{$t('NEXT')}}
                        </span>

                        <transition name="cz3__fade-slow">
                            <span v-if="tooltipTapHereRequested && delayedTooltipsVisible && tooltipTapHereVisible"
                                class="cz3__button-tooltip cz3__button-tooltip--above-left">
                                <span class="cz3__button-tooltip__body">
                                    {{$t('TOOLTIP_TAP_TO_CONTINUE')}}
                                </span>
                            </span>
                        </transition>
                    </button>
                </transition>
            </div>

            <button ref="grip"
                id="nav-grip"
                class="cz3__navigation-grip"
                :aria-label="$t('TOGGLE_DEVICE')"
                @click.prevent="toggleDevice"
            >
            </button>
        </div>

        <transition name="cz3__fade">
            <div v-if="deviceColor"
                class="cz3__navigation-colors"
            >
                <div class="cz3__type--small cz3__type--center">
                    <span v-html="productLongDisplayName"></span>
                </div>

                <div class="cz3__navigation-colors__device-colors">
                    <edit-options-placement
                        :placement="phoneColorPlacement"
                        :controller="viewController"
                        :title="selectedDeviceTitle"
                        @selected="selectedPhoneColor"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__navigation {
            position: absolute;

            display: flex;
            flex-direction: column;

            left: 0;
            bottom: 0;

            width: 100%;

            height: 72px;

            background: rgba(255, 255, 255, 0.95);
            border-radius: 8px 8px 0px 0px;

            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);

            transition: all 0.25s ease-out;

            // Above the thumbnails panel.
            z-index: 1;

            user-select: none;

            @include respond-above(sm) {
                bottom: 24px;
                left: calc(50% - 251px);

                width: $content-large-width;
                height: 80px;

                background: rgba(255, 255, 255, 0.8);

                border-radius: 8px;

                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
            }

            &--with-device-color {
                height: 230px;
                background: #fff;
            }

            &--device-color-toggling {
                overflow: hidden;
            }
        }

        .cz3__navigation__mini {
            flex: 0 0 auto;

            width: 100%;

            display: flex;

            align-items: center;
            justify-content: center;

            height: 72px;
            padding: 0 12px;

            @include respond-above(sm) {
                height: 80px;
            }
        }

        .cz3__navigation-left {
            flex: 1 0 0;

            display: flex;

            align-items: center;
            justify-content: left;
        }

        .cz3__navigation-center {
            flex: 1 0 0;

            display: flex;

            align-items: center;
            justify-content: center;
        }

        .cz3__navigation-right {
            flex: 1 0 0;

            display: flex;

            align-items: center;
            justify-content: flex-end;
        }

        .cz3__navigation-grip {
            position: absolute;

            width: 40px;
            height: 60px;

            top: 0;
            left: 50%;
            transform: translate(-20px, 0);

            border: none;
            background: transparent;

            touch-action: none;

            &:after {
                content: '';

                position: absolute;

                width: 30px;
                height: 4px;

                top: 8px;
                left: 50%;
                transform: translate(-15px, 0);

                border-radius: 4px;
                background: $color-gray-mid;
            }
        }

        .cz3__navigation__product-name {
            font-family: $font-alt;

            font-size: 14px;
            font-weight: 500;

            white-space: nowrap;
        }

        .cz3__navigation__product-size {
            display: block;

            font-family: $font-alt;

            font-size: 14px;
            font-weight: 400;

            white-space: nowrap;

            text-align: center;
        }

        .cz3__navigation__product-looks {
            display: block;

            font-family: $font-alt;

            font-size: 12px;
            font-weight: 400;

            white-space: nowrap;

            text-align: center;
        }

        .cz3__navigation-colors {
            position: relative;

            flex: 1 1 0;

            display: flex;
            flex-direction: column;

            top: -20px;

            > * {
                flex: 0 0 auto;
            }
        }

        .cz3__navigation-colors__device-colors {
            flex: 1 1 0;

            display: flex;
            flex-direction: column;

            justify-content: center;
            align-items: center;
        }
    }
</style>

<script>
    import interact from 'interactjs';

    import EditOptionsPlacement from './EditOptionsPlacement.vue';

    import allStores from '../stores/all';

    import tooltips from './tooltips';

    import * as constants from '../constants';

    export default {
        mixins: [allStores, tooltips],

        components: {
            EditOptionsPlacement,
        },

        data() {
            return {
                deviceColor: false,
                deviceColorToggling: false,
            };
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },

            selectedBundle() {
                return this.customizerStore.selectedBundle;
            },

            productDisplayName() {
                return this.customizerStore.productDisplayName;
            },

            productSize() {
                if (this.selectedBundle == null) {
                    return null;
                }

                return this.selectedBundle.size;
            },

            productLongDisplayName() {
                let html = this.customizerStore.productLongDisplayName;

                html = html.replace('{hard return}', '<br/>');

                return html;
            },

            viewController() {
                return this.customizerStore.viewController;
            },

            selectedDeviceTitle() {
                if (this.viewController == null) {
                    return '';
                }

                const phone = this.viewController.selectedAtPlacement(constants.PLACEMENT_PHONE);

                if (phone == null) {
                    return null;
                }

                if (phone.custom?.['ps-drawer-title']) {
                    return phone.custom?.['ps-drawer-title'];
                }

                const names = this.viewController.componentNames(phone);

                return names.join('');
            },

            selectedBundlePrice() {
                return this.customizerStore.selectedBundlePrice;
            },

            phoneColorPlacement() {
                if (this.viewController == null) {
                    return '';
                }

                const phone = this.viewController.findPlacement(constants.PLACEMENT_PHONE_COLOR);

                return {
                    placementCode: phone.code,
                    placement: phone,
                };
            },

            previousStep() {
                return this.customizerStore.previousStep;
            },

            nextStep() {
                return this.customizerStore.nextStep;
            },

            canGoBack() {
                return this.customizerStore.canGoBack;
            },

            canGoNext() {
                return this.customizerStore.canGoNext;
            },

            tooltipTapHereVisible() {
                return this.tooltipVisible('TAP_HERE_TO_CONTINUE');
            },

            tooltipTapHereRequested() {
                return this.tooltipRequested('TAP_HERE_TO_CONTINUE');
            },

            useLooks() {
                return this.customizerStore.useLooks;
            },
        },

        watch: {
            currentStep() {
                this.stepChanged();
            },
        },

        mounted() {
            this.updateGrip(true);
        },

        beforeUpdate() {
            this.updateGrip(false);
        },

        updated() {
            this.updateGrip(true);
        },

        beforeUnmount() {
            this.updateGrip(false);
        },

        methods: {
            confirmExit() {
                this.appStore.confirmPopup({
                    message: this.$t('CANCEL_BODY'),
                    confirmButton: this.$t('CANCEL_CONFIRM'),
                    cancelButton: this.$t('CANCEL_CANCEL'),
                    confirmAction: () => {
                        this.appStore.confirmPopup();
                        this.customizerStore.globalCancel();
                    },
                    cancelAction: () => {
                        this.appStore.confirmPopup();
                    },
                });
            },

            goBack() {
                if (this.previousStep === constants.STEP_CANCEL) {
                    this.confirmExit();

                    // Return is required to avoid falling through another step after responsive properties were updated.
                    // eslint-disable-next-line no-useless-return
                    return;
                }

                if (this.previousStep === constants.STEP_SELECT_PHONE) {
                    this.customizerStore.setCurrentStep(constants.STEP_SELECT_PHONE);

                    // Return is required to avoid falling through another step after responsive properties were updated.
                    // eslint-disable-next-line no-useless-return
                    return;
                }

                if (this.previousStep === constants.STEP_SELECT_BUNDLE) {
                    this.returnToBundle(constants.STEP_SELECT_BUNDLE);

                    // Return is required to avoid falling through another step after responsive properties were updated.
                    // eslint-disable-next-line no-useless-return
                    return;
                }

                if (this.previousStep === constants.STEP_SELECT_CASE_BUNDLE) {
                    this.returnToBundle(constants.STEP_SELECT_CASE_BUNDLE);

                    // Return is required to avoid falling through another step after responsive properties were updated.
                    // eslint-disable-next-line no-useless-return
                    return;
                }

                if (this.previousStep === constants.STEP_EDIT) {
                    // Also clear the joint selection.
                    this.customizerStore.selectJoint(null);

                    this.customizerStore.setCurrentStep(constants.STEP_EDIT);

                    // Return is required to avoid falling through another step after responsive properties were updated.
                    // eslint-disable-next-line no-useless-return
                    return;
                }

                if (this.previousStep === constants.STEP_EDIT_IMAGE) {
                    this.customizerStore.setCurrentStep(constants.STEP_EDIT_IMAGE);

                    // Return is required to avoid falling through another step after responsive properties were updated.
                    // eslint-disable-next-line no-useless-return
                    return;
                }
            },

            goNext() {
                if (this.nextStep === constants.STEP_EDIT) {
                    if (!this.currentStep?.startsWith('edit')) {
                        if (this.customizerStore.selectedBundle?.bundleStyleCode) {
                            // Track bundle.
                            this.customizerStore.trackAnalyticsEvent({
                                action: 'cyo_select_bundle',
                                label: this.customizerStore.selectedBundle?.bundleStyleCode,
                            });
                        }
                    }

                    this.customizerStore.setCurrentStep(constants.STEP_EDIT);

                    // Return is required to avoid falling through another step after responsive properties were updated.
                    // eslint-disable-next-line no-useless-return
                    return;
                }

                if (this.nextStep === constants.STEP_SELECT_CASE_BUNDLE) {
                    this.customizerStore.setCurrentStep(constants.STEP_SELECT_CASE_BUNDLE);

                    // Return is required to avoid falling through another step after responsive properties were updated.
                    // eslint-disable-next-line no-useless-return
                    return;
                }

                if (this.nextStep === constants.STEP_ADD_TO_CART) {
                    // Run add to cart.
                    this.addToCart();

                    // Return is required to avoid falling through another step after responsive properties were updated.
                    // eslint-disable-next-line no-useless-return
                    return;
                }
            },

            returnToBundle(step) {
                this.appStore.confirmPopup({
                    message: this.$t('DESIGN_CONFIRM_BODY'),
                    confirmButton: this.$t('DESIGN_CONFIRM_CONFIRM'),
                    cancelButton: this.$t('DESIGN_CONFIRM_CANCEL'),
                    confirmAction: () => {
                        this.appStore.confirmPopup();

                        this.customizerStore.setCurrentStep(step);
                    },
                    cancelAction: () => {
                        this.appStore.confirmPopup();

                        // Nothing else.
                    },
                });
            },

            addToCart() {
                const { hasEmpty, emptyType } = this.customizerStore.checkEmpty();

                let atcMessage = this.$t('ATC_CONFIRM_BODY');

                if (hasEmpty) {
                    if (emptyType === 'looks') {
                        atcMessage = this.$t('ATC_CONFIRM_EMPTY_LOOKS');
                    } else {
                        atcMessage = this.$t('ATC_CONFIRM_EMPTY');
                    }
                }

                this.appStore.confirmPopup({
                    message: atcMessage,
                    confirmButton: this.$t('ATC_CONFIRM_CONFIRM'),
                    cancelButton: this.$t('ATC_CONFIRM_CANCEL'),
                    confirmCountdown: hasEmpty ? 3 : 0,
                    confirmAction: () => {
                        this.appStore.confirmPopup();

                        // Add to cart.
                        this.appStore.setGlobalBusy(true);

                        this.customizerStore.addToCart();
                    },
                    cancelAction: () => {
                        this.appStore.confirmPopup();

                        // Nothing else.
                    },
                });
            },

            updateGrip(mount) {
                interact(this.$refs.grip);

                if (mount) {
                    interact(this.$refs.grip).draggable({
                        onend: (event) => {
                            if (event.swipe) {
                                if (event.swipe.up) {
                                    this.deviceColor = true;
                                }
                                if (event.swipe.down) {
                                    this.deviceColor = false;
                                }
                            }
                        },
                    });
                }
            },

            stepChanged() {
                this.updateTooltips();
            },

            toggleDevice() {
                this.appStore.hideTooltips();

                this.deviceColor = !this.deviceColor;

                // tracks when someone opens the bottom section with device color options
                this.customizerStore.trackAnalyticsEvent({
                    action: 'cyo_bottombar',
                });

                if (this.deviceColor === false) {
                    this.deviceColorToggling = true;

                    setTimeout(() => {
                        this.deviceColorToggling = false;
                    }, 250);
                }
            },

            selectedPhoneColor({ placement, component }) {
                this.customizerStore.setLastSelectedPhoneColor(placement, component);
            },
        },
    };
</script>
