<template>
    <div class="cz3__add-ai" ref="container"
        :class="{
            'cz3__add-ai--generated': previewData != null,
        }"
    >
        <div class="cz3__add-ai__body">
            <div class="cz3__add-ai__body-inner">
                <div class="cz3__add-ai__header-space cz3__add-ai__fixed"></div>

                <ai-header
                    :mode="mode"
                    :prevent-back="productSwitcher"
                    @close="close"
                    @back="backToPrompt"
                ></ai-header>

                <div class="cz3__add-ai__header-space cz3__add-ai__fixed"></div>

                <ai-prompt-editor
                    :start-prompt="startPrompt"
                    :structure="structure"
                    :buttons="buttons"
                    :show-upload-disclaimer="showUploadDisclaimer"
                    :generated="previewData != null"
                    @generate="generate"
                    @updatePrompt="updatePrompt"
                ></ai-prompt-editor>

                <div class="cz3__add-ai__rhythm-space cz3__add-ai__fixed"></div>

                <ai-preview
                    :preview-data="previewData"
                    :product-switcher="productSwitcher"
                    :compatible-options="bundleOptions"
                    :can-do-variations="canDoVariations"
                    :generating="generationInProcess"
                    @redo="redo"
                    @addToDesign="addToDesign"
                    @stopVariations="stopVariations"
                ></ai-preview>

                <div class="cz3__add-ai__rhythm-space cz3__add-ai__fixed cz3__add-ai__bottom-canary"></div>

                <transition name="cz3__fade">
                    <div v-if="showUploadDisclaimer" class="cz3__upload-disclaimer-popup">
                        <div class="cz3__upload-disclaimer-popup__body" v-html="disclaimer">
                        </div>

                        <div class="cz3__upload-disclaimer-popup__confirm">
                            <button
                                id="confirmTos"
                                class="cz3__outline-button cz3__outline-button--responsive"
                                @click.prevent="confirmDisclaimer"
                            >
                                {{$t('AGREE')}}
                            </button>
                        </div>

                        <div class="cz3__upload-disclaimer-popup__back">
                            <button v-if="!productSwitcher"
                                id="back"
                                class="cz3__grey-rounded-no-shadow-button"
                                @click.prevent="close"
                            >
                                {{$t('BACK')}}
                            </button>
                        </div>
                    </div>
                </transition>

                <transition name="cz3__fade">
                    <ai-help v-if="showAiHelpModal" @close="closeHelp" />
                </transition>

                <!-- Blank out while waiting for JSON -->
                <transition name="cz3__fade">
                    <div v-if="structure == null" class="cz3__add-ai__loading">
                    </div>
                </transition>
            </div>

            <transition name="cz3__fade">
                <ai-progress
                    v-if="generating"
                    :mode="generating"
                    @quit="quitGenerating"
                ></ai-progress>
            </transition>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__add-ai {
            @include absolute-overlay();

            background: rgba(24, 24, 24, 0.9);

            z-index: 10;

            .cz3__type--body {
                font-size: 16px;
                line-height: 110%;
            }

            .cz3__outline-button {
                font-size: 14px;
            }

            .cz3__primary-button {
                font-size: 14px;
            }

            @include respond-above(smai) {
                display: flex;

                justify-content: center;
                align-items: center;

                background: #fff;

                &:before {
                    content: '';

                    position: absolute;

                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;

                    background: transparent url(../assets/bg.webp) no-repeat center/cover;

                    transition: opacity 0.25s ease-out;
                }

                &.cz3__add-ai--generated {
                    &:before {
                        opacity: 0.15;
                    }
                }
            }
        }

        .cz3__add-ai__body {
            position: absolute;

            width: 100%;
            height: 100%;

            max-width: 100vw;;

            max-height: calc(100% - 2px);
            max-height: calc(100dvh - 2px);

            left: 50%;
            top: calc(50% + 2px);

            transform: translate(-50%, -50%);

            background: $color-primary-white;
            border-radius: 10px 10px 0 0;

            overflow: hidden;

            @include respond-above(smai) {
                width: 430px;

                // height: 694px;
                height: 100%;
                height: 100dvh;

                max-width: calc(100% - 32px);
                // max-height: calc(100% - 32px);

                // border-radius: 10px;
                border-radius: 0;
                border-left: 1px solid #e9e9e9;
                border-right: 1px solid #e9e9e9;

                position: relative;
                top: 0;
                left: 0;

                transform: none;

                max-height: calc(100%);
                max-height: calc(100dvh);
            }

            .cz3__upload-disclaimer-popup {
                z-index: 2;

                padding: 20px;
            }

            .cz3__upload-disclaimer-popup__back {
                left: 20px;
                bottom: 20px;
            }
        }

        .cz3__add-ai__body-inner {
            position: absolute;

            inset: 0;

            overflow-x: hidden;
            overflow-y: auto;

            display: flex;
            flex-direction: column;
            // Each "block" will have its own padding...
            // padding: 0 24px;
        }

        .cz3__add-ai__header-space {
            flex: 2 1 0;

            min-height: 15px;
            max-height: 30px;
        }

        .cz3__add-ai__rhythm-space {
            flex: 1 1 0;

            min-height: 10px;
            max-height: 22px;
        }

        .cz3__add-ai__loading {
            position: absolute;

            inset: 0;

            background: rgba(255, 255, 255, 0.8);
        }
    }
</style>

<script>
    import allStores from '../stores/all';

    import AiHeader from './AiHeader.vue';
    // import AiPrompt from './AiPrompt.vue';
    import AiPromptEditor from './AiPromptEditor.vue';
    import AiPreview from './AiPreview.vue';
    import AiProgress from './AiProgress.vue';
    import AiHelp from './AiHelp.vue';

    import * as constants from '../constants';

    // eslint-disable-next-line no-unused-vars
    // import mockData from '../mocks/mockPreview.json';

    export default {
        mixins: [allStores],

        components: {
            AiHeader,
            AiPromptEditor,
            AiPreview,
            AiProgress,
            AiHelp,
        },

        props: {
            type: {
                type: String,
                default: 'images',
            },
            usePlacement: {
                type: String,
                default: null,
            },
            useController: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                structure: null,
                buttons: null,

                mode: 'enter',
                previewData: null,
                lastPrompt: null,

                // mode: 'preview',
                // previewData: mockData,
                // lastPrompt: { prompt: 'test', style: 'anime' },

                startPrompt: null,
                startStyle: null,

                generating: false,
                canDoVariations: false,

                showUploadDisclaimer: false,

                productSwitcher: false,

                bundleOptions: null,

                abortController: null,

                variationsGenerating: false,
            };
        },

        computed: {
            viewController() {
                return this.customizerStore.viewController;
            },

            generatorRoot() {
                return this.viewController.blueprint.custom['ps-ai-endpoint'];
            },

            firstLoadedProduct() {
                return this.customizerStore.firstLoadedProduct;
            },

            lastLoadedProduct() {
                return this.customizerStore.lastLoadedProduct;
            },

            selectableOptions() {
                return this.customizerStore.selectableBundles;
            },

            firstBundle() {
                return this.selectableOptions.find((bundle) => bundle.product === this.firstLoadedProduct);
            },

            compatibleOptions() {
                if (this.firstBundle == null) {
                    return this.selectableOptions;
                }

                if (this.firstBundle.type == null) {
                    return this.selectableOptions;
                }

                return this.selectableOptions.filter((bundle) => bundle.types?.includes(this.firstBundle.type));
            },

            showAiHelpModal() {
                return this.appStore.showAiHelpModal;
            },

            loadingBundle() {
                if (this.generating !== 'apply') {
                    return false;
                }

                if (this.customizerStore.cameraLoading || this.customizerStore.bundleLoading) {
                    return true;
                }

                return false;
            },

            isAutoAiMode() {
                return this.viewController?.blueprint?.custom['ps-auto-ai'] === 'true';
            },

            disclaimer() {
                const m = this.viewController?.blueprint?.custom['ps-tos-html'];

                if (m) {
                    return m;
                }

                return this.$t('UPLOAD_DISCLAIMER_AI');
            },

            generationInProcess() {
                return this.variationsGenerating;
            },
        },

        watch: {
            loadingBundle() {
                if (!this.loadingBundle) {
                    this.goToBundle();
                }
            },

            buttons: {
                deep: true,
                handler() {
                    this.ensureDefaultSelections();
                },
            },
        },

        mounted() {
            // Show disclaimer on the first load.
            if (this.viewController?.blueprint?.custom?.['ps-show-upload-disclaimer'] === 'yes') {
                if (!this.appStore.uploadDisclaimerShown) {
                    this.showUploadDisclaimer = true;
                }
            }

            this.bundleOptions = this.compatibleOptions;

            // Show product selector?
            this.productSwitcher = this.viewController.blueprint?.custom?.['ps-ai-products-switcher'] === 'true';

            this.pinResized();

            window.addEventListener('resize', this.pinResized);

            // Load structure.
            this.loadStructure();
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.pinResized);
        },

        methods: {
            nothing() {
            },

            ensureDefaultSelections() {
                if (this.buttons) {
                    for (const mainButton of this.buttons) {
                        let anySelected = false;
                        let defaultButton = null;

                        this.customizerStore.forAllButtons((button) => {
                            if (button.isActive) {
                                anySelected = true;
                            }

                            if (button.tokenString == null || button.tokenString === '') {
                                defaultButton = button;
                            }
                        }, [mainButton], mainButton, true);

                        if (!anySelected && defaultButton) {
                            defaultButton.isActive = true;
                        }
                    }
                }

                const allTokens = [];

                this.customizerStore.forAllButtons((button, mainButton) => {
                    let v = button.tokenString;
                    if (v.startsWith('<')) {
                        v = v.substr(1);
                    }
                    if (v.endsWith('>')) {
                        v = v.substr(0, v.length - 1);
                    }

                    const dup = allTokens.find((x) => x.token === v);

                    if (dup) {
                        // eslint-disable-next-line
                        console.log('%c%s (in %s) is a duplicate of %s (in %s)', 'color: #909', v, mainButton.displayName, dup.token, dup.category);
                    }

                    allTokens.push({
                        token: v,
                        category: mainButton.displayName,
                    });
                }, this.buttons);

                // for (let i = 0; i < allTokens.length; i += 1) {
                //     for (let j = 0; j < allTokens.length; j += 1) {
                //         if (i === j) {
                //             // eslint-disable-next-line no-continue
                //             continue;
                //         }

                //         if (allTokens[i].token.startsWith(allTokens[j].token)) {
                // eslint-disable-next-line
                //             console.log('%c%s (in %s) is prefix of %s (in %s)', 'color: #f0f', allTokens[j].token, allTokens[j].category, allTokens[i].token, allTokens[i].category);
                //         }
                //     }
                // }
            },

            pinResized() {
                const els = Array.from(this.$refs.container.querySelectorAll('.cz3__add-ai__fixed'));

                for (const el of els) {
                    el.style.height = null;
                    el.style.flex = null;
                }

                this.$nextTick(() => {
                    for (const el of els) {
                        const h = el.offsetHeight;

                        el.style.height = `${h}px`;
                        el.style.flex = `0 0 ${h}px`;
                    }
                });
            },

            loadStructure() {
                const url = `${this.generatorRoot}/guided_prompts`;

                fetch(url).then((response) => response.json()).then((data) => {
                    this.structure = data;

                    this.buttons = JSON.parse(JSON.stringify(this.structure))?.mainButtons;

                    // We came here from the editor.
                    if (this.useController && this.usePlacement) {
                        const selected = this.useController.selectedAtPlacement(this.usePlacement);

                        if (selected) {
                            this.startPrompt = selected.custom?.['ps-ai-prompt-raw'];
                            this.startStyle = selected.custom?.['ps-ai-style'];
                        }
                    }

                    if (this.isAutoAiMode) {
                        if (this.customizerStore.options.aiStartWithPrompt
                            || (this.customizerStore.options.startingParameters?.type === 'prompt'
                                && this.customizerStore.options.startingParameters?.prompt)) {
                            this.captureFirstPrompt = true;

                            this.startPrompt = this.customizerStore.options.aiStartWithPrompt;

                            if (this.customizerStore.options.startingParameters?.type === 'prompt' && this.customizerStore.options.startingParameters?.prompt) {
                                this.startPrompt = this.customizerStore.options.startingParameters?.prompt;
                            }
                        }

                        if (this.customizerStore.options.aiStartWithResult) {
                            this.loadPreviousResult(this.customizerStore.options.aiStartWithResult);
                        }

                        if (this.customizerStore.options.startingParameters?.type === 'prompt' && this.customizerStore.options.startingParameters?.image) {
                            this.loadPreviousResult(this.customizerStore.options.startingParameters?.image);
                        }
                    }
                });
            },

            loadPreviousResult(url) {
                const from = url.startsWith('//') ? `https:${url}` : url;

                fetch(from).then((r) => r.blob()).then((blob) => {
                    const reader = new FileReader();

                    reader.onload = () => {
                        const result = {
                            images: [
                                reader.result,
                            ],
                            overlay: null,
                        };

                        this.previewData = result;

                        this.canDoVariations = true;
                    };

                    reader.readAsDataURL(blob);
                });
            },

            closeModal() {
                // if (this.isAutoAiMode) {
                //     this.appStore.confirmPopup({
                //         message: this.$t('CANCEL_BODY'),
                //         confirmButton: this.$t('CANCEL_CONFIRM'),
                //         cancelButton: this.$t('CANCEL_CANCEL'),
                //         confirmAction: () => {
                //             this.appStore.confirmPopup();
                //             this.customizerStore.globalCancel();
                //         },
                //         cancelAction: () => {
                //             this.appStore.confirmPopup();
                //         },
                //     });
                // } else {
                //     this.close();
                // }
            },

            close(image, useStep) {
                this.$emit('close', {
                    editImage: image,
                    useStep,
                });
            },

            backToPrompt() {
                this.previewData = null;

                this.mode = 'enter';
            },

            redo(options) {
                const keep = options?.keep;

                if (this.lastPrompt) {
                    if (this.previewData) {
                        if (this.previewData.images.length === 1) {
                            this.previewData.images.push(null);
                            this.previewData.images.push(null);
                            this.previewData.images.push(null);
                        } else {
                            const indexes = keep ? [0, 1, 2, 3].filter((x) => x !== keep) : [1, 2, 3];

                            this.previewData.images[indexes[0]] = null;
                            this.previewData.images[indexes[1]] = null;
                            this.previewData.images[indexes[2]] = null;
                        }
                    }

                    this.generateMore(this.lastPrompt, keep);
                }
            },

            // eslint-disable-next-line no-unused-vars
            generate(prompt) {
                this.generating = 'generate';

                const bottomButton = this.$refs.container.querySelector('.cz3__add-ai__bottom-canary');

                if (bottomButton && bottomButton.scrollIntoView) {
                    bottomButton.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest',
                    });
                }

                this.makeImage(prompt, true).then((result) => {
                    this.generating = null;

                    this.lastPrompt = prompt;

                    this.previewData = result;

                    this.canDoVariations = true;
                }).catch(() => {
                    this.generating = null;
                });
            },

            generateMore(prompt, keep) {
                const indexes = keep ? [0, 1, 2, 3].filter((x) => x !== keep) : [1, 2, 3];

                const tasks = [];

                this.canDoVariations = false;
                this.variationsGenerating = true;

                const cleanPrompt = { ...prompt };
                delete cleanPrompt.fromImage;

                tasks.push(this.makeImage(cleanPrompt).then((images) => {
                    this.previewData.images[indexes[0]] = images.images[0];
                }));

                tasks.push(this.makeImage(cleanPrompt).then((images) => {
                    this.previewData.images[indexes[1]] = images.images[0];
                }));

                tasks.push(this.makeImage(cleanPrompt).then((images) => {
                    this.previewData.images[indexes[2]] = images.images[0];
                }));

                Promise.all(tasks).then(() => {
                    this.canDoVariations = true;
                    this.variationsGenerating = false;
                });
            },

            stopVariations() {
                this.canDoVariations = true;
                this.variationsGenerating = false;
            },

            makeImage(prompt) {
                return new Promise((resolve, reject) => {
                    // // Testing data.
                    // resolve({
                    //     images: [mockData.images[Math.floor(Math.random() * 4)]],
                    //     overlay: mockData.overlay,
                    // });

                    // return;

                    this.abortController = new AbortController();

                    const url = `${this.generatorRoot}/generate/preview`;

                    let sourceUrl = prompt.fromImage ? prompt.fromImage.imageUrl : null;

                    if (sourceUrl?.startsWith('//')) {
                        sourceUrl = `https:${sourceUrl}`;
                    }

                    const data = {
                        prompt: prompt.prompt,
                        style: prompt.style,
                        fromImage: sourceUrl,
                    };

                    /// Always 1 now.
                    data.numImages = 1;

                    // if (this.viewController.blueprint.custom['ps-ai-preview-images']) {
                    //     data.numImages = +this.viewController.blueprint.custom['ps-ai-preview-images'];
                    // }

                    fetch(url, {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(data),
                        signal: this.abortController.signal,
                    }).then((response) => this.checkStatus(response))
                        .then((response) => response.json())
                        .then((previewData) => {
                            if (this.checkSuccess(previewData)) {
                                resolve(previewData);
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },

            quitGenerating() {
                this.abortController.abort();
            },

            goToBundle() {
                let step = null;

                if (this.viewController?.blueprint?.custom?.['ps-select-phone-first'] === 'true') {
                    step = constants.STEP_SELECT_PHONE;
                } else {
                    step = constants.STEP_SELECT_BUNDLE;
                }

                setTimeout(() => {
                    this.close(null, step);
                }, 250);
            },

            addToDesign(images) {
                this.generating = 'apply';

                const url = `${this.generatorRoot}/generate/finalize`;

                const continueApply = async (image, overlay) => {
                    // Upload images first.
                    let imageData = null;

                    try {
                        imageData = await this.customizerStore.uploadImage(image);
                    } catch (e) {
                        // Ignore.
                    }

                    let overlayData = null;

                    try {
                        overlayData = await this.customizerStore.uploadImage(overlay);
                    } catch (e) {
                        // Ignore.
                    }

                    // We have the data. But can we apply?
                    if (this.productSwitcher) {
                        this.customizerStore.stashLayers(imageData, overlayData, this.lastPrompt);

                        // Load a product first.

                        this.customizerStore.setCameraLoading(true);

                        this.customizerStore.selectBundle({
                            bundle: images.bundle,
                            resetOptions: true,
                        });

                        // There is no good indicator of when everything is loaded.
                        // Wait for the camera signal at least.
                    } else {
                        this.customizerStore.generateLayers(image, overlay, this.lastPrompt, this.useController, this.usePlacement).then((firstLayer) => {
                            this.close(firstLayer);
                        });
                    }
                };

                if (this.viewController.blueprint.custom['ps-ai-final-resolution'] === '1024') {
                    continueApply(images.image, images.overlay);
                } else {
                    // Check the image size first.
                    const img = new Image();

                    img.onload = () => {
                        const width = +this.viewController.blueprint.custom['ps-ai-final-resolution'];

                        if (img.width >= width) {
                            // Continue as is.
                            continueApply(images.image, images.overlay);
                        } else {
                            // Upscale.
                            const data = {
                                width,
                                image: images.image,
                            };

                            this.abortController = new AbortController();

                            fetch(url, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify(data),
                                signal: this.abortController.signal,
                            }).then((response) => this.checkStatus(response))
                                .then((response) => response.json())
                                .then((imageData) => {
                                    if (this.checkSuccess(imageData)) {
                                        continueApply(imageData.images[0], images.overlay);
                                    }
                                })
                                .catch((error) => {
                                    if (error && error.name === 'AbortError') {
                                        // eslint-disable-next-line
                                        console.log('user aborted generate');
                                    } else {
                                        // eslint-disable-next-line
                                        console.log('an error occurred: ', error);
                                    }

                                    this.generating = null;
                                });
                        }
                    };

                    img.src = images.image;
                }
            },

            checkStatus(response) {
                return new Promise((resolve, reject) => {
                    if (response.ok) {
                        resolve(response);
                    } else {
                        response.json().then((json) => {
                            if (json && json.code) {
                                this.checkSuccess(json);
                            } else {
                                this.checkSuccess({
                                    code: response.status,
                                });
                            }

                            reject();
                        }).catch(() => {
                            this.checkSuccess({
                                code: response.status,
                            });

                            reject();
                        });
                    }
                });
            },

            checkSuccess(data) {
                if (data && data.code) {
                    this.generating = null;

                    // Show an error instead.
                    let message = this.$t(`AI_ERROR_${data.code}`);

                    if (message == null) {
                        message = this.$t('AI_ERROR_GENERIC');
                    }

                    message += ` [${data.code}]`;

                    this.appStore.confirmPopup({
                        message,
                        confirmButton: this.$t('OK'),
                        confirmAction: () => {
                            this.appStore.confirmPopup();
                        },
                        cancelAction: () => {
                            this.appStore.confirmPopup();
                        },
                    });

                    return false;
                }

                return true;
            },

            confirmDisclaimer() {
                this.appStore.setUploadDisclaimerShown(true);

                this.showUploadDisclaimer = false;

                this.customizerStore.trackAnalyticsEvent({
                    action: 'cyo_agree',
                });
            },

            closeHelp() {
                this.appStore.showAiHelpModal = false;
            },

            updatePrompt(prompt) {
                if (this.captureFirstPrompt) {
                    this.lastPrompt = prompt;
                }

                this.canDoVariations = false;
            },
        },
    };
</script>
