<template>
    <div ref="app" id="cz3"
        :class="[
            'cz3',
            {
                'cz3__loaded': loaded,
                'cz3__hide-outlines': hideOutlines,
            },
            `cz3__locale-${cleanedLocale}`,
            `cz3__short-locale-${shortCleanedLocale}`,
        ]"
        @keydown="keyDown"
        @mousedown="mouseDown"
    >
        <div ref="sizecanary" class="cz3__size-canary"></div>
        <customizer-main></customizer-main>
    </div>
</template>

<style lang="scss">
    @import "./styles/variables.scss";
    @import "./styles/shared.scss";
    @import "./styles/uppy.scss";

    // Hide everything that is not the widget.
    .cz3__overlay-lock > *:not(.cz3):not(#tasks-container) {
        display: none !important;
        margin: 0 !important;
        padding: 0 !important;
    }

    #cz3.cz3 {
        position: absolute;

        // Sizing.
        --app-height: 100vh;

        left: 0;
        top: 0;
        width: 100vw;
        height: var(--app-height);

        z-index: 10000;

        // Swiper styles.
        @import 'swiper/scss';
        @import 'swiper/scss/navigation';
        @import 'swiper/scss/pagination';

        background: #fcfcfc;

        .cz3__size-canary {
            position: absolute;

            left: 0;
            width: 0;
            height: 100vh;
            pointer-events: none;

            opacity: 0;

            z-index: -1;
        }
    }

    body.cz3__overlay-lock {
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    // Inspector overrides.
    #actionTabs .tabsMenu .panes .pane .sliderLine .floatLine .short input {
        padding: 4px;
        font-size: 12px;
        line-height: 12px;
        height: auto;
    }
</style>

<script>
    // eslint-disable-next-line
    import URL from 'url-polyfill';
    // eslint-disable-next-line
    import '@netless/canvas-polyfill';

    import allStores from './stores/all';

    import CustomizerMain from './components/CustomizerMain.vue';

    import 'vue-multiselect/dist/dist/vue-multiselect.css';

    import '@uppy/core/dist/style.css';
    import '@uppy/dashboard/dist/style.css';
    import '@uppy/informer/dist/style.css';

    export default {
        mixins: [allStores],

        components: {
            CustomizerMain,
        },

        props: {
            options: Object,
        },

        data() {
            return {
                // Is the main UI loaded.
                loaded: false,

                // Hide outlines when using only the mouse.
                hideOutlines: true,
            };
        },

        computed: {
            /**
             * Is the UX blocked?
             */
            globalBusy() {
                return this.customizerStore.globalBusy;
            },

            /**
             * Shortcut to self-destruct flag.
             */
            selfDestruct() {
                return this.customizerStore.selfDestruct;
            },

            /**
             * Get normalized locale ID.
             */
            cleanedLocale() {
                if (this.customizerStore.options && this.customizerStore.options.locale) {
                    return this.customizerStore.options.locale.replace('_', '-').toLowerCase();
                }

                return 'generic';
            },

            /**
             * Get short normalized locale ID (i.e. for en-US return en only).
             */
            shortCleanedLocale() {
                const locale = this.cleanedLocale;

                return locale.split('-')[0];
            },
        },

        watch: {
            /**
             * Begin self-destruct sequence.
             */
            selfDestruct(v) {
                if (v) {
                    this.loaded = false;

                    this.$nextTick(() => {
                        this.$emit('selfDestruct');
                    });
                }
            },
        },

        mounted() {
            // Save options in all modules.
            this.appStore.setOptions(this.options);
            this.appStore.setTranslations(this.$root.$i18n);
            this.customizerStore.setOptions(this.options);

            this.configureSizing();

            // Once the popup is in DOM, load the product data.
            this.customizerStore.load({
                options: this.options,
            });

            this.loaded = true;

            // interact.addDocument(window.document, {
            //     events: { passive: false },
            // });
        },

        beforeUnmount() {
            this.stopResizing();
        },

        methods: {
            /**
             * Configure the resize of the widget so it always takes the full height.
             */
            configureSizing() {
                // Reset scroll position.
                this.oldBodyScroll = document.body.scrollTop || document.documentElement.scrollTop;

                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;

                document.body.style.height = this.lockHeight ? `${this.lockHeight}px` : `${this.$refs.app.offsetHeight}px`;

                // Hide everything so we have a clean page.
                document.body.classList.add('cz3__overlay-lock');

                // Listen to resize.
                window.addEventListener('resize', this.resize);

                // And resize right away.
                this.$nextTick(() => {
                    this.resize();
                });

                // Because the scrolling state may have changed now with the body height adjusted, reevaluate sizing a moment later.
                this.heightTimer = setInterval(this.resize, 100);
            },

            /**
             * Cleanly destroy the widget.
             */
            stopResizing() {
                document.body.classList.remove('cz3__overlay-lock');
                document.body.style.height = 'auto';

                window.removeEventListener('resize', this.resize);

                if (this.oldBodyScroll) {
                    document.body.scrollTop = this.oldBodyScroll;
                    document.documentElement.scrollTop = this.oldBodyScroll;
                }

                if (this.heightTimer) {
                    clearInterval(this.heightTimer);
                }
            },

            /**
             * Update sizing parameters.
             */
            resize() {
                const w = window.innerWidth;
                let h = window.innerHeight;

                const zoom = document.documentElement.clientWidth / window.innerWidth;

                h *= zoom;

                let skip = this.lastHeight === h;

                // If focused on a text element, skip.
                if (document.activeElement?.nodeName === 'TEXTAREA' || document.activeElement?.nodeName === 'INPUT') {
                    skip = true;
                }

                // If the height different is more than 25%, it might mean that the soft keyboard is shown.
                if (!skip
                    && w < 1024
                    && Math.abs(this.lastHeight - h) > this.lastHeight * 0.3) {
                    skip = true;
                }

                // If the width changed... may be landscape<->portrait transition.
                if (this.lastWidth != null && this.lastWidth !== w) {
                    skip = false;
                }

                if (!skip) {
                    // Resize the frame.
                    this.$refs.app.style.setProperty('--app-height', `${h}px`);

                    // Adjust the body height for the full screen effect.
                    document.body.style.height = `${this.$refs.app.offsetHeight}px`;

                    this.lastHeight = h;
                    this.lastWidth = w;
                }

                const small = window.innerWidth < 1024;
                if (this.appStore.small !== small) {
                    this.appStore.setSmall(small);
                }
            },

            /**
             * Hides outlines on mouse actions.
             */
            mouseDown() {
                this.hideOutlines = true;
            },

            /**
             * Shows outlines again when keyboard is engaged.
             */
            keyDown() {
                this.hideOutlines = false;
            },
        },
    };
</script>
