<template>
    <div>
        <div class="cz3__options cz3__options-edit cz3__renderer-exclude">
            <div class="cz3__mb--options">
                <buttons-panel
                    :show-edit-buttons="true"
                    :show-preview-toggle="false"
                    :show-preview-close="true"
                    @preview3d="preview3d"
                    @addImage="addImage"
                    @addStickers="addStickers"
                    @addText="addText"
                    @addAi="addAi"
                />
            </div>
        </div>

        <div class="cz3__preview-buttons">
            <template v-if="sets">
                <button
                    id="preview-set"
                    class="cz3__preview-set"
                    :class="[
                        'cz3__preview-set--selected-' + currentSet.set,
                    ]"
                    @click.prevent="changeSet"
                >
                    <span v-for="s in sets"
                        :key="s.set"
                        class="cz3__preview-set__set"
                    ></span>

                    <span class="cz3__preview-set__thumb">
                        {{currentSet.set}}
                    </span>
                </button>

                <button v-for="(v, i) in currentSet.views"
                    :key="v.view.code"
                    :id="`view-${i+1}`"
                    class="cz3__preview-view"
                    :class="{
                        'cz3__preview-view--selected': v.selected,
                    }"
                    :aria-label="v.view.name"
                    @click.prevent="selectView(v, currentSet.views)"
                >
                    <icon-view-select/>
                </button>
            </template>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__options-edit {
            height: auto;
        }

        .cz3__preview-buttons {
            position: absolute;

            display: flex;
            flex-direction: column;

            right: 12px;
            top: 50%;

            transform: translate(0, -50%);

            @include respond-above(sm) {
                right: auto;

                left: calc(50% + #{math.div($content-large-width, 2)} + 60px);
            }
        }

        .cz3__preview-set {
            position: relative;

            flex: 0 0 auto;
            display: inline-block;

            width: 40px;
            margin-bottom: 12px;

            border: 1px solid $color-gray-mid;
            border-radius: 40px;
            background: $color-gray-light;

            .cz3__preview-set__set {
                display: block;

                width: 32px;
                height: 32px;
            }

            .cz3__preview-set__thumb {
                position: absolute;

                display: block;

                width: 32px;
                height: 32px;

                left: 3px;

                border: 1px solid $color-primary-black;
                border-radius: 40px;
                color: $color-primary-black;

                font-size: 12px;
                line-height: 32px;

                text-align: center;

                transition: all 0.25s ease-out;
            }

            &.cz3__preview-set--selected-1 {
                .cz3__preview-set__thumb {
                    top: 3px;
                }
            }

            &.cz3__preview-set--selected-2 {
                .cz3__preview-set__thumb {
                    top: 31px;
                }
            }

            &.cz3__preview-set--selected-3 {
                .cz3__preview-set__thumb {
                    top: 59px;
                }
            }
        }

        .cz3__preview-view {
            flex: 0 0 auto;
            display: flex;

            justify-content: center;
            align-items: center;

            width: 40px;
            height: 40px;

            border: none;
            background: transparent;

            opacity: 0.3;
            transition: opacity 0.25s ease-out;

            svg {
                width: 12px;
                height: 12px;
            }

            &--selected {
                opacity: 1;
            }
        }
    }
</style>

<script>
    import ButtonsPanel from './ButtonsPanel.vue';

    import allStores from '../stores/all';

    import * as constants from '../constants';

    import IconViewSelect from '../assets/view-select.svg';

    export default {
        mixins: [allStores],

        components: {
            ButtonsPanel,
            IconViewSelect,
        },

        props: {
            cases: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                sets: null,
                currentSet: null,
            };
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },

            viewController() {
                return this.customizerStore.viewController;
            },
        },

        mounted() {
            const sets = this.viewSets();

            this.sets = sets;
            this.currentSet = sets[0];

            this.$nextTick(() => {
                // added `ignoreRotation: true` to this call so it doesn't trigger an analytics tracking event.
                this.selectView(this.currentSet.views[0], this.currentSet.views, true);
            });
        },

        beforeUnmount() {
            this.customizerStore.setOverrideView(null);
        },

        methods: {
            /**
             * Exit 3D preview mode.
             */
            preview3d() {
                this.customizerStore.setCurrentStep(constants.STEP_EDIT);
            },

            /**
             * Build view sets.
             */
            viewSets() {
                const controller = this.customizerStore.viewController;

                const sets = [];

                for (let set = 1; ; set += 1) {
                    let foundSome = false;

                    let currentSet = null;

                    for (let view = 1; ; view += 1) {
                        const viewCode = `Preview ${set} ${view}`;

                        const foundView = controller.blueprint.views.find((v) => v.code === viewCode);

                        if (foundView == null) {
                            break;
                        }

                        foundSome = true;

                        if (currentSet == null) {
                            currentSet = {
                                set,
                                views: [],
                            };

                            sets.push(currentSet);
                        }

                        currentSet.views.push({
                            selected: currentSet.views.length === 0,
                            view: foundView,
                        });
                    }

                    if (!foundSome) {
                        break;
                    }
                }

                return sets.length > 0 ? sets : null;
            },

            /**
             * Selects a view.
             */
            selectView(v, all, ignoreRotation) {
                all.forEach((other) => {
                    other.selected = false;
                });

                v.selected = true;

                // since this method also gets called from changeSet,
                // we should avoid applying the _preset tracker
                // when ignoreRotation is true.
                if (!ignoreRotation) {
                    this.customizerStore.trackAnalyticsEvent({
                        action: 'cyo_3D_preset',
                    });
                }

                this.customizerStore.setOverrideView(v.view, {
                    ignoreRotation,
                });
            },

            /**
             * Pick the next view set.
             */
            changeSet() {
                this.customizerStore.trackAnalyticsEvent({
                    action: 'cyo_3D_toggle',
                });
                const nextIndex = this.currentSet.set;

                if (this.sets[nextIndex] == null) {
                    this.currentSet = this.sets[0];
                } else {
                    this.currentSet = this.sets[nextIndex];
                }

                this.currentSet.views.forEach((v) => {
                    v.selected = false;
                });

                this.currentSet.views[0].selected = true;

                this.selectView(this.currentSet.views[0], this.currentSet.views, true);
            },

            addImage() {
                this.customizerStore.setCurrentStep({
                    step: constants.STEP_EDIT,
                    mode: 'addImage',
                });
            },

            addStickers() {
                this.customizerStore.setCurrentStep({
                    step: constants.STEP_EDIT,
                    mode: 'addSticker',
                });
            },

            addText() {
                this.customizerStore.setCurrentStep({
                    step: constants.STEP_EDIT,
                    mode: 'addText',
                });
            },

            addAi() {
                this.customizerStore.setCurrentStep({
                    step: constants.STEP_EDIT,
                    mode: 'addAi',
                });
            },
        },
    };
</script>
