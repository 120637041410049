import { defineStore, acceptHMRUpdate } from 'pinia';

import axios from 'axios';

import { useAppStore } from './app';

import * as constants from '../constants';
import * as ProductUtilities from '../helpers/products';
import * as GalleryUtilities from '../helpers/imageGallery';
import * as ComponentUtilities from '../helpers/components';

import designArea from '../helpers/designArea';
import extent from '../helpers/extent';

import emitter from '../helpers/bus';

// Formats the price according to current locale settings.
function formatPrice(v, relative, locale, currency) {
    if (v == null || v === '' || v === 0) {
        return '';
    }

    const wholeFormatter = Intl.NumberFormat(locale ?? 'en-US', {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        notation: 'standard',
        currency: currency ?? 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const fractionFormatter = Intl.NumberFormat(locale ?? 'en-US', {
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
        notation: 'standard',
        currency: currency ?? 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatted = v % 1 === 0 ? wholeFormatter.format(v) : fractionFormatter.format(v);

    if (relative) {
        if (formatted.startsWith('-')) {
            return formatted;
        }

        return `+${formatted}`;
    }

    return formatted;
}

// eslint-disable-next-line import/prefer-default-export
export const useCustomizerStore = defineStore('customizer', {
    id: 'customizer',

    state: () => ({
        // Is the widget currently doing something.
        globalBusy: false,

        // Is the widget self-destructing.
        selfDestruct: false,

        // Current configuration step.
        currentStep: constants.STEP_LOADING,

        // Step options.
        currentStepOptions: null,

        // Widget configuration options.
        options: {},

        // Currently selected controller.
        viewController: null,

        // Prefer MagSafe products.
        preferMagSafe: false,

        // A list of joints to show.
        joints: [],

        // Selected joint.
        selectedJoint: null,

        // Last selected joint.
        lastSelectedJoint: null,

        // Selected view.
        selectedView: null,

        // Use this view as an override.
        overrideView: null,
        overrideViewOptions: null,

        // Selected monogramming layout.
        monogrammingLayout: 1,

        // User uploaded images.
        ownImages: [],

        // User uploaded images.
        ownStickers: [],

        // Selected layer.
        selectedLayer: null,

        // Is CameraView loading?
        cameraLoading: false,

        // Is a bundle loading?
        bundleLoading: false,

        // A product or a bundle that was last loaded.
        lastLoadedProduct: null,

        // A product or a bundle that was first loaded.
        firstLoadedProduct: null,

        // Current camera aspect ratio.
        cameraAspectRatio: 2,

        // Cached product bundle prices.
        prices: [],

        // Last phone color selections.
        lastSelectedPhonePlacement: null,
        lastSelectedPhoneColor: null,

        // Use recipes when initializing.
        useRecipes: null,

        // Precached options list so it wouldn't change if the base product is reloaded.
        cachedOptions: null,

        // Have we already shown initial AI step?
        aiShown: false,

        // Prefer to use looks inserts?
        useLooks: false,

        stashOverlayImage: null,
        stashBackgroundImage: null,
        stashPrompt: null,

        canCheckStashedLayers: false,

        playedAiImageVideo: false,
        expandedGuidedPrompts: false,

        firstLoad: true,
        skipBundles: false,
        skipBundlesAllowPhone: false,
    }),

    getters: {
        small() {
            const appStore = useAppStore();

            return appStore.small;
        },

        /**
         * Get a list of prebuilt options.
         */
        prebuiltOptions() {
            if (this.cachedOptions != null) {
                return this.cachedOptions;
            }

            const options = ProductUtilities.flattenRelated(this.viewController);

            const filtered = options.filter((p) => {
                if (p.custom && p.custom['ps-prebuilt-product.essential']) {
                    return true;
                }

                return false;
            }).map((p) => ({
                bundleStyleCode: p.styleCode,
                isDefault: p.custom['ps-prebuilt-default.essential'] === 'true',

                selectCaption: p.custom['ps-prebuilt-caption.essential'],

                name: this.fixSpaces(p.custom['ps-prebuilt-name.essential']),
                size: this.fixSpaces(p.custom['ps-prebuilt-size.essential']),
                displayName: p.custom['ps-prebuilt-display-name.essential'],

                longName: p.custom['ps-prebuilt-long-name.essential'],

                image: p.custom['ps-prebuilt-image.essential'],

                product: p.custom['ps-prebuilt-product.essential'],

                order: +(p.custom['ps-prebuilt-order.essential'] ?? '10000'),

                phone: p.custom['ps-prebuilt-phone.essential'],
                magSafe: p.custom['ps-prebuilt-magsafe.essential'] === 'true',
                magSafeLink: p.custom['ps-prebuilt-magsafe-link.essential'],

                type: p.custom['ps-prebuilt-bundle-type.essential'],
                types: p.custom['ps-prebuilt-bundle-types.essential']
                    ? p.custom['ps-prebuilt-bundle-types.essential'].split(',').map((x) => x.trim()).filter((x) => x.length > 0)
                    : null,

                popupTitle: p.custom['ps-prebuilt-bundle-popup-title.essential'],
                popupBody: p.custom['ps-prebuilt-bundle-popup-body.essential'],

                sku: p.custom['ps-prebuilt-sku.essential'],

                tlaSuffix: p.custom['ps-prebuilt-tla-suffix.essential'],

                looks: p.custom['ps-prebuilt-looks.essential'] === 'true',
                looksInfo: p.custom['ps-looks-info-html.essential'],

                withLooks: p.custom['ps-prebuilt-with-looks.essential'],

                addon1: p.custom['ps-prebuilt-addon-1.essential'],
                looksAddon1: p.custom['ps-prebuilt-looks-addon-1.essential'],

                price: null,
                priceFormatted: null,
                relativePrice: null,
                relativePriceFormatted: null,

                preorderTitle: p.custom['ps-prebuilt-preorder-title.essential'],
                preorderDate: p.custom['ps-prebuilt-preorder-date.essential'],
                preorderDateTitle: p.custom['ps-prebuilt-preorder-date-title.essential'],
            })).sort((a, b) => a.order - b.order);

            const unique = [];
            const bundles = {};

            filtered.forEach((f) => {
                if (bundles[f.product] == null) {
                    bundles[f.product] = true;

                    unique.push(f);
                }
            });

            if (unique.length > 0) {
                this.cachedOptions = unique;
            }

            setTimeout(() => {
                this.updateBundlePrices();
            });

            return unique;
        },

        selectableBundles() {
            let cases = false;

            if (this.viewController?.blueprint?.custom?.['ps-select-phone-first'] === 'true') {
                cases = true;

                // TODO: There are only MagSafe cases right now.
                this.setPreferMagSafe(true);
            }

            // Filter by phone and MagSafe preference.
            if (cases) {
                const selectedPhone = this.viewController.selectedAtPlacement(constants.PLACEMENT_PHONE);
                if (selectedPhone) {
                    const phoneType = selectedPhone.custom['ps-phone'];

                    const phoneOptions = this.prebuiltOptions.filter((o) => o.phone === phoneType);

                    let magSafeOptions;

                    if (this.preferMagSafe) {
                        magSafeOptions = phoneOptions.filter((o) => o.magSafe);
                    } else {
                        magSafeOptions = phoneOptions.filter((o) => !o.magSafe);
                    }

                    setTimeout(() => {
                        this.updateBundlePrices();
                    });

                    return magSafeOptions;
                }
            }

            setTimeout(() => {
                this.updateBundlePrices();
            });

            return this.prebuiltOptions;
        },

        selectedBundle() {
            const options = this.prebuiltOptions;

            if (options == null) {
                return null;
            }

            if (this.lastLoadedProduct == null) {
                return null;
            }

            // Compare launcher bundles without taking into the consideration the very first
            // product - the one that would pick the bundle collection in the first place.
            // i.e.
            // C3PhoneLargeGripPlatform~Phone,C3MSRound
            // C3PhoneStartMSRoundOnly~Phone,C3MSRound
            // are the same configuration.

            const ignoreDevice = (v) => {
                if (v == null) {
                    return v;
                }

                const tilde = v.indexOf('~');

                if (tilde > 0) {
                    return v.substr(tilde);
                }

                return v;
            };

            let selected = options.find((o) => ignoreDevice(o.product) === ignoreDevice(this.lastLoadedProduct));

            if (selected == null) {
                selected = options.find((o) => o.withLooks && ignoreDevice(o.withLooks) === ignoreDevice(this.lastLoadedProduct));
            }

            return selected;
        },

        selectedBundlePrice() {
            if (this.selectedBundle) {
                return this.prices.find((x) => x.sku === this.selectedBundle.sku)?.priceFormatted ?? '';
            }

            return '';
        },

        // Current product display name.
        productDisplayName() {
            if (this.selectedBundle == null) {
                return '';
            }

            return this.selectedBundle.displayName ?? this.selectedBundle.name;
        },

        // Current product display name.
        productLongDisplayName() {
            if (this.selectedBundle == null) {
                return '';
            }

            return this.selectedBundle.longName ?? this.selectedBundle.name;
        },

        /**
         * Calculates the previous step.
         */
        previousStep() {
            if (this.currentStep === constants.STEP_LOADING) {
                return null;
            }

            if (this.currentStep === constants.STEP_SELECT_BUNDLE) {
                return constants.STEP_CANCEL;
            }

            if (this.currentStep === constants.STEP_SELECT_PHONE) {
                return constants.STEP_CANCEL;
            }

            if (this.currentStep === constants.STEP_SELECT_CASE_BUNDLE) {
                return constants.STEP_SELECT_PHONE;
            }

            if (this.currentStep === constants.STEP_EDIT) {
                if (this.skipBundles) {
                    let needPhone = false;

                    if (this.skipBundlesAllowPhone) {
                        if (this.viewController?.blueprint?.custom?.['ps-select-phone-first'] === 'true') {
                            needPhone = true;
                        }
                    }

                    if (needPhone) {
                        return constants.STEP_SELECT_PHONE;
                    }

                    return constants.STEP_CANCEL;
                }

                if (this.viewController?.blueprint?.custom?.['ps-select-phone-first'] === 'true') {
                    return constants.STEP_SELECT_CASE_BUNDLE;
                }

                return constants.STEP_SELECT_BUNDLE;
            }

            if (this.currentStep === constants.STEP_EDIT_OPTIONS) {
                return constants.STEP_EDIT;
            }

            if (this.currentStep === constants.STEP_EDIT_IMAGE) {
                return constants.STEP_EDIT;
            }

            if (this.currentStep === constants.STEP_PREVIEW) {
                return constants.STEP_EDIT;
            }

            return null;
        },

        /**
         * Can we actually go back?
         */
        canGoBack() {
            if (this.cameraLoading) {
                return false;
            }

            return true;
        },

        /**
         * Calculates the next step.
         */
        nextStep() {
            if (this.currentStep === constants.STEP_LOADING) {
                return null;
            }

            if (this.currentStep === constants.STEP_SELECT_BUNDLE) {
                return constants.STEP_EDIT;
            }

            if (this.currentStep === constants.STEP_SELECT_PHONE) {
                if (this.skipBundles) {
                    return constants.STEP_EDIT;
                }

                return constants.STEP_SELECT_CASE_BUNDLE;
            }

            if (this.currentStep === constants.STEP_SELECT_CASE_BUNDLE) {
                return constants.STEP_EDIT;
            }

            if (this.currentStep === constants.STEP_EDIT) {
                return constants.STEP_ADD_TO_CART;
            }

            if (this.currentStep === constants.STEP_PREVIEW) {
                return constants.STEP_ADD_TO_CART;
            }

            if (this.currentStep === constants.STEP_EDIT_OPTIONS) {
                return constants.STEP_EDIT;
            }

            if (this.currentStep === constants.STEP_EDIT_IMAGE) {
                return constants.STEP_ADD_TO_CART;
            }

            return null;
        },

        /**
         * Can we actually advance?
         */
        canGoNext() {
            if (this.cameraLoading) {
                return false;
            }

            if (this.currentStep === constants.STEP_SELECT_PHONE) {
                // Should always be available.
                return true;
            }

            if (this.currentStep === constants.STEP_SELECT_BUNDLE || this.currentStep === constants.STEP_SELECT_CASE_BUNDLE) {
                if (this.viewController) {
                    const sockets = this.viewController.socketsState;

                    if (sockets && sockets.length > 0) {
                        const someAttached = sockets.some((s) => s.to);

                        return someAttached;
                    }
                }
            }

            if (this.currentStep === constants.STEP_EDIT) {
                // Should always be available.
                return true;
            }

            if (this.currentStep === constants.STEP_EDIT_OPTIONS) {
                // Should always be available.
                return true;
            }

            if (this.currentStep === constants.STEP_EDIT_IMAGE) {
                // Should always be available.
                return true;
            }

            if (this.currentStep === constants.STEP_PREVIEW) {
                // Should always be available.
                return true;
            }

            return false;
        },

        imageGallery() {
            return GalleryUtilities.getGallery(this.viewController, constants.PLACEMENT_IMAGES);
        },

        stickerGallery() {
            return GalleryUtilities.getGallery(this.viewController, constants.PLACEMENT_STICKERS);
        },

        /**
         * Joints visible in the selected view.
         */
        activeJoints() {
            // Filter based on the currently selected view.

            // If no view is selected (?) return everything.
            if (this.selectedView == null) {
                return this.joints;
            }

            // Hide joints while in the edit image mode.
            const showJoints = this.selectedView.custom['ps-show-joints'];
            if (showJoints !== 'true') {
                return [];
            }

            const type = this.selectedView.custom['ps-type'];

            const visible = [];
            const all = this.joints.map((x) => ({ ...x }));

            if (type != null && type.length > 0) {
                // If a view wants to show a specific type (such as Editor views)
                // only show joints of the specific type.
                // For example, Overview 1 only shows "phone,case" - platform, wallet, grip, etc should be shown on Overview 2.
                // Overview 1 will have ps-type, Overview 2 won't - it will be a catch all state.
                const types = type.split(',').map((x) => x.trim());

                all.forEach((j) => {
                    const productType = j?.controller?.blueprint?.custom?.['ps-type'];

                    if (productType != null) {
                        const productTypes = productType.split(',').map((x) => x.trim());

                        const intersects = types.some((x) => productTypes.includes((x)));

                        if (intersects) {
                            visible.push(j);
                        }
                    }
                });
            } else {
                // Since this view doesn't care about which types to show.
                // Show only components of types not requested by other views.
                // For example, Overview 1 in the carousel wants to display only "phone,case", but Overview 2 shows "the rest".
                const except = [];

                this.viewController?.blueprint?.views?.forEach((v) => {
                    if (v.custom?.['ps-type']) {
                        except.push(...v.custom['ps-type'].split(',').map((x) => x.trim()));
                    }
                });

                all.forEach((j) => {
                    const productType = j?.controller?.blueprint?.custom?.['ps-type'];

                    if (productType != null) {
                        const productTypes = productType.split(',').map((x) => x.trim());

                        const notIntersects = except.every((x) => !productTypes.includes((x)));

                        if (notIntersects) {
                            visible.push(j);
                        }
                    }
                });
            }

            all.forEach((j) => {
                j.active = false;
            });

            visible.forEach((j) => {
                j.active = true;
            });

            return visible;
        },

        /**
         * Joints selectable on the current view.
         */
        selectableJoints() {
            // Group inactive joints by controller.
            //
            // For example if Overview 1 is selected:
            //   - active joints will correspond to the Case
            //   - inactive joints will be the wallet and poptop
            //     - these inactive ones will be be grouped to represent one controller each.
            //     - simply have the first joint coordinates.

            // Filter based on the currently selected view.
            const showJoints = this.selectedView?.custom?.['ps-show-joints'];

            // Joints are hidden while in the Edit Image mode.
            if (showJoints !== 'true') {
                return [];
            }

            const active = this.activeJoints;
            const all = this.joints.map((x) => ({ ...x }));

            const groupedByController = {};

            all.forEach((j) => {
                // Combine screen coordinates.
                let controller = groupedByController[j.controller.id];

                if (controller == null) {
                    controller = {
                        merged: false,
                        joints: [],
                    };

                    groupedByController[j.controller.id] = controller;
                }

                controller.joints.push(j);

                // If there is a selected joint, it shouldn't be attached a controller in the current view.
                // The carousel should have been moved to another view.

                if (!active.some((x) => x.id === j.id)) {
                    controller.merged = true;
                }
            });

            Object.values(groupedByController).forEach((controller) => {
                // Don't mark as merged
                if (controller.merged) {
                    controller.joints.forEach((j) => {
                        j.merged = true;
                        j.mergedCount = controller.joints.length;
                    });
                } else {
                    controller.joints.forEach((j) => {
                        j.merged = false;
                        j.mergedCount = controller.joints.length;
                    });
                }
            });

            return all;
        },

        /**
         * One of the joints on the edit view is active. It should not be shown to the user (it is already active),
         * unless it is was previously edited.
         * There could be multiple candidates, if there was a previously selected image joint, use that.
         */
        editViewJoint() {
            const imageJoints = this.activeJoints.filter((x) => x.type === 'image');

            if (this.lastSelectedJoint?.type === 'image') {
                // Is it still active?
                const stillActive = imageJoints.find((j) => j.id === this.lastSelectedJoint.id);
                if (stillActive) {
                    return {
                        id: stillActive.id,
                        joint: stillActive,
                        edited: stillActive.edited,
                        visible: stillActive.edited,
                    };
                }
            }

            // Otherwise use the first image joint.
            if (imageJoints.length > 0) {
                const edited = ComponentUtilities.isAnyFilled(imageJoints[0].controller);

                return {
                    id: imageJoints[0].id,
                    joint: imageJoints[0],
                    edited: imageJoints[0].edited,
                    visible: edited,
                };
            }

            // Otherwise use the first joint and make it explicitly visible.
            if (this.activeJoints.length > 0) {
                return {
                    id: this.activeJoints[0],
                    joint: this.activeJoints[0],
                    edited: false,
                    visible: true,
                };
            }

            return null;
        },

        /**
         * Gets current controller corresponding to the selected joint.
         * Probably different from viewController,
         */
        currentController() {
            if (this.selectedJoint == null) {
                return null;
            }

            return this.selectedJoint.controller;
        },

        /**
         * Get a list of placements with images.
         */
        imagePlacements() {
            const selectedSuffix = new RegExp(`${this.monogrammingLayout}\\d\\d?$`);

            if (this.currentController) {
                return this.currentController.state.filter((p) => {
                    if (p.placement.code.startsWith(constants.PLACEMENT_IMAGE) && selectedSuffix.test(p.placement.code)) {
                        return true;
                    }

                    return false;
                });
            }

            return [];
        },

        /**
         * Get a list of placements with filled images.
         */
        filledImagePlacements() {
            return this.imagePlacements.filter((p) => ComponentUtilities.isFilled(p.component));
        },

        /**
         * Get a list of empty image placements.
         */
        emptyImagePlacements() {
            return this.imagePlacements.filter((p) => !ComponentUtilities.isFilled(p.component));
        },

        /**
         * Get a list of placements with text.
         */
        textPlacements() {
            const selectedSuffix = new RegExp(`${this.monogrammingLayout}\\d$`);

            if (this.currentController) {
                return this.currentController.state.filter((p) => p.placement.code.startsWith(constants.PLACEMENT_TEXT)
                    && selectedSuffix.test(p.placement.code));
            }

            return [];
        },

        /**
         * Get a list of placements with filled text.
         */
        filledTextPlacements() {
            return this.textPlacements.filter((p) => ComponentUtilities.isFilled(p.component));
        },

        /**
         * Get a list of empty text placements.
         */
        emptyTextPlacements() {
            return this.textPlacements.filter((p) => !ComponentUtilities.isFilled(p.component));
        },

        /**
         * Get a list of empty text placements.
         */
        emptyishTextPlacements() {
            return this.textPlacements.filter((p) => !ComponentUtilities.isFilledish(p.component));
        },

        /**
         * Current monogramming layers.
         */
        layers() {
            const list = [];

            if (this.currentController) {
                this.currentController.state.forEach((s) => {
                    if (s.placement.code.startsWith('MONOGRAM')
                        && !s.placement.code.startsWith('MONOGRAMMINGBASE')
                        && !s.placement.code.startsWith('MONOGRAMMINGRESULT')
                        && ComponentUtilities.isFilled(s.component)) {
                        const index = s.placement.custom['explicit-order'];

                        const l = {
                            state: s,
                            index,
                        };

                        if (s.component.custom['dynamic-image-url']) {
                            l.type = 'image';
                            l.image = s.component.custom['dynamic-image-url'];
                            l.thumbnail = `${s.component.custom['dynamic-image-url']}?rwid=100&fmt=jpg&bgc=ffffff`;
                        }

                        list.push(l);
                    }
                });
            }

            list.sort((a, b) => a.index - b.index);

            return list;
        },

        productHasCustomizations() {
            // if (this.layers.length > 0) {
            //     return this.layers.length > 0;
            // }

            // return (this.filledImagePlacements.length + this.extPlacements.length) > 0;

            let hasCustomization = false;

            if (this.viewController) {
                this.viewController.forAllControllers((controller) => {
                    controller.state.forEach((s) => {
                        if (s.placement.code.startsWith(constants.PLACEMENT_TEXT)) {
                            if (ComponentUtilities.isFilled(s.component)) {
                                hasCustomization = true;
                            }
                        }

                        if (s.placement.code.startsWith(constants.PLACEMENT_IMAGE)) {
                            if (ComponentUtilities.isFilled(s.component)) {
                                hasCustomization = true;
                            }
                        }
                    });
                });
            }

            return hasCustomization;
        },

        hasAi() {
            return this.viewController.blueprint?.custom['ps-use-ai'] === 'true';
        },
    },

    actions: {
        /**
         * Save options.
         */
        setOptions(options) {
            this.options = options;
        },

        /**
         * Reset state after loading a new product.
         */
        resetState() {
            this.joints = [];
            this.selectedJoint = null;
            this.lastSelectedJoint = null;
            this.selectedView = null;
            this.overrideView = null;
            this.monogrammingLayout = 1;
            this.selectedLayer = null;
        },

        /**
         * Sets current application step.
         */
        setCurrentStep(step) {
            if (typeof step === 'string') {
                this.currentStepOptions = null;
                this.currentStep = step;
            } else {
                this.currentStepOptions = step;
                this.currentStep = step.step;
            }
        },

        /**
         * Closes current instance.
         */
        globalCancel() {
            this.selfDestruct = true;

            if (this.options.onCancel) {
                this.options.onCancel();
            }
        },

        /**
         * Closes current instance.
         */
        globalDone() {
            this.selfDestruct = true;
        },

        /**
         * Start loading products.
         */
        load(options) {
            if (options) {
                Object.assign(this.options, options);
            }

            if (this.options.recipe || (this.options.startingParameters?.type === 'recipe' && this.options.startingParameters?.recipe)) {
                this.loadRecipe();
            } else {
                this.continueStartFromBlank();
            }
        },

        /**
         * Calculate default controller options.
         */
        defaultControllerOptions() {
            const options = {
                apiKey: this.options.apiKey,
                products: this.options.products,
                preview: this.options.preview,
                site: this.options.site,
                version: this.options.version,
                locale: this.options.locale,
                disableCaching: this.options.disableCaching,
            };

            if (this.options.endpoint) {
                options.endpoint = this.options.endpoint;
            }

            return options;
        },

        /**
         * Loads prices for components mentioned in the blueprint.
         */
        getPrices() {
            return new Promise((resolve) => {
                resolve();
            });
        },

        /**
         * Retrieve a component price.
         */
        // eslint-disable-next-line no-unused-vars
        getPrice() { // { component }
            return new Promise((resolve) => {
                resolve(10);
            });
        },

        /**
         * Load controller.
         */
        loadController(controller) {
            controller.on('setReactive', (v) => {
                // Vue 3 doesn't need Vue.set anymore.
                v.target[v.key] = v.value;
                return true;
            });

            controller.on('initializeAvailability', (o) => this.getPrices({
                controller,
                blueprint: o.blueprint,
            }));

            controller.on('afterInitializeState', () => {
                // If there is a phone color selected, use that.
                if (this.lastSelectedPhonePlacement) {
                    controller.updateComponent(this.lastSelectedPhonePlacement, this.lastSelectedPhoneColor);
                }
            });

            controller.on('isAvailable', () => true);

            controller.on('getPrice', (component) => this.getPrice({
                component,
                alwaysAvailable: this.options.alwaysAvailable,
                testPrices: this.options.testPrices,
                ignoreOnline: this.options.ignoreOnline,
                products: this.products,
            }));

            controller.on('socketAttached', (socket) => {
                socket.to.on('placementHidden', ({ placement, view, baking }) => {
                    // Show only stickers on underprint placements.
                    if (!baking) {
                        return false;
                    }

                    if (!view.code.includes('Underprint')) {
                        return false;
                    }

                    // Do show base and results.
                    if (placement.placement.code.startsWith(constants.PLACEMENT_BASE)) {
                        return false;
                    }

                    if (placement.placement.code.startsWith(constants.PLACEMENT_RESULT)) {
                        return false;
                    }

                    // Text should be visible and have an underprint.
                    if (placement.placement.code.startsWith(constants.PLACEMENT_TEXT)) {
                        return false;
                    }

                    // Stickers are visible.
                    if (placement.component.custom['ps-type'] === 'stickers'
                        && placement.component.custom['dynamic-image-url']
                        && placement.component.custom['dynamic-image-url'].length > 0) {
                        return false;
                    }

                    // Images that are NOT marked as not having an underprint.
                    if (placement.component.custom['ps-type'] === 'images'
                        && placement.component.custom['dynamic-image-url']
                        && placement.component.custom['dynamic-image-url'].length > 0
                        && placement.component.custom['ps-no-underprint'] !== 'true') {
                        return false;
                    }

                    // Everything else is hidden.
                    return true;
                });
            });

            controller.load();
        },

        initializeFromRecipes(controller) {
            if (this.useRecipes == null) {
                return;
            }

            controller.forAllControllers((socket) => {
                // Is there a recipe for this controller?
                const styleCode = socket.blueprint.styleCode;

                const recipe = this.useRecipes.find((r) => r.custom['ps-style-code'] === styleCode || r.styleCode === styleCode);

                if (recipe) {
                    socket.state.forEach((s) => {
                        const recipePlacement = recipe.components.find((x) => x.code === s.placement.code);

                        if (recipePlacement?.custom?.['explicit-order']) {
                            if (s.placement.custom) {
                                s.placement.custom['explicit-order'] = recipePlacement?.custom?.['explicit-order'];
                            }
                        }

                        if (recipePlacement?.component?.code && !recipePlacement?.component?.placeholder) {
                            if (recipePlacement.code.startsWith('MONOGRAMMINGIMAGE')) {
                                if (recipePlacement.component?.custom?.['dynamic-image-url']?.length > 0) {
                                    const attributes = { ...recipePlacement.component.custom };

                                    socket.updateComponent(s.placement.code, recipePlacement.component.code);

                                    socket.commitCustomAttributes(s.placement.code, attributes);
                                }
                            } else if (recipePlacement.code.startsWith('MONOGRAMMINGTEXT')) {
                                if (recipePlacement.component?.custom?.['personalization-text']?.length > 0) {
                                    const attributes = { ...recipePlacement.component.custom };

                                    socket.updateComponent(s.placement.code, recipePlacement.component.code);

                                    socket.commitCustomAttributes(s.placement.code, attributes);
                                }
                            } else {
                                // eslint-disable-next-line no-lonely-if
                                if (!(s.placement.code === 'C3Phone')) {
                                    socket.updateComponent(s.placement.code, recipePlacement.component.code);
                                }
                            }
                        }
                    });
                }
            });
        },

        /**
         * Loads products from recipe.
         */
        loadRecipe() {
            let recipeId = this.options.recipe;

            if (this.options.startingParameters?.type === 'recipe' && this.options.startingParameters?.recipe) {
                recipeId = this.options.startingParameters?.recipe;
            }

            const recipeUrl = `//cz.drrv.co/recipe/${recipeId}.json`;

            fetch(recipeUrl).then((r) => r.json()).then((data) => {
                // Load other recipes.
                const tasks = [];

                Object.keys(data?.custom).forEach((k) => {
                    if (k.startsWith('ps-recipe-source-')) {
                        tasks.push(
                            fetch(`//cz.drrv.co/recipe/${data.custom[k]}.json`).then((r) => r.json()),
                        );
                    }
                });

                Promise.all(tasks).then((components) => {
                    const startingBundle = data?.custom?.['ps-from-bundle'];

                    const allComponents = [data, ...components];

                    if (startingBundle) {
                        this.options.products = startingBundle;

                        this.continueStartFromBlank(allComponents);
                    } else {
                        this.continueStartFromBlank(allComponents);
                    }
                });
            }).catch(() => {
                this.continueStartFromBlank();
            });
        },

        continueStartFromBlank(useRecipes) {
            this.loadCustomizeFlow({
                startWithProducts: this.options.startWithProducts,
                useRecipes,
            });
        },

        /**
         * Load customization flow.
         */
        loadCustomizeFlow({
            startWithProducts,
            useRecipes,
        }) {
            const loadProducts = startWithProducts;

            const options = {
                ...this.defaultControllerOptions(),
                ...loadProducts,
            };

            this.saveLastLoadedProduct(options);

            const controller = new this.options.DriveCustomizer.Controller(options);

            controller.on('afterInitializeState', () => {
            });

            this.useRecipes = useRecipes;

            controller.on('afterAssembliesBuilt', () => {
                // Load actual product options.
                this.loadColors({
                    controllers: [controller],
                });
            });

            // Load controller.
            this.loadController(controller);
        },

        /**
         * Extracts the product ID to be loaded and saves it to the store.
         */
        saveLastLoadedProduct(options) {
            let product = null;

            if (options.products) {
                product = options.products;
            }

            if (Array.isArray(product)) {
                product = product[0];
            }

            this.lastLoadedProduct = product;

            // Save the first loaded bundle.
            if (product?.includes('~') && this.firstLoadedProduct == null) {
                this.firstLoadedProduct = product;
            }

            setTimeout(() => {
                this.updateBundlePrices();
            });
        },

        /**
         * Updates bundle prices relative to the selected product.
         */
        updateBundlePrices() {
            // First ensure that we actually have prices for all selectable bundles.
            const unknownProducts = [];

            this.selectableBundles.forEach((b) => {
                if (this.prices.find((x) => x.sku === b.sku) == null) {
                    unknownProducts.push(b.sku);
                }
            });

            if (unknownProducts.length > 0) {
                this.options.productDataEndpoint(unknownProducts, (data) => {
                    if (data) {
                        data.forEach((price) => {
                            const bundle = this.prices.find((x) => x.sku === price.productId);

                            if (!bundle) {
                                this.prices.push({
                                    sku: price.productId,
                                    price: price.price,
                                    priceFormatted: formatPrice(price.price, false, this.options.locale, this.options.currency),
                                });
                            }
                        });

                        this.updateRelativePrices();
                    }
                });
            } else {
                this.updateRelativePrices();
            }
        },

        updateRelativePrices() {
            const basePrice = this.selectedBundle ? this.prices.find((x) => x.sku === this.selectedBundle.sku) : null;

            if (basePrice) {
                this.prices.forEach((b) => {
                    if (basePrice.price) {
                        b.relativePrice = b.price - basePrice.price;
                        b.relativePriceFormatted = formatPrice(b.relativePrice, true, this.options.locale, this.options.currency);
                    }
                });
            } else {
                this.prices.forEach((b) => {
                    b.relativePrice = b.price;
                    b.relativePriceFormatted = b.priceFormatted;
                });
            }
        },

        /**
         * Continue initializing the controller and configure product colors.
         */
        loadColors({ controllers }) {
            let showController = null;

            if (controllers.length > 0) {
                // eslint-disable-next-line
                showController = controllers[0];
            }

            this.updateTranslations(showController);

            if (this.firstLoad) {
                this.firstLoad = false;

                if (showController?.blueprint?.custom['ps-skip-select-bundle'] === 'true') {
                    this.skipBundles = true;
                }

                if (showController?.blueprint?.custom['ps-skip-select-bundle-select-phone'] === 'true') {
                    this.skipBundlesAllowPhone = true;
                }
            }

            if (showController) {
                this.initializeFromRecipes(showController);

                this.showController(showController);

                // Are we loading from a recipe?
                if (this.options.recipe || (this.options.startingParameters?.type === 'recipe' && this.options.startingParameters?.recipe)) {
                    this.setCurrentStep(constants.STEP_EDIT);

                    // Otherwise figure out the starting step.

                    // After everything is loaded, replace an image.
                    this.preloadImages();
                }

                if (this.skipBundles) {
                    let needPhone = false;

                    if (this.skipBundlesAllowPhone) {
                        if (showController?.blueprint?.custom?.['ps-select-phone-first'] === 'true') {
                            needPhone = true;
                        }
                    }

                    if (needPhone) {
                        this.setCurrentStep(constants.STEP_SELECT_PHONE);
                    } else {
                        this.setCurrentStep(constants.STEP_EDIT);
                    }
                } else if (showController?.blueprint?.custom?.['ps-select-phone-first'] === 'true') {
                    this.setCurrentStep(constants.STEP_SELECT_PHONE);
                } else {
                    this.setCurrentStep(constants.STEP_SELECT_BUNDLE);
                }

                // Check if there are any assemblies that are already loaded, and if not, select something.
                this.loadDefaultAssemblies();
            }
        },

        /**
         * Update translations for the current locale from the controller.
         */
        updateTranslations(controller) {
            if (controller.blueprint?.translations) {
                const appStore = useAppStore();

                const locale = this.options.locale || 'en';

                let messages = appStore.translations.getLocaleMessage(locale);

                if (messages == null) {
                    messages = {};
                }

                // eslint-disable-next-line no-restricted-syntax
                for (const k of Object.keys(controller.blueprint.translations)) {
                    messages[k] = controller.blueprint.translations[k];
                }

                appStore.translations.setLocaleMessage(locale, messages);
            }
        },

        /**
         * Preloads an image and replaces it in the state.
         */
        preloadImages() {
            if (this.options.addImages) {
                // eslint-disable-next-line no-restricted-syntax
                for (const newImage of this.options.addImages) {
                    // Find controller.
                    let useController = null;

                    this.viewController.forAllControllers((controller) => {
                        if (controller.blueprint.styleCode === newImage.styleCode) {
                            useController = controller;
                        }
                    });

                    if (useController == null) {
                        // eslint-disable-next-line no-continue
                        continue;
                    }

                    let usePlacement = null;

                    if (newImage.placement) {
                        usePlacement = useController.findPlacement(newImage.placement);
                    }

                    if (newImage.image) {
                        // Reupload image.
                        // eslint-disable-next-line no-underscore-dangle
                        this.uploadAndUseImage(useController, usePlacement, newImage.image, newImage.type);
                    }
                }
            }
        },

        uploadImage(imageUrl) {
            return new Promise((resolve, reject) => {
                if (typeof imageUrl === 'object') {
                    resolve(imageUrl);
                } else {
                    // Reupload to Customizer.
                    let endpoint = '//api.images.drivecommerce.com/api/v1/';

                    if (this.viewController.blueprint.imageRootUrl) {
                        endpoint = this.viewController.blueprint.imageRootUrl.replace('http://', '//').replace('https://', '//');
                    }

                    endpoint = window.location.protocol + endpoint;

                    endpoint += endpoint.substr(-1) === '/' ? 'upload' : '/upload';

                    // Prefer direct.
                    endpoint = endpoint.replace('//fx.images', '//api.images');

                    const formData = new FormData();

                    let image = imageUrl;
                    if (image.startsWith('//')) {
                        image = `https:${image}`;
                    }

                    if (image.startsWith('data:')) {
                        const comma = image.indexOf(',');

                        image = image.substr(comma + 1);
                    }

                    formData.append('copy-from', image);

                    axios({
                        method: 'post',
                        url: endpoint,
                        data: formData,
                        config: {
                            headers: { 'Content-Type': 'multipart/form-data' },
                        },
                    }).then((response) => {
                        resolve(response.data);
                    }).catch(() => {
                        reject();
                    });
                }
            });
        },

        // eslint-disable-next-line no-underscore-dangle
        uploadAndUseImage(controller, placement, imageUrl, type) {
            return new Promise((resolve) => {
                this.uploadImage(imageUrl).then((data) => {
                    this.addImage({
                        image: data,
                        type,
                        usePlacement: placement?.code,
                        useController: controller,
                    });

                    setTimeout(() => {
                        resolve(data);
                    });
                }).catch(() => {
                    resolve(null);
                });
            });
        },

        /**
         * Loads defaults assemblies for the current controller.
         */
        loadDefaultAssemblies() {
            const sockets = this.viewController.socketsState;

            if (sockets && sockets.length > 0) {
                const someAttached = sockets.some((s) => s.to);

                // If no attached controllers found, load the default bundle.
                if (!someAttached) {
                    let phoneType = 'any';

                    const selectedPhone = this.viewController.selectedAtPlacement(constants.PLACEMENT_PHONE);
                    if (selectedPhone) {
                        phoneType = selectedPhone.custom['ps-phone'];
                    }

                    let prebuilt = this.prebuiltOptions.find((o) => o.isDefault && o.phone === phoneType);

                    if (prebuilt == null) {
                        prebuilt = this.prebuiltOptions.find((o) => o.phone === phoneType);
                    }

                    if (prebuilt == null) {
                        prebuilt = this.prebuiltOptions.find((o) => o.isDefault);
                    }

                    if (prebuilt == null) {
                        prebuilt = this.prebuiltOptions?.[0];
                    }

                    if (prebuilt) {
                        // Reload the current controller with new default choices.
                        this.reloadController(prebuilt.product).then((controller) => {
                            this.showController(controller);
                        });
                    }
                }
            }
        },

        /**
         * Reloads controller with a different set of products.
         */
        reloadController(product) {
            return new Promise((resolve) => {
                const options = {
                    ...this.defaultControllerOptions(),
                    ...{
                        products: [product],
                    },
                };

                this.saveLastLoadedProduct(options);

                const reloaded = new this.options.DriveCustomizer.Controller(options);

                reloaded.on('afterInitializeState', () => {
                });

                reloaded.on('afterAssembliesBuilt', () => {
                    resolve(reloaded);

                    this.useRecipes = null;
                });

                this.loadController(reloaded);
            });
        },

        /**
         * Selects a controller to show in the UI.
         */
        showController(controller) {
            this.viewController = controller;

            emitter.$off('renderTransformUpdated', this.renderTransformUpdated);
            emitter.$on('renderTransformUpdated', this.renderTransformUpdated);

            emitter.$off('cameraLoading', this.setCameraLoading);
            emitter.$on('cameraLoading', this.setCameraLoading);

            emitter.$off('setCameraAspectRatio', this.setCameraAspectRatio);
            emitter.$on('setCameraAspectRatio', this.setCameraAspectRatio);

            emitter.$on('cameraClicked', this.cameraClicked);

            this.updateJoints();
        },

        cameraClicked() {
            // Special case.
            // 3D view is actually being constantly rotated.
            // And click events on the 3D view will trigger the selection event.
            // So to distinguish the intentional rotation event, we should check if we are on the Preview step.
            if (this.currentStep === constants.STEP_PREVIEW) {
                this.trackAnalyticsEvent({
                    action: 'cyo_3D_rotate',
                });
            }
        },

        setCameraAspectRatio(v) {
            this.cameraAspectRatio = v;
        },

        /**
         * Sets the 3D view status.
         */
        setCameraLoading(loading) {
            this.cameraLoading = loading;
        },

        /**
         * Update the utility list of joints, such as calculated screen positions etc.
         */
        renderTransformUpdated() {
            this.updateJoints();
        },

        /**
         * Reloads controllers with the new bundle while attempting to save the current printable configuration.
         */
        selectBundle({ bundle, resetOptions }) {
            emitter.$emit('clear');

            this.resetState();

            this.cameraLoading = true;
            this.bundleLoading = true;

            if (this.useLooks && bundle.withLooks) {
                this.reloadController(bundle.withLooks).then((controller) => {
                    this.showController(controller);

                    if (resetOptions) {
                        this.cachedOptions = null;
                    }
                });
            } else {
                this.reloadController(bundle.product).then((controller) => {
                    this.showController(controller);

                    if (resetOptions) {
                        this.cachedOptions = null;
                    }

                    this.bundleLoading = false;
                });
            }
        },

        /**
         * Sets MagSafe preference.
         */
        setPreferMagSafe(v) {
            this.preferMagSafe = v;
        },

        /**
         * Updates the list of available joints.
         */
        updateJoints() {
            const joints = [];

            if (this.viewController == null) {
                return;
            }

            const types = {};

            const blocked = new Set();

            this.viewController.forAllControllers((controller) => {
                if (controller.blueprint?.custom?.['ps-disable-edit']) {
                    const names = controller.blueprint?.custom?.['ps-disable-edit'].split(',').map((x) => x.trim()).filter((x) => x.length > 0);

                    names.forEach((n) => {
                        blocked.add(n);
                    });
                }
            });

            this.viewController.socketsState.forEach((s) => {
                if (s?.socket?.custom?.['ps-edit'] == null) {
                    return;
                }

                if (s.screen == null) {
                    return;
                }

                if (blocked.has(s.socket?.code)) {
                    return;
                }

                let type = 'options';

                if (s?.socket?.custom?.['ps-edit'] === 'image') {
                    type = 'image';
                }

                if (type === 'options') {
                    // Are there actually editable placements.
                    const editable = s.on?.state?.some((p) => p.placement.custom?.['ps-editable'] === 'true');

                    if (!editable) {
                        return;
                    }
                }

                let firstOfType = false;

                if (types[type] == null) {
                    firstOfType = true;

                    types[type] = true;
                }

                const meshes = s?.socket?.custom?.['ps-meshes'];

                if (ComponentUtilities.isAnySpecial(s.on)) {
                    return;
                }

                const edited = type === 'image' && ComponentUtilities.isAnyFilled(s.on);

                let viewSuffix = null;

                // Find a socket that this controller is attached to.
                if (s.on.socket) {
                    const attachedTo = this.viewController.allSockets().find((x) => x.id === s.on.socket);

                    if (attachedTo) {
                        const to = attachedTo.socket;

                        if (to) {
                            if (to.custom?.['ps-view-suffix']) {
                                // A special view to be triggered when this joint is selected.
                                viewSuffix = to.custom?.['ps-view-suffix'];
                            }
                        }
                    }
                }

                // viewName will be used as a unique identifier for automated testing.
                const viewName = s.on.blueprint.styleCode + s.socket.code;

                joints.push({
                    id: s.id,
                    type,
                    firstOfType,
                    controller: s.on,
                    controllerId: s.on.id,

                    view: s.socket.custom['ps-edit-view'],
                    viewSuffix,
                    viewName,

                    screen: {
                        x: s.screen.x / this.cameraAspectRatio,
                        y: s.screen.y / this.cameraAspectRatio,
                    },

                    merged: false,
                    mergedScreen: null,
                    mergedPreferred: false,
                    mergedCount: 0,

                    active: true,

                    edited,

                    meshes,
                });
            });

            const groupedByController = {};

            joints.forEach((j) => {
                // Combine screen coordinates.
                let controller = groupedByController[j.controller.id];

                if (controller == null) {
                    controller = {
                        merged: false,
                        joints: [],
                        x: 0,
                        y: 0,
                    };

                    groupedByController[j.controller.id] = controller;
                }

                // controller.x += j.screen.x;
                // controller.y += j.screen.y;

                controller.x = j.screen.x;
                controller.y = j.screen.y;

                controller.joints.push(j);
            });

            Object.values(groupedByController).forEach((controller) => {
                const s = {
                    // x: controller.x / controller.joints.length,
                    // y: controller.y / controller.joints.length,
                    x: controller.x,
                    y: controller.y,
                };

                let preferred = null;

                controller.joints.forEach((j) => {
                    j.mergedScreen = s;

                    if (j.type === 'image') {
                        preferred = j;
                    }
                });

                if (preferred) {
                    preferred.mergedPreferred = true;
                } else {
                    controller.joints[0].mergedPreferred = true;
                }
            });

            this.setJoints(joints);
        },

        /**
         * Sets joints list.
         */
        setJoints(j) {
            this.joints = j;
        },

        /**
         * Selects a joint.
         */
        selectJoint(j) {
            this.selectedJoint = j;

            if (j != null) {
                this.lastSelectedJoint = j;
            }
        },

        /**
         * Store selected view.
         */
        selectView(v) {
            if (v) {
                // eslint-disable-next-line
                console.log(`Select view ${v.code}`);
            }

            this.selectedView = v;
        },

        /**
         * Selects a view override.
         */
        setOverrideView(v, options) {
            this.overrideView = v;
            this.overrideViewOptions = options;
        },

        /**
         * Update view options.
         */
        setOverrideViewOptions(options) {
            this.overrideViewOptions = options;
        },

        /**
         * Adds image to the list of recently uploaded.
         */
        addOwnImage(image) {
            // De-duplicate.
            if (!this.ownImages.some((i) => i.imageUrl === image.imageUrl || i.imageUrl === image.image)
            ) {
                this.ownImages.push(image);
            }
        },

        /**
         * Adds image to the list of recently uploaded.
         */
        addOwnSticker(image) {
            // De-duplicate.
            if (!this.ownStickers.some((i) => i.imageUrl === image.imageUrl)) {
                this.ownStickers.push(image);
            }
        },

        /**
         * Clears empty layers.
         */
        removeEmptyLayers() {
            if (this.currentController) {
                const clearPlacements = [];

                this.currentController.state.forEach((s) => {
                    if (s.component.custom['personalization-filled'] === 'true'
                        && (s.component.custom['personalization-text'] == null || s.component.custom['personalization-text'].trim().length === 0)) {
                        clearPlacements.push(s);
                    }
                });

                clearPlacements.forEach((s) => {
                    this.clearPlacement(this.currentController, s.placement.code);
                });
            }
        },

        /**
         * Adds text to the first free slot.
         */
        copyText({
            controller,
            placement,
            component,
        }) {
            const freePlacements = this.emptyishTextPlacements;

            if (freePlacements.length === 0) {
                return null;
            }

            const freePlacement = freePlacements[0];

            ComponentUtilities.assignTopmostLayer(controller, freePlacement.placement);

            const targetPlacement = freePlacement.placement.code;

            let x = +component.custom['personalization-x'];
            let y = +component.custom['personalization-y'];

            const { dy } = extent(placement, component);
            x += dy * 0.5;
            y += dy * 0.5;

            // const areaWidth = +controller.blueprint.custom['layout-1-width'];
            // const areaHeight = +controller.blueprint.custom['layout-1-height'];

            // x += areaWidth * 0.1;
            // y += areaHeight * 0.1;

            const newSettings = {
                'personalization-text': component.custom['personalization-text'],
                'personalization-x': x.toString(),
                'personalization-y': y.toString(),
                'personalization-rotate': component.custom['personalization-rotate'],
                'personalization-scale': component.custom['personalization-scale'],
                'personalization-color': component.custom['personalization-color'],
                'personalization-filled': 'true',
                'personalization-width': '1',
                'personalization-height': '1',
                'personalization-size-fit-min': 'none',
            };

            controller.commitCustomAttributes(targetPlacement, newSettings, {
                propagate: true,
            });

            const font = component.description.find((d) => d.definition === constants.DEFINITION_FONT);
            if (font) {
                controller.updateSimilarComponent(targetPlacement, constants.DEFINITION_FONT, font.code);
            }

            return targetPlacement;
        },

        /**
         * Adds text to the first free slot.
         */
        addText({
            usePlacement,
            useController,
        }) {
            let controller = null;

            if (useController) {
                controller = useController;
            } else {
                controller = this.currentController;
            }

            if (controller == null) {
                return null;
            }

            let placementCode = null;

            if (usePlacement) {
                placementCode = usePlacement;
            } else {
                const freePlacements = this.emptyishTextPlacements;

                if (freePlacements.length === 0) {
                    return null;
                }

                const placement = freePlacements[0];

                ComponentUtilities.assignTopmostLayer(controller, placement.placement);

                placementCode = placement.placement.code;
            }

            const component = controller.selectedAtPlacement(placementCode);

            const {
                left, right, top, bottom,
            } = designArea(controller, '1');

            const width = +component.custom['personalization-width'];
            const height = +component.custom['personalization-height'];

            const areaHeight = +controller.blueprint.custom['layout-1-height'];
            const size = +component.custom['personalization-size'];

            let sizeScale = (areaHeight * 0.1) / size;
            if (sizeScale < 1) {
                sizeScale = 1;
            }

            if (left != null && right != null && top != null && bottom != null) {
                const newSettings = {
                    'personalization-x': ((left + right) * 0.5) - (width * 0.5),
                    'personalization-y': (((top + bottom) * 0.5) - (height * 0.5)),
                    'personalization-color': '000000',
                    'personalization-rotate': 0,
                    'personalization-scale': `${sizeScale}`,
                    'personalization-filled': 'true',
                    'personalization-width': '1',
                    'personalization-height': '1',
                    'personalization-size-fit-min': 'none',
                };

                controller.commitCustomAttributes(placementCode, newSettings, {
                    propagate: true,
                });
            }

            return placementCode;
        },

        /**
         * Finds empty placements in a controller.
         */
        findEmptyImagePlacements(controller) {
            const selectedSuffix = new RegExp(`${this.monogrammingLayout}\\d\\d?$`);

            const imagePlacements = controller.state.filter((p) => {
                if (p.placement.code.startsWith(constants.PLACEMENT_IMAGE) && selectedSuffix.test(p.placement.code)) {
                    return true;
                }

                return false;
            });

            return imagePlacements.filter((p) => !ComponentUtilities.isFilled(p.component));
        },

        /**
         * Adds image to the first free slot.
         */
        addImage({
            image,
            type,
            usePlacement,
            useController,
            useXRay,
        }) {
            let controller = null;

            if (useController) {
                controller = useController;
            } else {
                controller = this.currentController;
            }

            if (controller == null) {
                return null;
            }

            let placementCode = null;

            if (usePlacement) {
                placementCode = usePlacement;
            } else {
                const freePlacements = this.findEmptyImagePlacements(controller);

                if (freePlacements.length === 0) {
                    return null;
                }

                const placement = freePlacements[0];

                ComponentUtilities.assignTopmostLayer(controller, placement.placement);

                placementCode = placement.placement.code;
            }

            // Clear first.
            controller.commitCustomAttributes(placementCode, {
                'dynamic-image-offset-x': 0,
                'dynamic-image-offset-y': 0,
                'dynamic-image-scale': 1,
                'dynamic-image-rotate': 0,
                'dynamic-image-url': null,
                'dynamic-image-svg-url': null,
                'dynamic-image-svg-scale': 1,
                'dynamic-image-is-placeholder': true,
                'gallery-source': '',
            });

            // Use a new image.
            controller.updateDynamicImage(placementCode, {
                title: image.title,
                name: image.name,
                url: image.imageUrl,
                pngUrl: image.pngImageUrl,
                jpgUrl: image.jpgImageUrl,
                width: image.width,
                height: image.height,
                fit: type === 'stickers' ? 'none' : null,
            });

            if (image.sourceCode) {
                controller.commitCustomAttributes(placementCode, {
                    'gallery-source': image.sourceCode,
                });
            }

            // Double-check scaling for stickers.
            if (type === 'stickers') {
                const component = controller.selectedAtPlacement(placementCode);
                const placement = controller.findPlacement(placementCode);

                let areaWidth = +(component.custom['dynamic-image-area-width'] || (placement.custom && placement.custom['dynamic-image-area-width']));
                let areaHeight = +(component.custom['dynamic-image-area-height'] || (placement.custom && placement.custom['dynamic-image-area-height']));

                const width = +(component.custom['dynamic-image-width']);
                const height = +(component.custom['dynamic-image-height']);

                areaWidth *= 0.75;
                areaHeight *= 0.75;

                let scale = 1;

                if (width > areaWidth) {
                    scale = areaWidth / width;
                }

                if ((scale * height) > areaHeight) {
                    scale = areaHeight / height;
                }

                controller.commitCustomAttributes(placementCode, {
                    'dynamic-image-scale': scale,
                });
            }

            // Save initial scale and position.
            {
                const component = controller.selectedAtPlacement(placementCode);

                controller.commitCustomAttributes(placementCode, {
                    'ps-moved': 'false',
                    'ps-image-initial-scale': `${component.custom['dynamic-image-scale']}`,
                    'ps-image-initial-x': component.custom['dynamic-image-offset-x'],
                    'ps-image-initial-y': component.custom['dynamic-image-offset-y'],
                });
            }

            // Save the sticker/image type.
            controller.commitCustomAttributes(placementCode, {
                'ps-type': type,
                'ps-no-underprint': image.noUnderprint ? 'true' : 'false',
            });

            // Are we projecting this thing on all surfaces?
            if (useXRay) {
                const component = controller.selectedAtPlacement(placementCode);

                this.xrayFrom(controller, placementCode, component, true);
            }

            // Return used placement code.
            return placementCode;
        },

        xrayFrom(fromController, placementCode, component, projectIfNot) {
            if (this.viewController == null || this.viewController.blueprint.custom['ps-xray-enabled'] !== 'true') {
                return;
            }

            // Does the source component is lacking a projection ID?
            if (!component.custom['ps-xray-id']) {
                if (projectIfNot) {
                    component.custom['ps-xray-id'] = `xray-${new Date().getTime()}`;
                } else {
                    return;
                }
            }

            const xrayId = component.custom['ps-xray-id'];

            // First, scan all xray-able controllers and figure out dimensions and center positions.
            const positions = [];

            this.viewController.forAllControllers((controller) => {
                if (controller.blueprint.custom['ps-xray-layer']) {
                    const layer = +controller.blueprint.custom['ps-xray-layer'];

                    const width = +controller.blueprint.custom['layout-1-width'];
                    const height = +controller.blueprint.custom['layout-1-height'];

                    let centerY = height / 2;

                    if (controller.blueprint.custom['ps-xray-center-y'].endsWith('%')) {
                        centerY = (height * (+controller.blueprint.custom['ps-xray-center-y'].replace('%', ''))) / 100;
                    } else {
                        centerY = +controller.blueprint.custom['ps-xray-center-y'];
                    }

                    positions.push({
                        controller,
                        reference: controller.id === fromController.id,
                        layer,
                        width,
                        height,
                        centerX: width / 2,
                        centerY,
                    });
                }
            });

            let reference = positions.find((x) => x.reference);

            let newProjected = false;
            const saveInitial = [];

            for (const position of positions) {
                // If this a reference controller? skip it, it already has an image.
                if (position.reference) {
                    // eslint-disable-next-line no-continue
                    continue;
                }

                // Do we already have the image projected?
                let projected = null;

                for (const s of position.controller.state) {
                    if (s.component.custom['ps-xray-id'] === xrayId) {
                        projected = s;
                    }
                }

                // If not, we need to project it.
                if (projected == null) {
                    const type = component.custom['ps-type'];

                    // Use the same image.
                    const image = {
                        title: component.custom['dynamic-image-title'],
                        name: component.custom['dynamic-image-name'],
                        imageUrl: component.custom['dynamic-image-url'],
                        pngImageUrl: component.custom['dynamic-image-pngUrl'],
                        jpgImageUrl: component.custom['dynamic-image-jpgUrl'],
                        width: +component.custom['dynamic-image-width'],
                        height: +component.custom['dynamic-image-height'],
                        fit: type === 'stickers' ? 'none' : null,
                        // Ensure to copy extra properties.
                        sourceCode: component.custom['gallery-source'],
                        noUnderprint: component.custom['ps-no-underprint'] === 'true',
                    };

                    const targetPlacement = this.addImage({
                        image,
                        type: component.custom['ps-type'],
                        // Find a new placement.
                        usePlacement: null,
                        useController: position.controller,
                        useXRay: false,
                    });

                    // Assign xray id.
                    position.controller.commitCustomAttributes(targetPlacement, {
                        'ps-xray-id': xrayId,
                    });

                    // Scan again.
                    for (const s of position.controller.state) {
                        if (s.component.custom['ps-xray-id'] === xrayId) {
                            projected = s;
                        }
                    }

                    // Save initial settings for this layer.
                    saveInitial.push({
                        controller: position.controller,
                        placement: targetPlacement,
                    });

                    newProjected = true;
                }
            }

            // These are the source component adjustments...
            // But we might need to figure out the largest scale.
            let scale = null;
            let rotate = null;
            let x = null;
            let y = null;

            if (newProjected) {
                for (const position of positions) {
                    // Do we already have the image projected?
                    let projected = null;

                    for (const s of position.controller.state) {
                        if (s.component.custom['ps-xray-id'] === xrayId) {
                            projected = s;
                        }
                    }

                    if (projected == null) {
                        // eslint-disable-next-line no-continue
                        continue;
                    }

                    const cs = +projected.component.custom['dynamic-image-scale'];
                    const cr = +projected.component.custom['dynamic-image-rotate'];
                    const cx = +projected.component.custom['dynamic-image-offset-x'];
                    const cy = +projected.component.custom['dynamic-image-offset-y'];

                    if (scale == null || cs > scale) {
                        scale = cs;
                        rotate = cr;
                        x = cx;
                        y = cy;

                        reference = position;
                    }
                }
            }

            for (const position of positions) {
                // Do we already have the image projected?
                let projected = null;

                for (const s of position.controller.state) {
                    if (s.component.custom['ps-xray-id'] === xrayId) {
                        projected = s;
                    }
                }

                if (projected == null) {
                    // eslint-disable-next-line no-continue
                    continue;
                }

                const dx = x + (position.centerX - reference.centerX);
                const dy = y + (position.centerY - reference.centerY);

                position.controller.commitCustomAttributes(projected.placement.code, {
                    'dynamic-image-scale': scale.toString(),
                    'dynamic-image-rotate': rotate.toString(),
                    'dynamic-image-offset-x': dx.toString(),
                    'dynamic-image-offset-y': dy.toString(),
                });

                // If this is the first time, save the initial settings for the layer.
                if (saveInitial.some((s) => s.controller === position.controller && s.placement === projected.placement.code)) {
                    const otherComponent = position.controller.selectedAtPlacement(projected.placement.code);

                    position.controller.commitCustomAttributes(projected.placement.code, {
                        'ps-moved': 'false',
                        'ps-image-initial-scale': `${otherComponent.custom['dynamic-image-scale']}`,
                        'ps-image-initial-x': otherComponent.custom['dynamic-image-offset-x'],
                        'ps-image-initial-y': otherComponent.custom['dynamic-image-offset-y'],
                    });
                }
            }
        },

        /**
         * Clear personalization from a placement.
         */
        clearPlacement(controller, placementCode) {
            const selectedComponent = controller.selectedAtPlacement(placementCode);
            const selectedPlacement = controller.findPlacement(placementCode);

            if (selectedComponent.custom['dynamic-image-url'] && selectedComponent.custom['dynamic-image-url'].length > 0) {
                controller.updateDynamicImage(placementCode, {
                    title: '',
                    name: '',
                    url: '',
                    pngUrl: '',
                    jpgUrl: '',
                    width: '',
                    height: '',
                });
            }

            if (selectedComponent.custom['personalization-text'] && selectedComponent.custom['personalization-text'].length > 0) {
                controller.updatePersonalization(placementCode, '');
            }

            controller.commitCustomAttributes(placementCode, {
                'personalization-filled': null,
                'personalization-size-fit-min': 'none',
                'layer-rendered': null,
            }, {
                propagate: true,
            });

            // Clear rendering specific things.
            selectedPlacement.custom['last-personalization-x'] = null;
            selectedPlacement.custom['last-personalization-y'] = null;

            controller.clearComponent(placementCode);
        },

        /**
         * Moves a layer.
         */
        moveLayer(controller, oldIndex, newIndex) {
            const newList = [...this.layers];

            const el = newList[oldIndex];

            newList.splice(oldIndex, 1);

            newList.splice(newIndex, 0, el);

            let index = 1;

            newList.forEach((layer) => {
                controller.commitCustomPlacementAttributes(layer.state.placement.code, {
                    'explicit-order': index,
                });

                // TODO: Controller does not trigger the state change on placement attributes.
                controller.stateChanged();

                index += 1;
            });
        },

        /**
         * Sets selected layer.
         */
        setSelectedLayer(l) {
            this.selectedLayer = l;
        },

        /**
         * Generate a combined recipe.
         * The param exitAfterCreatingRecipe is for enabling share functionality. It is forwarded
         * to the addToCartSave function, and if the bool is true, it will exit the function
         * after generating a recipe but before adding anything to the cart.
         */
        addToCart(exitAfterCreatingRecipe = false) {
            const snapshots = [];

            // Create local snapshots.
            const controllers = [this.viewController];

            this.viewController.socketsState.forEach((s) => {
                if (s.to) {
                    controllers.push(s.to);
                }
            });

            controllers.forEach((controller) => {
                controller.blueprint.views.forEach((view) => {
                    if (view.bakeInRecipe && view.viewType === 'Camera') {
                        snapshots.push({
                            controller: controller.id,
                            view,
                        });
                    }
                });
            });

            const images = [];

            return this.addToCartPrerender(snapshots, images, exitAfterCreatingRecipe);
        },

        addToCartPrerender(snapshots, images, exitAfterCreatingRecipe = false) {
            return new Promise((resolve) => {
                this.addToCartPrerenderInternal(snapshots, images, exitAfterCreatingRecipe, resolve);
            });
        },

        addToCartPrerenderInternal(snapshots, images, exitAfterCreatingRecipe = false, resolver = undefined) {
            if (snapshots.length > 0) {
                const view = snapshots.shift();

                emitter.$emit('snapshot', {
                    controllerId: view.controller,
                    view: view.view.code,
                    done: (data) => {
                        images.push({
                            controller: view.controller,
                            view: view.view.code,
                            data,
                        });

                        this.addToCartPrerenderInternal(snapshots, images, exitAfterCreatingRecipe, resolver);
                    },
                });
            } else {
                this.addToCartSave(images, exitAfterCreatingRecipe, resolver);
            }
        },

        addToCartSave(images, exitAfterCreatingRecipe = false, resolver = undefined) {
            const controllers = {};
            const tlaControllers = {};

            // The top level controller should only have the phone and no custom components, so we need to save only controllers
            // that are attached to some sockets.
            this.viewController.allSockets().forEach((socket) => {
                if (socket.on) {
                    if (socket.on.blueprint?.custom?.['ps-save-recipe'] === 'true' && socket.on.blueprint?.custom?.['ps-recipe-order']) {
                        if (controllers[socket.on.id] == null) {
                            controllers[socket.on.id] = socket.on;
                        }
                    }

                    if (socket.on.blueprint?.custom?.['ps-recipe-order']) {
                        if (tlaControllers[socket.on.id] == null) {
                            tlaControllers[socket.on.id] = socket.on;
                        }
                    }
                }
            });

            const tasks = [];

            let emptyCustomization = true;

            Object.values(controllers).forEach((controller) => {
                controller.state.forEach((s) => {
                    if (s.placement.code.startsWith(constants.PLACEMENT_TEXT)) {
                        if (ComponentUtilities.isFilled(s.component)) {
                            emptyCustomization = false;
                        }
                    }

                    if (s.placement.code.startsWith(constants.PLACEMENT_IMAGE)) {
                        if (ComponentUtilities.isFilled(s.component)) {
                            emptyCustomization = false;
                        }
                    }
                });
            });

            Object.values(controllers).forEach((controller) => {
                tasks.push(new Promise((resolve) => {
                    controller.saveRecipe({
                        translateRecipeAfter(recipe) {
                            // Assign thumbnails.
                            images.forEach((image) => {
                                const view = recipe.views.find((v) => v.code === image.view && controller.id === image.controller);

                                if (view) {
                                    view.clientRender = image.data;
                                }
                            });
                        },
                    }).then((recipe) => {
                        if (recipe.location) {
                            fetch(recipe.location)
                                .then((response) => response.json())
                                .then((data) => {
                                    resolve(data);
                                });
                        }
                    });
                }));
            });

            Promise.all(tasks).then((recipes) => {
                // Arrange recipes in the order.
                const ordered = [];

                recipes.forEach((r) => {
                    if (r.custom['ps-recipe-order']) {
                        ordered.push({
                            order: +r.custom['ps-recipe-order'],
                            recipe: r,
                        });
                    }
                });

                ordered.sort((a, b) => a.order - b.order);

                // Calculate the TLA mapping source.
                const tlaOrdered = [];

                Object.values(tlaControllers).forEach((controller) => {
                    if (controller.blueprint.custom['ps-recipe-order']) {
                        tlaOrdered.push({
                            order: +controller.blueprint.custom['ps-recipe-order'],
                            controller,
                        });
                    }
                });

                tlaOrdered.sort((a, b) => a.order - b.order);

                const tlaParts = ['C3'];

                tlaOrdered.forEach((r) => {
                    if (r.controller.blueprint.custom['ps-skip-tla'] === 'true') {
                        return;
                    }

                    r.controller.state.forEach((placement) => {
                        if (placement.component?.custom?.['ps-tla']) {
                            const prefix = r.controller.blueprint.custom?.['ps-tla-prefix'];
                            if (prefix) {
                                tlaParts.push(prefix);
                            }

                            tlaParts.push(placement.component?.custom?.['ps-tla']);
                        }
                    });
                });

                if (this.selectedBundle?.tlaSuffix) {
                    tlaParts.push(this.selectedBundle.tlaSuffix);
                }

                // Now save the main recipe.
                this.viewController.saveRecipe({
                    translateRecipe: (recipe) => {
                        ordered.forEach((r) => {
                            if (recipe.custom[`ps-recipe-source-${r.order}`] == null) {
                                recipe.custom[`ps-recipe-source-${r.order}`] = r.recipe.id;
                            } else {
                                let dedup = 1;

                                while (recipe.custom[`ps-recipe-source-${r.order}-${dedup}`]) {
                                    dedup += 1;
                                }

                                recipe.custom[`ps-recipe-source-${r.order}-${dedup}`] = r.recipe.id;
                            }
                        });

                        // Save the style code.
                        recipe.custom['ps-style-code'] = recipe.styleCode;

                        const tlas = tlaParts.join('--');

                        recipe.custom.tla = tlas;
                        recipe.custom.sku = tlas;

                        recipe.name = tlas;
                        recipe.styleCode = tlas;

                        recipe.custom['ps-empty-customization'] = emptyCustomization ? 'true' : 'false';
                        recipe.custom['ps-from-bundle'] = this.selectedBundle?.product;

                        if (this.selectedBundle?.addon1) {
                            recipe.custom['external-product-id-addon-1'] = this.selectedBundle?.addon1;
                            recipe.custom['order-extra-sku-1'] = this.selectedBundle?.addon1;
                        }

                        if (this.useLooks && this.selectedBundle?.looksAddon1) {
                            recipe.custom['order-extra-sku-1'] = this.selectedBundle?.looksAddon1;
                        }
                    },

                    translateRecipeAfter: (recipe) => {
                        const indexes = {};

                        // Add production views from other recipes.
                        ordered.forEach((r) => {
                            if (r.recipe.views) {
                                r.recipe.views.forEach((view) => {
                                    if (view.productionReady) {
                                        const addView = { ...view };

                                        if (addView.custom == null) {
                                            addView.custom = {};
                                        }

                                        addView.custom.recipe = addView.preview;

                                        delete addView.preview;
                                        delete addView.clientRender;

                                        let viewIndex = null;
                                        if (indexes[addView.code]) {
                                            indexes[addView.code] += 1;
                                            viewIndex = indexes[addView.code];
                                        } else {
                                            indexes[addView.code] = 1;
                                            viewIndex = indexes[addView.code];
                                        }

                                        addView.name = addView.name.replace('Composite 1', `Composite ${viewIndex}`);
                                        addView.code = addView.code.replace('Composite1', `Composite${viewIndex}`);

                                        recipe.views.push(addView);
                                    } else if (view.custom?.['ps-collect-view'] === 'true') {
                                        const addView = { ...view };

                                        if (addView.custom == null) {
                                            addView.custom = {};
                                        }

                                        addView.custom.recipe = addView.preview;

                                        delete addView.preview;
                                        delete addView.clientRender;

                                        let viewIndex = null;
                                        if (indexes[addView.code]) {
                                            indexes[addView.code] += 1;
                                            viewIndex = indexes[addView.code];
                                        } else {
                                            indexes[addView.code] = 1;
                                            viewIndex = indexes[addView.code];
                                        }

                                        addView.name = addView.name.replace(/1$/, `${viewIndex}`);
                                        addView.code = addView.code.replace(/1$/, `${viewIndex}`);

                                        recipe.views.push(addView);
                                    }
                                });
                            }
                        });

                        // Assign thumbnails.
                        images.forEach((image) => {
                            const view = recipe.views.find((v) => v.code === image.view && this.viewController.id === image.controller);

                            if (view) {
                                view.clientRender = image.data;
                            }
                        });
                    },
                }).then((recipe) => {
                    const appStore = useAppStore();

                    let layers = 0;

                    this.viewController.forAllControllers((controller) => {
                        controller.state.forEach((s) => {
                            if (s.component.custom['widget-type'] === 'personalization'
                                || s.component.custom['widget-type'] === 'dynamic-image') {
                                if (ComponentUtilities.isFilled(s.component)) {
                                    layers += 1;
                                }
                            }
                        });
                    });

                    if (exitAfterCreatingRecipe && resolver) {
                        // don't add to cart or close customizer,
                        // just resolve the recipe to be shared.
                        this.trackAnalyticsEvent({
                            action: 'cyo_share_recipe',
                            label: `layers_${layers}_recipe_${recipe.id}`,
                        });

                        resolver(recipe);
                    } else {
                        // proceed with add to cart and close customizer
                        this.trackAnalyticsEvent({
                            action: 'cyo_add_to_bag',
                            label: `layers_${layers}_recipe_${recipe.id}`,
                        });

                        if (appStore.options && appStore.options.onDone) {
                            appStore.options.onDone(recipe);
                        }

                        this.globalDone();
                    }
                });
            });
        },

        setLastSelectedPhoneColor(placement, component) {
            this.lastSelectedPhonePlacement = placement;
            this.lastSelectedPhoneColor = component;
        },

        fixSpaces(v) {
            return (v ?? '').replace(' +', '&nbsp;+');
        },

        setAiShown() {
            this.aiShown = true;
        },

        setUseLooks(v) {
            this.useLooks = v;
        },

        stashLayers(backgroundImage, overlayImage, prompt) {
            this.stashBackgroundImage = backgroundImage;
            this.stashOverlayImage = overlayImage;
            this.stashPrompt = prompt;
        },

        setCanCheckStashedLayers(v) {
            this.canCheckStashedLayers = v;
        },

        checkStashedLayers(controller) {
            if (!this.canCheckStashedLayers) {
                return;
            }

            if (this.stashBackgroundImage) {
                this.generateLayers(this.stashBackgroundImage, this.stashOverlayImage, this.stashPrompt, controller);
            }

            this.stashLayers(null, null);
        },

        addLayer(image, controller, placement) {
            return new Promise((resolve) => {
                this.uploadAndUseImage(
                    controller,
                    placement,
                    image,
                    'images',
                ).then((uploaded) => {
                    resolve(uploaded);
                });
            });
        },

        async generateLayers(image, overlay, prompt, controller, placementCode) {
            let firstLayer = null;

            if (image) {
                try {
                    // Which placement did we add the image to?
                    const useController = controller ?? this.currentController ?? this.viewController;

                    const placement = placementCode ? useController.findPlacement(placementCode) : null;

                    const used = await this.addLayer(image, controller, placement);

                    if (firstLayer == null && used != null) {
                        firstLayer = used;
                    }

                    if (used != null) {
                        const found = useController.state.find((x) => x.component.custom?.['dynamic-image-url'] === used.imageUrl);

                        if (found) {
                            useController.commitCustomAttributes(found.placement.code, {
                                'ps-ai-prompt': prompt?.prompt ?? '',
                                'ps-ai-prompt-raw': prompt?.raw ?? '',
                                'ps-ai-style': prompt?.style ?? '',
                            });
                        }
                    }
                } catch (e) {
                    // Nothing.
                }
            }

            if (overlay) {
                try {
                    const used = await this.addLayer(overlay, controller);

                    if (firstLayer == null && used != null) {
                        firstLayer = used;
                    }
                } catch (e) {
                    // Nothing.
                }
            }

            this.generating = null;

            return firstLayer;
        },

        /**
         * Recursively iterates through all buttons.
         */
        forAllButtons(callback, root, parent, allButtons) {
            for (const b of root) {
                if (b.selectionButtons) {
                    for (const s of b.selectionButtons) {
                        if (allButtons) {
                            callback(s, parent ?? b);
                        } else if (s.tokenString && s.tokenString.length > 0) {
                            callback(s, parent ?? b);
                        }
                    }
                }

                if (b.subButtons) {
                    this.forAllButtons(callback, b.subButtons, parent ?? b, allButtons);
                }
            }
        },

        /**
         * Check if there are empty products.
         */
        checkEmpty() {
            let hasEmpty = false;
            let emptyType = null;

            if (this.viewController) {
                this.viewController.forAllControllers((controller) => {
                    if (controller.blueprint.custom['ps-save-recipe'] === 'false') {
                        return;
                    }

                    let canCustomize = false;
                    let hasCustomization = false;

                    controller.state.forEach((s) => {
                        if (s.placement.code.startsWith(constants.PLACEMENT_TEXT)) {
                            canCustomize = true;

                            if (ComponentUtilities.isFilled(s.component)) {
                                hasCustomization = true;
                            }
                        }

                        if (s.placement.code.startsWith(constants.PLACEMENT_IMAGE)) {
                            canCustomize = true;

                            if (ComponentUtilities.isFilled(s.component)) {
                                hasCustomization = true;
                            }
                        }
                    });

                    if (canCustomize && !hasCustomization) {
                        hasEmpty = true;

                        if (controller.blueprint.custom['ps-empty-type']) {
                            emptyType = controller.blueprint.custom['ps-empty-type'];
                        }
                    }
                });
            }

            return {
                hasEmpty,
                emptyType,
            };
        },

        /**
         * Check how many customizable surfaces we have.
         */
        countSurfaces() {
            let count = 0;

            if (this.viewController) {
                this.viewController.forAllControllers((controller) => {
                    if (controller.blueprint.custom['ps-save-recipe'] === 'false') {
                        return;
                    }

                    let canCustomize = false;

                    controller.state.forEach((s) => {
                        if (s.placement.code.startsWith(constants.PLACEMENT_TEXT)) {
                            canCustomize = true;
                        }

                        if (s.placement.code.startsWith(constants.PLACEMENT_IMAGE)) {
                            canCustomize = true;
                        }
                    });

                    if (canCustomize) {
                        count += 1;
                    }
                });
            }

            return count;
        },

        /**
         * Get xray element names.
         */
        xrayNames() {
            const names = [];

            if (this.viewController) {
                this.viewController.forAllControllers((controller) => {
                    if (controller.blueprint.custom['ps-save-recipe'] === 'false') {
                        return;
                    }

                    if (controller.blueprint.custom['ps-xray-name']) {
                        names.push(controller.blueprint.custom['ps-xray-name']);
                    }
                });
            }

            return names;
        },

        clearXRayed(xrayId) {
            if (xrayId) {
                this.viewController.forAllControllers((controller) => {
                    let projected = null;

                    for (const s of controller.state) {
                        if (s.component.custom['ps-xray-id'] === xrayId) {
                            projected = s;
                        }
                    }

                    if (projected) {
                        this.clearPlacement(controller, projected.placement.code);
                    }
                });
            }
        },

        /**
         * Track event.
         */
        trackAnalyticsEvent(trackingEvent) {
            const appStore = useAppStore();

            appStore.trackAnalyticsEvent(trackingEvent);

            if (trackingEvent.event && this.viewController) {
                this.viewController.trackAction({
                    event: trackingEvent.event,
                    action: trackingEvent.action,
                    label: trackingEvent.label,
                });
            }
        },

        /**
         * Track event.
         */
        trackAnalyticsDriveOnly(trackingEvent) {
            if (trackingEvent.event && this.viewController) {
                this.viewController.trackAction({
                    category: trackingEvent.category,
                    event: trackingEvent.event,
                    action: trackingEvent.action,
                    label: trackingEvent.label,
                    page: trackingEvent.page,
                    screen: trackingEvent.screen,
                    step: trackingEvent.step,
                    button: trackingEvent.button,
                });
            }
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCustomizerStore, import.meta.hot));
}
