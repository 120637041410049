<template>
    <div class="cz3__upload-image"
        ref="uploader"
    >
        <div class="cz3__upload-image__header">
            <div class="cz3__type--title cz3__type--center">
                {{$t('ART_UPLOAD_IMAGE')}}
            </div>
        </div>

        <div ref="uploadArea" class="cz3__upload-image__body cz3__uppy">
        </div>

        <div class="cz3__upload-image__prompt">
            <div class="cz3__upload-image__prompt-buttons">
                <button
                    id="back"
                    class="cz3__grey-rounded-no-shadow-button"
                    @click.prevent="back"
                >
                    {{$t('BACK')}}
                </button>
            </div>
        </div>

        <transition name="cz3__fade">
            <div v-if="showUploadDisclaimer" class="cz3__upload-disclaimer-popup">
                <div class="cz3__upload-disclaimer-popup__body" v-html="$t('UPLOAD_DISCLAIMER_LONG')">
                </div>

                <div class="cz3__upload-disclaimer-popup__confirm">
                    <button
                        id="confirmTos"
                        class="cz3__outline-button cz3__outline-button--responsive"
                        @click.prevent="confirmDisclaimer"
                    >
                        {{$t('AGREE')}}
                    </button>
                </div>

                <div class="cz3__upload-disclaimer-popup__back">
                    <button
                        id="back"
                        class="cz3__grey-rounded-no-shadow-button"
                        @click.prevent="back"
                    >
                        {{$t('BACK')}}
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__upload-image {
            position: absolute;

            left: 20px;
            top: 20px;
            right: 20px;
            bottom: 20px;

            display: flex;

            flex-direction: column;

            @include respond-above(sm) {
                left: 39px;
                top: 39px;
                right: 39px;
                bottom: 39px;
            }
        }

        .cz3__upload-disclaimer-popup {
            position: absolute;

            left: 0;
            top: 0;
            right: 0;
            bottom: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            background: #fff;
        }

        .cz3__upload-disclaimer-popup__body {
            font-family: $font-main;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            color: $color-primary-black;

            text-align: center;

            @include respond-above(sm) {
                font-size: 14px;
                line-height: 20px;
            }

            a {
                text-decoration: underline;
                text-underline-offset: 3px;

                color: $color-primary-black;
            }
        }

        .cz3__upload-disclaimer-popup__confirm {
            margin-top: 25px;

            text-align: center;
        }

        .cz3__upload-disclaimer-popup__back {
            position: absolute;

            left: 0;
            bottom: 0;
        }

        .cz3__upload-image__header {
            flex: 0 0 auto;
        }

        .cz3__upload-image__body {
            position: relative;

            flex: 1 1 0;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: start;

            padding-top: 20px;

            overflow: auto;
            --webkit-overflow-scrolling: touch;

            .cz3__upload-disclaimer {
                margin-top: 10px;

                font-family: $font-main;
                font-style: normal;
                font-weight: 400;
                font-size: 9px;
                line-height: 12px;

                a {
                    color: $color-primary-black;
                }
            }
        }

        .cz3__upload-image__prompt {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            flex: 0 0 auto;

            > * {
                flex: 1 1 0;
            }
        }

        .cz3__upload-image__prompt-buttons {
            flex: 0 0 auto;

            display: flex;
            justify-content: space-between;

            button {
                flex: 0 0 auto;
            }
        }

        .uppy-Informer-animated {
            p {
                background: #b00b00;
                color: #fff;

                span {
                    display: none;
                }
            }
        }

        .cz3__upload-image__body {
            position: relative;

            flex: 1 1 0;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: start;

            padding-top: 20px;

            overflow: auto;
            --webkit-overflow-scrolling: touch;

            .uppy-Root {
                @include absolute-overlay();
            }

            .uppy-Dashboard {
                @include absolute-overlay();
            }

            .uppy-Dashboard-inner {
                @include absolute-overlay();

                width: 100% !important;
                height: 100% !important;

                background: #fff;
                border: none;
            }

            .uppy-Dashboard-dropFilesHereHint {
                left: 0px;
                top: 15px;
                right: 0px;
                bottom: 15px;

                border: 2px dashed $color-gray-mid;
                border-radius: 24px;

                font-family: $font-main;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;

                color: $color-primary-black;
                background-image: url(../assets/drop.svg) !important;
            }

            .uppy-DashboardTab {
                border: none;
            }

            .uppy-DashboardTab-btn {
                position: relative;

                max-width: 230px;

                margin-bottom: 16px;
                margin-left: auto;
                margin-right: auto;

                padding: 0 12px;
                padding-left: 35px;

                background: transparent;
                color: $color-primary-black;

                border: 1px solid $color-primary-black;
                border-radius: 24px;

                height: 30px;

                @include respond-above(sm) {
                    max-width: 300px;

                    margin-bottom: 25px;

                    height: 40px;
                }

                svg {
                    display: none;
                }
            }

            .uppy-Dashboard-AddFiles {
                position: relative;

                padding-top: 300px;
                border: none;
            }

            .uppy-Dashboard-AddFiles-title {
                height: 0px;
                margin: 0;
                padding: 0;
            }

            .uppy-Dashboard-browse {
                position: absolute;

                left: 50%;
                top: 50px;

                width: 200px;
                height: 200px;

                transform: translate(-50%, 0);

                border: 2px dashed $color-gray-mid;
                border-radius: 24px;

                background: url(../assets/upload.svg) !important;
                background-position: center !important;
                background-repeat: no-repeat !important;
                background-size: 50px 50px;

                overflow: hidden;
                text-indent: -9999px;
            }

            [data-uppy-acquirer-id="MyDevice"] {
                .uppy-DashboardTab-btn {
                    &:before {
                        content: '';

                        position: absolute;

                        left: 8px;
                        top: 50%;

                        width: 24px;
                        height: 24px;

                        transform: translate(0, -50%);

                        background: url(../assets/icon-mydevice.svg) !important;

                        background-repeat: no-repeat;
                        background-size: contain !important;
                        background-position: center;
                    }
                }
            }

            [data-uppy-acquirer-id="Instagram"] {
                .uppy-DashboardTab-btn {
                    &:before {
                        content: '';

                        position: absolute;

                        left: 8px;
                        top: 50%;

                        width: 24px;
                        height: 24px;

                        transform: translate(0, -50%);

                        background: url(../assets/icon-instagram.svg) !important;

                        background-repeat: no-repeat;
                        background-size: contain !important;
                        background-position: center;
                    }
                }
            }

            .uppy-DashboardTab-name {
                font-family: $font-main;
                font-style: normal;
                font-weight: 400;

                line-height: 30px;
                font-size: 12px;

                @include respond-above(sm) {
                    line-height: 40px;
                    font-size: 16px;
                }
            }

            .uppy-DashboardContent-title {
                display: none;
            }

            .uppy-DashboardContent-panel {
                left: 0px;
                right: 0px;
                bottom: 15px;
                top: 15px;

                border-radius: 12px;
            }

            .uppy-DashboardContent-panel {
                background: #fff;
            }

            .uppy-DashboardContent-bar {
                height: 60px;
                background: #fff;
            }

            .uppy-c-btn-primary {
                padding: 0 12px;

                background: transparent;
                color: $color-primary-black;

                border: 1px solid $color-primary-black;
                border-radius: 24px;

                height: 30px;

                font-family: $font-main;
                font-style: normal;
                font-weight: 400;

                line-height: 30px;
                font-size: 12px;

                white-space: nowrap;

                @include respond-above(sm) {
                    height: 40px;

                    font-size: 16px;
                    line-height: 40px;
                }
            }

            .uppy-DashboardContent-back, .uppy-c-btn-link {
                height: 30px;

                padding: 0 12px;

                font-family: $font-main;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 30px;

                background: $color-gray-light;
                color: $color-primary-black;

                border: 1px solid $color-gray-light;
                border-radius: 8px;

                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
            }

            .uppy-c-btn-link {
                @include respond-above(sm) {
                    height: 40px;

                    font-size: 16px;
                    line-height: 40px;
                }
            }

            .uppy-StatusBar-actionBtn--done {
                display: none;
            }

            .uppy-Dashboard-AddFiles-info {
                padding-bottom: 30px;
            }

            .uppy-Dashboard-note {
                font-family: $font-main;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;

                color: $color-primary-black;
            }

            .uppy-Dashboard-progressindicators {
                position: absolute;

                left: 0;
                width: 100%;
                bottom: 0;

                background: #fff;
            }

            .cz3__upload-disclaimer {
                margin-top: 10px;

                font-family: $font-main;
                font-style: normal;
                font-weight: 400;
                font-size: 9px;
                line-height: 12px;

                a {
                    color: $color-primary-black;
                }
            }
        }
    }
</style>

<script>
    import Uppy from '@uppy/core';
    import Dashboard from '@uppy/dashboard';
    import Instagram from '@uppy/instagram';
    import XhrUpload from '@uppy/xhr-upload';
    import Informer from '@uppy/informer';

    import allStores from '../stores/all';

    export default {
        mixins: [allStores],

        props: {
        },

        data() {
            return {
                uploadError: null,
                showUploadDisclaimer: false,
            };
        },

        computed: {
            viewController() {
                return this.customizerStore.viewController;
            },
        },

        watch: {
            uploadError() {
                if (this.uploadError && this.uppy) {
                    this.uppy.info({
                        message: this.uploadError,
                    }, 'custom-error', 5000);
                }
            },
        },

        mounted() {
            this.showUppy();

            if (this.viewController?.blueprint?.custom?.['ps-show-upload-disclaimer'] === 'yes') {
                if (!this.appStore.uploadDisclaimerShown) {
                    this.showUploadDisclaimer = true;
                }
            }
        },

        beforeUnmount() {
            if (this.uppy) {
                this.uppy.close();

                this.uppy = null;
            }
        },

        methods: {
            back() {
                this.$emit('back');
            },

            showUppy() {
                if (this.uppy) {
                    this.uppy.close();

                    this.uppy = null;
                }

                if (!this.$refs.uploadArea) {
                    return;
                }

                this.uppy = new Uppy({
                    autoProceed: true,
                    allowMultipleUploads: false,
                    restrictions: {
                        maxNumberOfFiles: 1,
                        maxFileSize: 10 * 1024 * 1024,
                        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif', '.heic', '.tiff'],
                    },
                    locale: {
                        strings: {
                            dropHint: this.$t('UPLOAD_DROPHINT'),

                            myDevice: this.$t('UPLOAD_MYDEVICE'),
                            pluginNameInstagram: this.$t('UPLOAD_INSTAGRAM'),

                            youCanOnlyUploadFileTypes: this.$t('FILE_TYPE_ERROR'),

                            browseFiles: this.$t('UPLOAD_BROWSE'),
                            dropPasteImportFiles: '%{browseFiles}',

                            cancel: this.$t('UPLOAD_CANCEL'),

                            authenticateWith: this.$t('UPLOAD_AUTHENTICATEWITH'),
                            authenticateWithTitle: this.$t('UPLOAD_AUTHENTICATE'),

                            loading: this.$t('UPLOAD_LOADING'),
                            logOut: this.$t('UPLOAD_LOGOUT'),

                            uploading: this.$t('UPLOAD_UPLOADING'),
                            complete: this.$t('UPLOAD_COMPLETE'),
                            done: this.$t('UPLOAD_DONE'),

                            selectX: {
                                0: this.$t('UPLOAD_0_FILES'),
                                1: this.$t('UPLOAD_1_FILES'),
                                2: this.$t('UPLOAD_2_FILES'),
                            },
                        },
                    },
                });

                this.uppy.use(Dashboard, {
                    target: this.$refs.uploadArea,
                    inline: true,
                    replaceTargetContent: true,
                    proudlyDisplayPoweredByUppy: false,
                    showSelectedFiles: false,
                    disableInformer: true,
                    hideCancelButton: true,
                    hidePauseResumeButton: true,
                    hideRetryButton: true,
                    note: this.$t('UPLOAD_NOTE'),
                    width: 900,
                    height: window.innerHeight < 1024 ? 450 : 500,
                });

                this.uppy.use(Informer, {
                    target: this.$refs.uploader,
                });

                this.$nextTick(() => {
                    // Insert disclaimer manually.
                    const $note = this.$el.querySelector('.uppy-Dashboard-note');

                    if ($note) {
                        const template = document.createElement('template');
                        const html = `<div class="cz3__upload-disclaimer">${this.$t('UPLOAD_DISCLAIMER')}</div>`;
                        template.innerHTML = html;

                        $note.append(template.content.firstChild);
                    }
                });

                this.uppy.use(Instagram, {
                    target: Dashboard,
                    companionUrl: 'https://popsockets.images.drivecommerce.com',
                });

                let endpoint = '//api.images.drivecommerce.com/api/v1/';

                if (this.viewController.blueprint.imageRootUrl) {
                    endpoint = this.viewController.blueprint.imageRootUrl.replace('http://', '//').replace('https://', '//');
                }

                endpoint = window.location.protocol + endpoint;

                endpoint += endpoint.substr(-1) === '/' ? 'upload' : '/upload';

                // Prefer direct.
                endpoint = endpoint.replace('//fx.images', '//api.images');

                const processUploadError = (responseText) => {
                    const response = JSON.parse(responseText);

                    if (response.details) {
                        switch (response.details.error) {
                        case 'minimum-dimensions': {
                            const error = this.$t('IMAGE_SIZE_ERROR')
                                .replace('{uploaded}', response.details.uploaded)
                                .replace('{required}', response.details.required);

                            this.uploadError = error;

                            return new Error(error);
                        }
                        case 'maximum-dimensions': {
                            const error = this.$t('IMAGE_MAX_SIZE_ERROR')
                                .replace('{uploaded}', response.details.uploaded)
                                .replace('{required}', response.details.required);

                            this.uploadError = error;

                            return new Error(error);
                        }
                        default:
                            break;
                        }
                    }

                    if (response.modelState) {
                        if (response.modelState[''] && response.modelState[''].length > 0) {
                            // eslint-disable-next-line
                            this.uploadError = response.modelState[''][0];

                            return new Error(response.modelState[''][0]);
                        }
                    }

                    if (response.message) {
                        return new Error(response.message);
                    }

                    return new Error('The request is invalid');
                };

                this.uppy.use(XhrUpload, {
                    endpoint,
                    formData: true,
                    fieldName: 'file',
                    metaFields: ['min-width', 'min-height', 'jpeg-preview-size', 'jpeg-preview-background'],
                    getResponseError: processUploadError,
                });

                this.uppy.on('file-added', (file) => {
                    this.uploadError = null;

                    this.uppy.setFileMeta(file.id, {
                        'min-width': 300,
                        'min-height': 300,
                        'jpeg-preview-size': 1000,
                        'jpeg-preview-background': '255,255,255,255',
                    });
                });

                this.uppy.on('upload-error', () => {
                    this.uppy.reset();
                });

                this.uppy.on('upload-success', (file, body) => {
                    if (file && file.source) {
                        // this is something of a guess... need to validate
                        // what file.source is on IG upload
                        if (file.source === 'Dashboard') {
                            // track own upload
                            this.customizerStore.trackAnalyticsEvent({
                                action: 'cyo_deviceupload',
                            });
                        } else {
                            // track instagram upload
                            this.customizerStore.trackAnalyticsEvent({
                                action: 'cyo_instagramupload',
                            });
                        }
                    }
                    this.$emit('uploaded', {
                        ...{ title: file.name },
                        ...body.body,
                    });
                });

                this.uppy.on('restriction-failed', (file, error) => {
                    if (file && file.size > 10 * 1024 * 1024) {
                        this.uploadError = this.$t('FILE_LIMIT_SIZE');
                    } else {
                        this.uploadError = error.message;
                    }
                });

                // HACK: Hate it, but can't find how to filter messages otherwise.
                const uppyInfo = this.uppy.info;

                this.uppy.info = function uppyInfoReplaced(message, type, delay) {
                    if (type === 'custom-error') {
                        uppyInfo.call(this, message, type, delay);
                    }
                };
            },

            confirmDisclaimer() {
                this.appStore.setUploadDisclaimerShown(true);
                this.showUploadDisclaimer = false;

                this.customizerStore.trackAnalyticsEvent({
                    action: 'cyo_agree',
                });
            },
        },
    };
</script>
