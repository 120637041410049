<template>
    <div class="cz3__options cz3__options-phone cz3__renderer-exclude">
        <div class="cz3__mb--standard cz3__type--header">
            {{$t('SELECT_PHONE')}}
        </div>

        <div class="cz3__mb--large">
            <vue-multiselect
                :options="configurablePhones"
                label="name"
                track-by="code"
                :allow-empty="false"
                :close-on-select="true"
                :searchable="false"
                :show-labels="false"
                :model-value="selectedPhone"
                @update:model-value="updateSelectedPhone"
            />
        </div>

        <template v-if="configurableCases && configurableCases.length > 0">
            <div v-if="configurableCases.length === 1" class="cz3__options-phone__case-type-spacer"></div>

            <!-- Hidden for now: PAP-2597 -->
            <template v-else>
                <div class="cz3__mb--standard cz3__type--header cz3__type--no-wrap">
                    {{$t('SELECT_CASE')}}
                    <button
                        id="case-info"
                        class="cz3__icon-button cz3__select-case-info"
                        @click.prevent="showCaseInfo">
                        <icon-info/>
                    </button>
                </div>

                <div class="cz3__options-phone__case-type">
                    <toggle-select
                        :options="configurableCases"
                        label="name"
                        track-by="code"
                        :model-value="selectedCase"
                        @update:model-value="updateSelectedCase"
                    />
                </div>
            </template>
        </template>

        <preorder-bar v-if="preorder" :bundle="selectedBundle">
        </preorder-bar>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__options.cz3__options-phone {
            height: auto;
            width: 65%;
            left: 17.5%;

            text-align: center;

            @include respond-above(sm) {
                width: 100%;
                left: calc(50% - 142px);

                text-align: left;

                max-width: 284px;
            }
        }

        .cz3__select-case-info {
            margin-left: 5px;

            vertical-align: -4px;

            svg {
                width: 20px;
                height: 20px;
            }
        }

        .cz3__options-phone__case-type {
            margin-bottom: $margin-options-large;
        }

        .cz3__options-phone__case-type-spacer {
            // min-height: 38px;

            margin-bottom: 32px; // $margin-options-large;
        }
    }
</style>

<script>
    import VueMultiselect from 'vue-multiselect';

    import ToggleSelect from './ToggleSelect.vue';
    import PreorderBar from './PreorderBar.vue';

    import allStores from '../stores/all';

    import * as constants from '../constants';

    import IconInfo from '../assets/info.svg';

    export default {
        mixins: [allStores],

        components: {
            VueMultiselect,
            ToggleSelect,
            PreorderBar,
            IconInfo,
        },

        data() {
            return {
                selectedPhone: null,
                selectedCase: null,

                selectedPhoneType: null,
            };
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },

            viewController() {
                return this.customizerStore.viewController;
            },

            socketsState() {
                return this.viewController.socketsState;
            },

            prebuiltOptions() {
                return this.customizerStore.prebuiltOptions;
            },

            selectedBundle() {
                return this.customizerStore.selectedBundle;
            },

            preorder() {
                return this.selectedBundle?.preorderTitle;
            },

            selectableBundles() {
                return this.customizerStore.selectableBundles;
            },

            configurablePhones() {
                const phones = [];

                if (this.viewController == null) {
                    return phones;
                }

                const options = this.viewController.findPlacement(constants.PLACEMENT_PHONE);
                if (options == null) {
                    return phones;
                }

                options.components.forEach((c) => {
                    if (this.viewController.isAvailable(c)) {
                        phones.push({
                            code: c.code,
                            name: this.viewController.componentNames(c).join(''),
                        });
                    }
                });

                return phones;
            },

            phonePrebuiltOptions() {
                return this.prebuiltOptions.filter((o) => o.phone === this.selectedPhoneType);
            },

            phoneHasMagSafeOptions() {
                return this.phonePrebuiltOptions.some((o) => o.magSafe);
            },

            phoneHasStandardOptions() {
                return this.phonePrebuiltOptions.some((o) => !o.magSafe);
            },

            configurableCases() {
                const cases = [];

                if (this.phoneHasMagSafeOptions) {
                    cases.push({
                        code: 'magSafe',
                        name: 'MagSafe',
                    });
                }

                if (this.phoneHasStandardOptions) {
                    cases.push({
                        code: 'standard',
                        name: 'Standard',
                    });
                }

                return cases;
            },
        },
        watch: {
            viewController() {
                this.updateController();
            },
        },

        mounted() {
            this.updateController();
        },

        beforeUnmount() {
            this.trackCurrent();
        },

        methods: {
            trackCurrent() {
                const phone = this.viewController.selectedAtPlacement(constants.PLACEMENT_PHONE);

                if (phone) {
                    const name = this.viewController.componentNames(phone).join('');

                    this.customizerStore.trackAnalyticsEvent({
                        action: 'cyo_device',
                        label: `device_${name}`,
                    });
                }
            },

            updateController() {
                if (this.viewController == null) {
                    return;
                }

                const selectedPhone = this.viewController.selectedAtPlacement(constants.PLACEMENT_PHONE);
                if (selectedPhone) {
                    this.selectedPhone = this.configurablePhones.find((p) => p.code === selectedPhone.code);

                    const phoneType = selectedPhone.custom['ps-phone'];

                    this.selectedPhoneType = phoneType;
                }

                // TODO: There are currently no "non-MS" options.
                let magSafe = true;

                this.socketsState.forEach((s) => {
                    if (s.to && s.to.blueprint && s.to.blueprint.custom) {
                        if (s.to.blueprint.custom['ps-magsafe'] === 'true') {
                            magSafe = true;
                        }
                    }
                });

                if (magSafe) {
                    this.selectedCase = this.configurableCases.find((c) => c.code === 'magSafe');
                } else {
                    this.selectedCase = this.configurableCases.find((c) => c.code === 'standard');
                }

                if (this.selectedCase) {
                    this.customizerStore.setPreferMagSafe(this.selectedCase.code === 'magSafe');
                }
            },

            updateSelectedPhone(phone) {
                const currentBundle = this.selectedBundle;

                this.viewController.updateComponent(constants.PLACEMENT_PHONE, phone.code);

                this.updateController();

                // Might need to reload both the default assembly and the list of options.
                // updateController will update the selected phone type and recalculate computed options.
                // Thus the $nextTick call.
                this.$nextTick(() => {
                    if (currentBundle) {
                        // Find another bundle.
                        const options = this.selectableBundles;

                        const matching = options.find((o) => o.name === currentBundle.name);

                        if (matching) {
                            this.customizerStore.selectBundle({
                                bundle: matching,
                            });
                        }
                    }
                });
            },

            updateSelectedCase(selectedCase) {
                if (this.configurableCases == null || this.configurableCases.length < 2) {
                    this.showCaseInfo();
                } else {
                    const magSafe = selectedCase.code === 'magSafe';

                    // Find a new default bundle.
                    const bundle = this.phonePrebuiltOptions.find((o) => o.magSafe === magSafe);

                    if (bundle != null) {
                        this.customizerStore.selectBundle({
                            bundle,
                        });
                    }
                }
            },

            showCaseInfo() {
                this.appStore.confirmPopup({
                    message: this.$t('CASE_INFO_BODY'),
                    confirmButton: this.$t('CASE_INFO_CONFIRM'),
                    cancelButton: this.$t('CASE_INFO_CANCEL'),
                    confirmAction: () => {
                        this.appStore.confirmPopup();
                    },
                    cancelAction: () => {
                        this.appStore.confirmPopup();
                    },
                });
            },
        },
    };
</script>
