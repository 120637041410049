<template>
    <div class="cz3__buttons-panel">
        <transition name="cz3__fade">
            <button v-if="showEditButtons && hasAi"
                id="add-ai"
                class="cz3__icon-button cz3__buttons-panel__button"
                :aria-label="$t('ADD_AI')"
                @click.prevent="addAi"
            >
                <icon-add-ai/>

                <transition name="cz3__fade-slow">
                    <span v-if="showTooltips && tooltipAddAiRequested && delayedTooltipsVisible && tooltipAddAiVisible"
                        class="cz3__button-tooltip cz3__button-tooltip--above-right-far">
                        <span class="cz3__button-tooltip__body">
                            {{$t('TOOLTIP_AI')}}
                        </span>
                    </span>
                </transition>
            </button>
        </transition>

        <transition name="cz3__fade">
            <button v-if="showEditButtons"
                id="add-image"
                class="cz3__icon-button cz3__buttons-panel__button"
                :aria-label="$t('ADD_IMAGE')"
                @click.prevent="addImage"
            >
                <icon-add-image/>

                <transition name="cz3__fade-slow">
                    <span v-if="showTooltips && tooltipAddImagesRequested && delayedTooltipsVisible && tooltipAddImagesVisible"
                        class="cz3__button-tooltip cz3__button-tooltip--above-right-far">
                        <span class="cz3__button-tooltip__body">
                            {{$t('TOOLTIP_ADD')}}
                        </span>
                    </span>
                </transition>
            </button>
        </transition>

        <transition name="cz3__fade">
            <button v-if="showEditButtons"
                id="add-sticker"
                class="cz3__icon-button cz3__buttons-panel__button"
                :aria-label="$t('ADD_STICKERS')"
                @click.prevent="addStickers"
            >
                <icon-add-stickers/>
            </button>
        </transition>

        <transition name="cz3__fade">
            <button v-if="showEditButtons && showTextButtons"
                id="add-text"
                class="cz3__icon-button cz3__buttons-panel__button"
                :aria-label="$t('ADD_TEXT')"
                @click.prevent="addText"
            >
                <icon-add-text/>
            </button>
        </transition>

        <button v-if="showPreviewToggle"
            id="show-3d"
            class="cz3__icon-button cz3__buttons-panel__button cz3__buttons-panel__preview"
            :aria-label="$t('PREVIEW_3D')"
            @click.prevent="preview3d"
        >
            <icon-view-3d/>

            <transition name="cz3__fade-slow">
                <span v-if="showView3dTooltips && tooltipView3dRequested && delayedTooltipsVisible && tooltipView3dVisible"
                    class="cz3__button-tooltip cz3__button-tooltip--above-left">
                    <span class="cz3__button-tooltip__body">
                        {{$t('TOOLTIP_VIEW_3D')}}
                    </span>
                </span>
            </transition>
        </button>

        <button v-if="showPreviewClose"
            id="close-3d"
            class="cz3__icon-button cz3__buttons-panel__button cz3__buttons-panel__preview cz3__buttons-panel__preview--close"
            :aria-label="$t('PREVIEW_3D')"
            @click.prevent="preview3d"
        >
            <icon-view-3d-close/>
        </button>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__buttons-panel {
            position: relative;

            display: flex;
            justify-content: center;
            align-content: center;

            width: calc(100vw - 24px);
            height: 40px;
            margin: auto;

            gap: $gap-standard-small;

            @include respond-above(sm) {
                width: $content-large-width;
                height: 52px;
            }
        }

        .cz3__buttons-panel__button {
            position: relative;

            flex: 0 0 auto;

            width: 40px;
            height: 40px;

            @include respond-above(sm) {
                width: 52px;
                height: 52px;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .cz3__buttons-panel__preview {
            position: absolute;

            right: 0;

            &.cz3__buttons-panel__preview--close {
                svg {
                    width: 28px;
                    height: 28px;

                    @include respond-above(sm) {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
        }
    }
</style>

<script>
    import tooltips from './tooltips';

    import allStores from '../stores/all';

    import IconAddAi from '../assets/add-ai.svg';

    import IconAddImage from '../assets/add-image.svg';
    import IconAddStickers from '../assets/add-stickers.svg';
    import IconAddText from '../assets/add-text.svg';
    import IconView3d from '../assets/view-3d.svg';
    import IconView3dClose from '../assets/view-3d-close.svg';

    export default {
        mixins: [allStores, tooltips],

        components: {
            IconAddImage,
            IconAddStickers,
            IconAddText,
            IconView3d,
            IconView3dClose,
            IconAddAi,
        },

        props: {
            showPreviewToggle: {
                type: Boolean,
                default: false,
            },
            showPreviewClose: {
                type: Boolean,
                default: false,
            },
            showEditButtons: {
                type: Boolean,
                default: false,
            },
            showTooltips: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            tooltipAddAiVisible() {
                return this.tooltipVisible('ADD_AI');
            },

            tooltipAddImagesVisible() {
                return this.tooltipVisible('ADD_IMAGES');
            },

            tooltipView3dVisible() {
                return this.tooltipVisible('VIEW_3D');
            },

            tooltipAddAiRequested() {
                return this.tooltipRequested('ADD_AI');
            },

            tooltipAddImagesRequested() {
                return this.tooltipRequested('ADD_IMAGES');
            },

            tooltipView3dRequested() {
                return this.tooltipRequested('VIEW_3D');
            },

            viewController() {
                return this.customizerStore.viewController;
            },

            editViewJoint() {
                return this.customizerStore.editViewJoint;
            },

            selectedJoint() {
                return this.customizerStore.selectedJoint;
            },

            selectedJointEdited() {
                return this.selectedJoint && this.selectedJoint.joint && this.selectedJoint.joint.edited;
            },

            editViewJointEdited() {
                return this.editViewJoint && this.editViewJoint.edited;
            },

            showView3dTooltips() {
                if (this.selectedJointEdited || this.editViewJointEdited) {
                    return true;
                }

                return false;
            },

            showTextButtons() {
                return this.viewController?.blueprint?.custom?.['ps-text-enabled'] !== 'no';
            },

            hasAi() {
                return this.customizerStore.hasAi;
            },
        },

        methods: {
            preview3d() {
                this.updateTooltips();

                this.$emit('preview3d');

                this.customizerStore.trackAnalyticsEvent({
                    action: 'cyo_3D',
                });
            },

            addImage() {
                this.updateTooltips();

                this.$emit('addImage');
            },

            addStickers() {
                this.updateTooltips();

                this.$emit('addStickers');
            },

            addText() {
                this.updateTooltips();

                this.$emit('addText');
            },

            addAi() {
                this.updateTooltips();

                this.$emit('addAi');
            },
        },
    };
</script>
