<template>
    <div class="cz3__add-image">
        <div class="cz3__add-image__body">
            <transition name="cz3__fade">
                <add-image-collections
                    v-if="mode === 'enter'"

                    :images="categories"

                    :caption="isStickers ? $t('ART_STICKERS') : $t('ART_COLLECTIONS')"
                    :prompt="isStickers ? $t('ART_STICKER_UPLOAD_BODY') : $t('ART_UPLOAD_BODY')"
                    :type="type"

                    :hasBack="true"
                    :hasOwnCategory="true"
                    :hasUpload="true"

                    @back="close"
                    @selectOwn="selectOwnCategory"
                    @select="selectCategory"
                    @uploadOwn="uploadOwn"
                ></add-image-collections>
            </transition>

            <transition name="cz3__fade">
                <add-image-collections
                    v-if="mode === 'categoryOwn'"

                    :images="isStickers ? ownStickers : ownImages"

                    :caption="isStickers ? $t('ART_MY_STICKERS') : $t('ART_MY_IMAGES')"
                    :prompt="isStickers ? $t('ART_STICKER_SELECT_IMAGE') : $t('ART_SELECT_IMAGE')"
                    :type="type"

                    :hasBack="true"
                    :hasSelect="true"
                    :ownCategory="true"

                    @back="backToCategories"
                    @select="selectOwnImage"
                    @uploadOwn="uploadOwn"
                ></add-image-collections>
            </transition>

            <transition name="cz3__fade">
                <add-image-collections
                    v-if="mode === 'category'"

                    :images="selectedCategory.images"

                    :caption="selectedCategory.title"
                    :prompt="$t(isStickers ? 'ART_STICKER_SELECT_IMAGE' : 'ART_SELECT_IMAGE')"
                    :type="type"

                    :isCategory="true"
                    :hasBack="true"
                    :hasSelect="true"

                    @back="backToCategories"
                    @select="selectImage"
                ></add-image-collections>
            </transition>

            <transition name="cz3__fade">
                <upload-image
                    v-if="mode === 'uploadOwn'"
                    :type="type"
                    @back="backToCategories"
                    @uploaded="uploadedImage"
                >
                </upload-image>
            </transition>

            <transition name="cz3__fade">
                <edit-image
                    v-if="mode === 'editImage'"
                    :type="type"
                    :start-image="userImage"
                    @back="backToCategories"
                    @select="addImage"
                >
                </edit-image>
            </transition>
        </div>
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__add-image {
            @include absolute-overlay();

            background: rgba(24, 24, 24, 0.9);

            z-index: 2;
        }

        .cz3__add-image__body {
            position: absolute;

            width: 336px;
            max-width: calc(100% - 32px);
            height: 618px;
            max-height: calc(100% - 32px);

            left: 50%;
            top: 50%;

            transform: translate(-50%, -50%);

            background: $color-primary-white;
            border-radius: 32px;

            @include respond-above(sm) {
                width: 486px;
                height: 694px;

                max-width: calc(100% - 32px);
                max-height: calc(100% - 32px);
            }
        }
    }
</style>

<script>
    import axios from 'axios';

    import allStores from '../stores/all';

    import AddImageCollections from './AddImageCollections.vue';
    import UploadImage from './UploadImage.vue';
    import EditImage from './EditImage.vue';

    export default {
        mixins: [allStores],

        components: {
            AddImageCollections,
            UploadImage,
            EditImage,
        },

        props: {
            type: {
                type: String,
                default: 'images',
            },
            usePlacement: {
                type: String,
                default: null,
            },
            useController: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                mode: 'enter',
                selectedCategory: null,
                userImage: null,
            };
        },

        computed: {
            viewController() {
                return this.customizerStore.viewController;
            },

            isStickers() {
                return this.type === 'stickers';
            },

            categories() {
                return this.isStickers ? this.customizerStore.stickerGallery : this.customizerStore.imageGallery;
            },

            ownImages() {
                return this.customizerStore.ownImages.map((i) => ({
                    image: i.imageUrl,
                    thumbnail: `${i.imageUrl}?rwid=200&fmt=webp&bgc=ffffff`,
                    details: i,
                }));
            },

            ownStickers() {
                return this.customizerStore.ownStickers.map((i) => ({
                    image: i.imageUrl,
                    thumbnail: `${i.imageUrl}?rwid=200&fmt=webp&bgc=ffffff`,
                    details: i,
                }));
            },
        },

        methods: {
            close(image) {
                this.$emit('close', image);
            },

            uploadOwn() {
                this.mode = 'uploadOwn';
            },

            selectOwnCategory() {
                const list = this.isStickers ? this.ownStickers : this.ownImages;

                if (list.length === 0) {
                    this.mode = 'uploadOwn';
                } else {
                    this.mode = 'categoryOwn';
                }
            },

            selectCategory(c) {
                this.selectedCategory = c;
                this.mode = 'category';
            },

            backToCategories() {
                this.mode = 'enter';
            },

            selectImage(stock) {
                let endpoint = '//api.images.drivecommerce.com/api/v1/';

                if (this.viewController.blueprint.imageRootUrl) {
                    endpoint = this.viewController.blueprint.imageRootUrl.replace('http://', '//').replace('https://', '//');
                }

                endpoint = window.location.protocol + endpoint;

                endpoint += endpoint.substr(-1) === '/' ? 'upload' : '/upload';

                // Prefer direct.
                endpoint = endpoint.replace('//fx.images', '//api.images');

                const formData = new FormData();

                let image = stock.image;
                if (image.startsWith('//')) {
                    image = `https:${image}`;
                }

                formData.append('copy-from', image);
                formData.append('allow-reuse', 'true');

                axios({
                    method: 'post',
                    url: endpoint,
                    data: formData,
                    config: {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    },
                }).then((response) => {
                    const { data } = response;

                    // Mark as a stock image so we'd bypass filters if the image is reused.
                    data.sourceCode = stock.code;
                    data.isStock = true;
                    data.noUnderprint = stock.noUnderprint;

                    this.useImage(data).then(() => {
                        if (this.type === 'stickers') {
                            this.customizerStore.addOwnSticker(data);

                            this.close(data);
                        } else {
                            this.customizerStore.addOwnImage(data);

                            this.close();
                        }
                    });
                });
            },

            useImage(image) {
                return new Promise((resolve) => {
                    const continueAdd = (useXRay) => {
                        if (this.usePlacement && this.useController) {
                            this.customizerStore.addImage({
                                image,
                                type: this.type,
                                usePlacement: this.usePlacement,
                                useController: this.useController,
                                useXRay,
                            });
                        } else {
                            this.customizerStore.addImage({
                                image,
                                type: this.type,
                                useXRay,
                            });
                        }

                        resolve();
                    };

                    let canXRay = true;

                    // If not stickers, figure out xray preference.
                    if (this.type === 'stickers') {
                        canXRay = false;
                    }

                    // Can't apply xray when something is already customized.
                    if (this.customizerStore.productHasCustomizations) {
                        canXRay = false;
                    }

                    // How many surfaces do we have?
                    if (this.customizerStore.countSurfaces() < 2) {
                        canXRay = false;
                    }

                    if (this.customizerStore.viewController) {
                        if (this.customizerStore.viewController.blueprint.custom['ps-xray-enabled'] === 'false') {
                            canXRay = false;
                        }
                    }

                    if (canXRay) {
                        // Count elements.
                        const elements = this.customizerStore.xrayNames();

                        let currentName = this.$t('XRAY_CONFIRM_ITEM');
                        if (this.customizerStore.currentController) {
                            if (this.customizerStore.currentController.blueprint.custom['ps-xray-name']) {
                                currentName = this.customizerStore.currentController.blueprint.custom['ps-xray-name'];
                            }
                        }

                        // Ask first?
                        this.appStore.confirmPopup({
                            title: this.$t('XRAY_CONFIRM_TITLE', {
                                first: elements.slice(0, elements.length - 1).join(', '),
                                last: elements[elements.length - 1],
                            }),
                            message: this.$t('XRAY_CONFIRM_BODY'),
                            confirmButton: this.$t('XRAY_CONFIRM_YES'),
                            cancelButton: this.$t('XRAY_CONFIRM_NO', { type: currentName }),
                            confirmAction: () => {
                                this.appStore.confirmPopup();

                                continueAdd(true);
                            },
                            cancelAction: () => {
                                this.appStore.confirmPopup();

                                continueAdd();
                            },
                        });
                    } else {
                        continueAdd();
                    }
                });
            },

            uploadedImage(image) {
                this.userImage = image;

                this.mode = 'editImage';
            },

            selectOwnImage(image) {
                if (image.isStock) {
                    // Add directly.
                    this.useImage(image).then(() => {
                        if (this.type === 'stickers') {
                            this.close(image);
                        } else {
                            this.close();
                        }
                    });
                } else if (this.type === 'stickers') {
                    // Add directly.
                    this.useImage(image).then(() => {
                        this.close(image);
                    });
                } else {
                    // Continue to edit.
                    this.userImage = image;

                    this.mode = 'editImage';
                }
            },

            addImage(image) {
                if (this.isStickers) {
                    this.customizerStore.addOwnSticker(image);
                }

                this.useImage(image).then(() => {
                    if (this.type === 'stickers') {
                        this.close(image);
                    } else {
                        this.close();
                    }
                });
            },
        },
    };
</script>
