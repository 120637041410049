<template>
    <div class="cz3__ai-upload" ref="upload">
        <p>
            <span v-if="preview == null">{{$t('AI_PHOTO_INFO_ADD')}}</span> {{$t('AI_PHOTO_INFO')}}
        </p>

        <div class="cz3__ai-upload__preview-container">
            <div class="cz3__ai-upload__preview">
                <transition name="cz3__fade-slow">
                    <span v-if="canShowTooltip"
                        class="cz3__button-tooltip cz3__button-tooltip--left-narrow">
                        <span class="cz3__button-tooltip__body">
                            {{$t('AI_TOOLTIP_UPLOAD')}}
                        </span>
                    </span>
                </transition>

                <video ref="uploadVideo"
                    width="140"
                    height="140"
                    muted
                    playsinline
                    preload="metadata"
                    poster="../assets/plus-image@2x.png"
                >
                    <source src="https://cdn.drrv.co/popsockets/customizer-cyo3-assets/ai-photo-upload.mp4" type="video/mp4" />
                </video>

                <div class="cz3__ai-upload__image"
                    :style="{
                        backgroundImage: `url(&quot;${preview}&quot;)`
                    }"
                ></div>

                <div ref="uploadArea" class="cz3__ai-upload__body cz3__uppy">
                </div>
            </div>

            <div v-if="preview" class="cz3__ai-upload__edit">
                <button
                    class="cz3__outline-button cz3__outline-button--responsive"
                    @click.prevent="remove"
                >
                    <!-- eslint-disable -->
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="7.5" cy="7.5" r="7.5" transform="matrix(1 0 0 -1 0 15)" fill="#DF1212"/>
                    <line x1="0.75" y1="-0.75" x2="7.58333" y2="-0.75" transform="matrix(-1 8.74228e-08 8.74228e-08 1 11.6667 8.25)" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                    <!-- eslint-enable -->

                    {{$t('AI_IMAGE_REMOVE')}}
                </button>

                <button
                    class="cz3__outline-button cz3__outline-button--responsive"
                    @click.prevent="change"
                >
                    <!-- eslint-disable -->
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 1744">
                    <circle id="Ellipse 129" cx="7.5" cy="7.5" r="7.5" transform="matrix(1 0 0 -1 0 15)" fill="black"/>
                    <g id="Group 1742">
                    <line id="Line 127" x1="0.75" y1="-0.75" x2="7.58333" y2="-0.75" transform="matrix(-4.37113e-08 -1 -1 4.37113e-08 6.75009 11.6667)" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    <line id="Line 128" x1="0.75" y1="-0.75" x2="7.58333" y2="-0.75" transform="matrix(-1 8.74228e-08 8.74228e-08 1 11.6668 8)" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    </g>
                    </g>
                    </svg>
                    <!-- eslint-enable -->

                    {{$t('AI_IMAGE_CHANGE')}}
                </button>
            </div>
        </div>
    </div>

    <div class="cz3_ai-upload__informer" ref="uploader">
    </div>
</template>

<style lang="scss">
    @use "sass:math";

    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__ai-upload {
            overflow: hidden;

            p {
                margin: 14px 0;

                font-size: 14px;
            }
        }

        .cz3__ai-upload__preview {
            position: relative;

            margin: 0 auto;

            width: 140px;
            height: 140px;

            video {
                position: absolute;

                inset: 0;

                object-fit: contain;

                border-radius: 12px;

                overflow: hidden;
            }
        }

        .cz3__ai-upload__preview-container {
            position: relative;
        }

        .cz3__ai-upload__edit {
            position: absolute;

            right: 0;
            top: 0;

            display: flex;
            flex-direction: column;

            gap: 12px;

            button {
                font-size: 14px;

                display: flex;
                align-items: center;

                svg {
                    margin-right: 5px;
                }
            }
        }

        .cz3__ai-upload__image {
            position: absolute;

            inset: 0;

            border-radius: 15px;

            overflow: hidden;

            background-size: cover;
            background-repeat: no-repeat;
            background-color: transparent;
        }

        .cz3__ai-upload__body {
            position: relative;

            margin-top: 19px;

            width: 140px;
            height: 140px;

            &.cz3__uppy {
                .uppy-Dashboard-browse {
                    top: 0;

                    width: 140px;
                    height: 140px;

                    background-color: transparent;
                }

                .uppy-Dashboard-inner {
                    background: transparent;
                }

                .uppy-Dashboard-AddFiles {
                    height: 100%;

                    padding-top: 0;
                    margin: 0;

                    text-indent: -9999px;
                }

                .uppy-Dashboard-browse {
                    top: 0;

                    border-radius: 15px;
                    border-width: 1px;

                    width: 140px;
                    height: 140px;

                    background-image: none; // url(../assets/add-plus.svg);
                    background-size: 22px 22px;

                    &:hover,
                    &:active,
                    &:focus {
                        border-bottom: none;
                    }
                }

                .uppy-StatusBar-content {
                    width: 100%;

                    flex: 0 0 auto;

                    justify-content: center;
                }

                .uppy-StatusBar-statusPrimary {
                    visibility: collapse;

                    font-size: 0;

                    svg {
                        visibility: visible;
                    }
                }
            }
        }

        .cz3_ai-upload__informer {
            position: relative;

            height: 1px;
        }
    }
</style>

<script>
    import Uppy from '@uppy/core';
    import Dashboard from '@uppy/dashboard';
    import XhrUpload from '@uppy/xhr-upload';
    import Informer from '@uppy/informer';

    import tooltips from './tooltips';

    import allStores from '../stores/all';

    export default {
        mixins: [allStores, tooltips],

        emits: ['uploaded'],

        props: {
            playVideo: Boolean,
            fromImage: Object,
        },

        data() {
            let useImage = null;

            if (this.fromImage) {
                useImage = this.fromImage.jpgPreviewImageUrl;
            }

            return {
                uploadError: null,

                canShowTooltip: false,

                preview: useImage,
            };
        },

        computed: {
            tooltipUploadVisible() {
                return this.tooltipVisible('AI_UPLOAD');
            },

            tooltipUploadRequested() {
                return this.tooltipRequestedFirst('AI_UPLOAD');
            },

            viewController() {
                return this.customizerStore.viewController;
            },
        },

        watch: {
            uploadError() {
                if (this.uploadError && this.uppy) {
                    this.uppy.info({
                        message: this.uploadError,
                    }, 'custom-error', 5000);
                }
            },

            playVideo() {
                if (this.playVideo && !this.customizerStore.playedAiImageVideo) {
                    this.customizerStore.playedAiImageVideo = true;
                    this.$refs.uploadVideo.play();
                }
            },
        },

        mounted() {
            this.showUppy();

            setTimeout(() => {
                this.canShowTooltip = true;

                setTimeout(() => {
                    this.canShowTooltip = false;
                }, 5000);
            }, 1000);
        },

        beforeUnmount() {
            if (this.uppy) {
                this.uppy.close();

                this.uppy = null;
            }
        },

        methods: {
            showUppy() {
                if (this.uppy) {
                    this.uppy.close();

                    this.uppy = null;
                }

                if (!this.$refs.uploadArea) {
                    return;
                }

                this.uppy = new Uppy({
                    autoProceed: true,
                    allowMultipleUploads: false,
                    restrictions: {
                        maxNumberOfFiles: 1,
                        maxFileSize: 10 * 1024 * 1024,
                        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif', '.heic', '.tiff'],
                    },
                    locale: {
                        strings: {
                            dropHint: this.$t('UPLOAD_DROPHINT'),

                            myDevice: this.$t('UPLOAD_MYDEVICE'),
                            pluginNameInstagram: this.$t('UPLOAD_INSTAGRAM'),

                            youCanOnlyUploadFileTypes: this.$t('FILE_TYPE_ERROR'),

                            browseFiles: this.$t('UPLOAD_BROWSE'),
                            dropPasteImportFiles: '%{browseFiles}',

                            cancel: this.$t('UPLOAD_CANCEL'),

                            authenticateWith: this.$t('UPLOAD_AUTHENTICATEWITH'),
                            authenticateWithTitle: this.$t('UPLOAD_AUTHENTICATE'),

                            loading: this.$t('UPLOAD_LOADING'),
                            logOut: this.$t('UPLOAD_LOGOUT'),

                            uploading: this.$t('UPLOAD_UPLOADING'),
                            complete: this.$t('UPLOAD_COMPLETE'),
                            done: this.$t('UPLOAD_DONE'),

                            selectX: {
                                0: this.$t('UPLOAD_0_FILES'),
                                1: this.$t('UPLOAD_1_FILES'),
                                2: this.$t('UPLOAD_2_FILES'),
                            },
                        },
                    },
                });

                this.uppy.use(Dashboard, {
                    target: this.$refs.uploadArea,
                    inline: true,
                    replaceTargetContent: true,
                    proudlyDisplayPoweredByUppy: false,
                    showSelectedFiles: false,
                    disableInformer: true,
                    hideCancelButton: true,
                    hidePauseResumeButton: true,
                    hideRetryButton: true,
                    note: this.$t('UPLOAD_NOTE'),
                    width: 81,
                    height: 81,
                });

                this.uppy.use(Informer, {
                    target: this.$refs.uploader,
                });

                let endpoint = '//api.images.drivecommerce.com/api/v1/';

                if (this.viewController.blueprint.imageRootUrl) {
                    endpoint = this.viewController.blueprint.imageRootUrl.replace('http://', '//').replace('https://', '//');
                }

                endpoint = window.location.protocol + endpoint;

                endpoint += endpoint.substr(-1) === '/' ? 'upload' : '/upload';

                // Prefer direct.
                endpoint = endpoint.replace('//fx.images', '//api.images');

                const processUploadError = (responseText) => {
                    const response = JSON.parse(responseText);

                    if (response.details) {
                        switch (response.details.error) {
                        case 'minimum-dimensions': {
                            const error = this.$t('IMAGE_SIZE_ERROR')
                                .replace('{uploaded}', response.details.uploaded)
                                .replace('{required}', response.details.required);

                            this.uploadError = error;

                            return new Error(error);
                        }
                        case 'maximum-dimensions': {
                            const error = this.$t('IMAGE_MAX_SIZE_ERROR')
                                .replace('{uploaded}', response.details.uploaded)
                                .replace('{required}', response.details.required);

                            this.uploadError = error;

                            return new Error(error);
                        }
                        default:
                            break;
                        }
                    }

                    if (response.modelState) {
                        if (response.modelState[''] && response.modelState[''].length > 0) {
                            // eslint-disable-next-line
                            this.uploadError = response.modelState[''][0];

                            return new Error(response.modelState[''][0]);
                        }
                    }

                    if (response.message) {
                        return new Error(response.message);
                    }

                    return new Error('The request is invalid');
                };

                this.uppy.use(XhrUpload, {
                    endpoint,
                    formData: true,
                    fieldName: 'file',
                    metaFields: ['min-width', 'min-height', 'jpeg-preview-size', 'jpeg-preview-background', 'fit-width', 'fit-height', 'fit'],
                    getResponseError: processUploadError,
                });

                this.uppy.on('file-added', (file) => {
                    this.uploadError = null;

                    this.uppy.setFileMeta(file.id, {
                        'fit-width': 1024,
                        'fit-height': 1024,
                        fit: 'cover-crop',
                        'min-width': 512,
                        'min-height': 512,
                        'jpeg-preview-size': 1000,
                        'jpeg-preview-background': '255,255,255,255',
                    });
                });

                this.uppy.on('upload-error', () => {
                    this.uppy.reset();
                });

                this.uppy.on('upload-success', (file, body) => {
                    if (file && file.source) {
                        // track own upload
                        this.customizerStore.trackAnalyticsEvent({
                            action: 'cyo_deviceupload',
                        });
                    }

                    this.preview = body.body.jpgPreviewImageUrl;

                    this.$emit('uploaded', {
                        ...{ title: file.name },
                        ...body.body,
                    });

                    this.uppy.reset();
                });

                this.uppy.on('restriction-failed', (file, error) => {
                    if (file && file.size > 10 * 1024 * 1024) {
                        this.uploadError = this.$t('FILE_LIMIT_SIZE');
                    } else {
                        this.uploadError = error.message;
                    }
                });

                // HACK: Hate it, but can't find how to filter messages otherwise.
                const uppyInfo = this.uppy.info;

                this.uppy.info = function uppyInfoReplaced(message, type, delay) {
                    if (type === 'custom-error') {
                        uppyInfo.call(this, message, type, delay);
                    }
                };
            },

            remove() {
                this.preview = null;

                this.$emit('uploaded', null);
            },

            change() {
                this.$refs.upload.querySelector('button.uppy-Dashboard-browse').click();
            },
        },
    };
</script>
