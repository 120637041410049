<template>
    <div class="cz3__options cz3__options-bundle cz3__renderer-exclude">
        <transition name="cz3__fade">
            <looks-info v-if="activeBundle?.looks"></looks-info>
        </transition>

        <transition name="cz3__fade">
            <with-looks v-if="activeBundle?.withLooks"
                @use-looks="setUseLooks"
            ></with-looks>
        </transition>

        <div class="cz3__mb--standard cz3__type--header cz3__type--center">
            {{selectCaption}}
        </div>

        <bundle-slider
            :compatible-options="compatibleOptions"
            :last-loaded-product="lastLoadedProduct"
            @select-bundle="selectStandardBundle"
        ></bundle-slider>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__options-bundle {
            height: auto;
        }

        .cz3__options-bundle__slider {
            position: relative;

            margin-bottom: $margin-options-large;

            left: 50%;
            width: 100vw;

            transform: translate(-50%, 0);

            &.swiper {
                overflow: visible;
            }
        }

        .swiper-slide.cz3__bundle-option {
            position: relative;

            width: 96px;
            height: 96px;

            padding: 0;

            @include respond-above(sm) {
                width: 101px;
                height: 101px;
            }
        }

        .cz3__bundle-option__image {
            display: block;

            position: relative;

            height: 0;
            padding-top: 100%;

            background: #fff;
        }

        .cz3__bundle-option__image-background {
            position: absolute;

            left: 8px;
            top: 8px;
            right: 8px;
            bottom: 8px;

            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        .cz3__bundle-option--selected {
            .cz3__bundle-option__image {
                border-color: $color-gray-dark;
            }
        }

        .cz3__bundle-option {
            border: none;
            background: transparent;

            color: $color-primary-black;
        }

        .cz3__bundle-option__image {
            position: relative;

            height: 0;
            padding-top: 100%;

            border: 1px solid $color-gray-mid;
            border-radius: 12px;
        }

        .cz3__bundle-details {
            position: absolute;

            width: 100%;
            left: 0;
            top: calc(100% + 8px);

            span {
                display: block;
            }
        }

        .cz3__bundle-name {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
        }

        .cz3__bundle-upcharge {
            margin-top: 4px;

            color: $color-gray-dark;

            font-family: $font-main;
            font-size: 10px;
            line-height: 10px;
        }
    }
</style>

<script>
    import allStores from '../stores/all';

    import BundleSlider from './BundleSlider.vue';
    import LooksInfo from './LooksInfo.vue';
    import WithLooks from './WithLooks.vue';

    export default {
        mixins: [allStores],

        components: {
            BundleSlider,
            LooksInfo,
            WithLooks,
        },

        props: {
            cases: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },

            viewController() {
                return this.customizerStore.viewController;
            },

            prebuiltOptions() {
                return this.customizerStore.prebuiltOptions;
            },

            selectCaption() {
                if (this.selectableOptions.length > 0) {
                    return this.selectableOptions[0].selectCaption;
                }

                return '';
            },

            lastLoadedProduct() {
                return this.customizerStore.lastLoadedProduct;
            },

            firstLoadedProduct() {
                return this.customizerStore.firstLoadedProduct;
            },

            preferMagSafe() {
                return this.customizerStore.preferMagSafe;
            },

            selectableOptions() {
                return this.customizerStore.selectableBundles;
            },

            selectedBundle() {
                const selected = this.selectableOptions.find((bundle) => bundle.product === this.lastLoadedProduct);

                if (selected) {
                    return selected;
                }

                return this.selectableOptions.find((bundle) => bundle.withLooks === this.lastLoadedProduct);
            },

            firstBundle() {
                return this.selectableOptions.find((bundle) => bundle.product === this.firstLoadedProduct);
            },

            activeBundle() {
                return this.customizerStore.selectedBundle;
            },

            compatibleOptions() {
                if (this.firstBundle == null) {
                    return this.selectableOptions;
                }

                if (this.firstBundle.type == null) {
                    return this.selectableOptions;
                }

                return this.selectableOptions.filter((bundle) => bundle.types?.includes(this.firstBundle.type));
            },

            useLooks() {
                return this.customizerStore.useLooks;
            },
        },

        beforeUnmount() {
            if (this.selectedBundle) {
                const bundle = this.selectedBundle;

                this.customizerStore.trackAnalyticsEvent({
                    action: 'cyo_base',
                    label: `base_${this.selectedBundle?.name}`,
                });

                // determine what product(s) are in the bundle
                let selectedProduct = '';

                if (bundle.product) {
                    if (bundle.product.includes('Case')) {
                        selectedProduct += 'Case ';
                    }

                    if (bundle.product.includes('Wallet')) {
                        selectedProduct += 'Wallet ';
                    }

                    if (bundle.product.includes('Grip') || bundle.product.includes('PopTop')) {
                        selectedProduct += 'Grip ';
                    }
                }

                if (selectedProduct) {
                    this.customizerStore.trackAnalyticsEvent({
                        action: 'cyo_product_type',
                        label: `product_${selectedProduct.trim()}`,
                    });
                }
            }

            this.customizerStore.setCanCheckStashedLayers(true);
        },

        methods: {
            selectBundle(bundle) {
                this.customizerStore.selectBundle({
                    bundle,
                });

                if (bundle.popupTitle && bundle.popupBody) {
                    this.appStore.confirmPopup({
                        title: bundle.popupTitle,
                        message: bundle.popupBody,
                        confirmButton: this.$t('GOT_IT'),
                        confirmAction: () => {
                            this.appStore.confirmPopup();
                        },
                    });
                } else if (bundle.magSafe && bundle.magSafeLink) {
                    this.appStore.confirmPopup({
                        message: this.$t('CASE_INFO_BODY'),
                        confirmButton: this.$t('CASE_INFO_CONFIRM'),
                        cancelButton: this.$t('CASE_INFO_CANCEL'),
                        closeAction: () => {
                            this.appStore.confirmPopup();
                        },
                        confirmAction: () => {
                            this.appStore.confirmPopup();
                        },
                        cancelAction: () => {
                            this.appStore.confirmPopup();

                            window.location.href = bundle.magSafeLink;
                        },
                    });
                }
            },

            selectStandardBundle(bundle) {
                this.customizerStore.setUseLooks(false);

                this.selectBundle(bundle);
            },

            setUseLooks(v) {
                const use = v === 'swappable';

                if (this.useLooks !== use) {
                    if (this.activeBundle?.withLooks) {
                        this.customizerStore.setUseLooks(use);

                        this.selectBundle(this.activeBundle);
                    } else {
                        this.customizerStore.setUseLooks(false);

                        this.selectBundle(this.activeBundle);
                    }
                }
            },
        },
    };
</script>
