<template>
    <div
        ref="renderer"
        :class="[
            'cz3__renderer',
            `cz3__renderer--${currentStep}`,
        ]">

        <camera-view
            :controller="viewController"
            :view="selectedView"
            :view-options="overrideViewOptions"
            @renderTransformUpdated="renderTransformUpdated"
            @click.prevent="clickView"
        />

        <div class="cz3__renderer__shade">
        </div>

        <transition name="cz3__fade">
            <edit-handles v-if="selectedLayer"
                :layer="selectedLayer"
                :layer-width="layerWidth"
                :layer-height="layerHeight"
                :handles="layerHandles"
                :lines="layerLines"
                :fit-lines="fitLayerLines"
                :outside="layerOutside"
            ></edit-handles>
        </transition>

        <div class="cz3__renderer-overlays">
            <transition-group name="cz3__fade">
                <button v-for="j in viewJoints"
                    :key="j.id"
                    :id="`joint-${j.id}`"
                    :data-name="j.viewName"
                    class="cz3__icon-button cz3__joint-button"
                    :style="{
                        left: `${j.screen.x}px`,
                        top: `${j.screen.y}px`,
                        opacity: j.merged || !j.active || j.hidden ? 0 : 1,
                        'pointer-events': j.merged || !j.active || j.hidden ? 'none' : 'auto',
                    }"
                    @click.prevent="jointSelected(j)"
                >
                    <span v-if="isEditIcon(j)" class="cz3__joint-button__icon--edited">
                        <icon-edit/>
                    </span>

                    <span v-else class="cz3__joint-button__icon--select">
                        <icon-select/>
                    </span>
                </button>
            </transition-group>

            <transition-group name="cz3__fade">
                <button v-for="j in mergedViewJoints"
                    :key="j.id"
                    :id="`merged-${j.id}`"
                    :data-name="`merged-${j.viewName}`"
                    class="cz3__icon-button cz3__joint-button cz3__joint-button--merged"
                    :style="{
                        left: `${j.mergedScreen.x}px`,
                        top: `${j.mergedScreen.y}px`,
                        opacity: j.merged ? 1 : 0,
                        'pointer-events': j.merged ? 'auto' : 'none',
                    }"
                    @click.prevent="jointSelected(j)"
                >
                    <!-- Merged joints are always shown using the pulse -->
                    <span class="cz3__joint-button__icon--select">
                        <icon-select/>
                    </span>
                </button>
            </transition-group>

            <transition-group name="cz3__fade">
                <span v-for="j in viewJoints"
                    :key="j.id"
                    class="cz3__joint-placement"
                    :style="{
                        left: `${j.screen.x}px`,
                        top: `${j.screen.y}px`,
                        opacity: j.merged || !j.active || j.hidden ? 0 : 1,
                    }"
                >
                    <transition name="cz3__fade-slow">
                        <span v-if="j.type === 'options' && tooltipRequested('JOINT-' + j.id) && delayedTooltipsVisible && tooltipVisible('JOINT-' + j.id)"
                            class="cz3__button-tooltip cz3__button-tooltip--below-left">
                            <span class="cz3__button-tooltip__body">
                                {{$t('TOOLTIP_EDIT_COLOR')}}
                            </span>
                        </span>
                    </transition>

                    <transition name="cz3__fade-slow">
                        <span
                            v-if="j.type === 'image'
                                && j.edited
                                && tooltipRequested('JOINT-' + j.id)
                                && delayedTooltipsVisible
                                && tooltipVisible('JOINT-' + j.id)"
                            class="cz3__button-tooltip cz3__button-tooltip--above-left">
                            <span class="cz3__button-tooltip__body">
                                {{$t('TOOLTIP_EDIT_DESIGN')}}
                            </span>
                        </span>
                    </transition>
                </span>
            </transition-group>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__renderer {
            // Renderer is a dynamically sized thing depending on the current application step.
            position: absolute;

            left: 50%;
            top: var(--exclude-top);
            transform: translate(-50%, 0);

            // Initial value.
            --exclude-height: 0px;

            width: 100%;
            max-width: 1280px;
            height: calc(100% - var(--exclude-height));

            transition: height 0.25s ease-out;
        }

        .cz3__renderer-overlays {
            @include absolute-overlay();

            pointer-events: none;
        }

        .cz3__handles {
            @include absolute-overlay();

            pointer-events: none;
        }

        .cz3__joint-button {
            position: absolute;

            width: 40px;
            height: 40px;

            transform: translate(-50%, -50%);

            pointer-events: auto;

            transition: opacity 0.25s linear;

            svg {
                width: 100%;
                height: 100%;
            }

            @include respond-above(sm) {
                width: 52px;
                height: 52px;
            }

            .cz3__joint-button__icon--select {
                svg {
                    display: none;
                }

                &:after {
                    content: '';

                    position: absolute;

                    left: 50%;
                    top: 50%;

                    transform: translate(-50%, -50%);

                    display: block;

                    width: 40px;
                    height: 40px;

                    border-radius: 40px;
                    border: 1px solid #d4d4d4;

                    @include respond-above(sm) {
                        width: 52px;
                        height: 52px;
                    }

                    background: #fff;

                    box-shadow: 0 0 0 0 rgba(#fff, 0.8);
                    // animation: cz3__pulse 2.5s infinite, cz3__color-pulse 15s infinite;
                    animation: cz3__pulse 2.5s infinite;
                }

                @keyframes cz3__pulse {
                    0% {
                        transform: translate(-50%, -50%) scale(0.875);
                    }

                    40% {
                        transform: translate(-50%, -50%) scale(1);

                        box-shadow: 0 0 0 20px rgba(#fff, 0);
                    }

                    100% {
                        transform: translate(-50%, -50%) scale(0.875);

                        box-shadow: 0 0 0 0 rgba(#fff, 0);
                    }
                }

                @keyframes cz3__color-pulse {
                    0% {
                        background-color: #ee79f7;
                    }

                    33% {
                        background-color: #9c73ef;
                    }

                    66% {
                        background-color: #49a5f8;
                    }

                    100% {
                        background-color: #ee79f7;
                    }
                }
            }
        }

        .cz3__joint-placement {
            position: absolute;

            width: 40px;
            height: 40px;

            transform: translate(-50%, -50%);

            pointer-events: none;

            transition: opacity 0.25s linear;

            @include respond-above(sm) {
                width: 52px;
                height: 52px;
            }
        }

        .cz3__renderer__shade {
            position: absolute;

            pointer-events: none;

            left: 0;
            right: 0;

            bottom: 0;

            height: 50px;

            background: linear-gradient(to bottom, rgba(252, 252, 252, 0) 0%, rgba(252, 252, 252, 1) 100%);
        }
    }
</style>

<script>
    import interact from 'interactjs';

    import allStores from '../stores/all';

    import tooltips from './tooltips';

    import CameraView from './Three/CameraView.vue';
    import EditHandles from './EditHandles.vue';

    import * as ThreeUtilities from './Three/threeutilities';

    import * as constants from '../constants';

    import IconEdit from '../assets/edit.svg';
    import IconSelect from '../assets/select.svg';

    export default {
        mixins: [allStores, tooltips],

        components: {
            CameraView,
            EditHandles,
            IconEdit,
            IconSelect,
        },

        data() {
            return {
                layerHandles: [],
                layerLines: [],
                fitLayerLines: [],
                layerOutside: false,
                layerWidth: null,
                layerHeight: null,

                lastSelectedOverviewSlide: 1,
            };
        },

        computed: {
            currentStep() {
                return this.customizerStore.currentStep;
            },

            viewController() {
                return this.customizerStore.viewController;
            },

            selectedView() {
                return this.customizerStore.selectedView;
            },

            selectedLayer() {
                return this.customizerStore.selectedLayer;
            },

            joints() {
                return this.customizerStore.joints;
            },

            selectableJoints() {
                return this.customizerStore.selectableJoints;
            },

            mergedJoints() {
                return this.customizerStore.mergedJoints;
            },

            activeJoints() {
                return this.customizerStore.activeJoints;
            },

            editViewJoint() {
                return this.customizerStore.editViewJoint;
            },

            /**
             * Merge together selectable joints and the last edited joint.
             */
            viewJoints() {
                const current = this.selectedJoint || this.editViewJoint;

                const joints = this.selectableJoints.map((x) => ({
                    ...x,
                    hidden: current !== null && current.id === x.id && !current.visible && !current.edited,
                }));

                // Hide currently selected joint.
                // This doesn't include an implicitly selected joints (such as ones on the Overview view).
                // if (this.selectedJoint) {
                //     joints = joints.filter((x) => x.id !== this.selectedJoint.id);
                // }

                return joints;
            },

            /**
             * Merge together selectable joints and the last edited joint.
             */
            mergedViewJoints() {
                return this.viewJoints.filter((j) => j.mergedPreferred);
            },

            selectedJoint() {
                return this.customizerStore.selectedJoint;
            },

            overrideView() {
                return this.customizerStore.overrideView;
            },

            overrideViewOptions() {
                return this.customizerStore.overrideViewOptions;
            },

            overviewViewsCount() {
                let count = 0;

                for (;;) {
                    const expected = `${constants.VIEW_OVERVIEW} ${count + 1}`;

                    const view = this.viewController?.blueprint?.views?.find((v) => v.code === expected);

                    if (view) {
                        count += 1;
                    } else {
                        break;
                    }
                }

                return count;
            },

            small() {
                return this.appStore.small;
            },

            showAsCarousel() {
                if (this.viewController?.blueprint?.custom?.['ps-has-carousel'] === 'false') {
                    return false;
                }

                return true;
            },
        },

        watch: {
            currentStep(newValue, oldValue) {
                this.updateTooltips();
                this.startDelayedTooltipsTimer();

                if (oldValue === constants.STEP_EDIT) {
                    this.clearHighlights();
                }

                this.$nextTick(() => {
                    this.resize();

                    this.selectView();
                });
            },

            selectedJoint() {
                this.flashJointMeshes();

                this.$nextTick(() => {
                    this.selectView();
                });
            },

            editViewJoint() {
                this.flashJointMeshes();
            },

            overrideView() {
                this.$nextTick(() => {
                    this.selectView();
                });
            },

            selectedView() {
                this.$nextTick(() => {
                    this.customizerStore.setOverrideViewOptions(null);
                });
            },

            viewController() {
                this.captureController();
            },
        },

        mounted() {
            this.resize();

            this.captureController();

            this.updateSwiping(true);

            this.emitter.$on('renderSelectedLayerUpdated', this.renderSelectedLayerUpdated);

            window.addEventListener('resize', this.resize);

            this.heightTimer = setInterval(() => {
                this.updateExcludeHeight();
            }, 300);
        },

        beforeUnmount() {
            this.updateSwiping(false);

            this.emitter.$off('renderSelectedLayerUpdated', this.renderSelectedLayerUpdated);

            window.removeEventListener('resize', this.resize);

            if (this.heightTimer) {
                clearInterval(this.heightTimer);
            }
        },

        updated() {
            this.updateSwiping(true);
        },

        beforeUpdate() {
            this.updateSwiping(false);
        },

        methods: {
            // Check if the element has Vue's leave transition.
            isLeaving(el) {
                if (el && el.classList) {
                    for (let i = 0; i < el.classList.length; i += 1) {
                        if (el.classList[i].endsWith('leave-active')) {
                            return true;
                        }
                    }
                }

                return false;
            },

            updateExcludeHeight() {
                const parent = this.$el.closest('.cz3');

                if (parent) {
                    const excludes = Array.from(parent.querySelectorAll('.cz3__renderer-exclude'));

                    let excludeHeight = 0;
                    let offsetTop = 0;

                    // Margins.
                    const isLarge = window.innerWidth > 1024;

                    if (isLarge) {
                        excludeHeight += 24 + 24;
                        offsetTop = 18;
                    } else {
                        excludeHeight += 16;
                        offsetTop = 8;
                    }

                    let min = null;
                    let max = null;

                    excludes.forEach((el) => {
                        // Don't include elements that will disappear soon.
                        let leaveCheck = el;
                        while (leaveCheck) {
                            if (this.isLeaving(leaveCheck)) {
                                return;
                            }

                            leaveCheck = leaveCheck.parentNode;
                        }

                        const rect = el.getBoundingClientRect();

                        if (min == null || rect.top < min) {
                            min = rect.top;
                        }

                        if (max == null || rect.bottom > max) {
                            max = rect.bottom;
                        }
                    });

                    const parentRect = parent.getBoundingClientRect();

                    const onScreen = min != null ? parentRect.bottom - min : 0;

                    excludeHeight += onScreen;

                    this.$refs.renderer.style.setProperty('--exclude-height', `${excludeHeight}px`);
                    this.$refs.renderer.style.setProperty('--exclude-top', `${offsetTop}px`);
                }
            },

            resize() {
                this.updateExcludeHeight();
            },

            /**
             * When a new controller is selected, capture initial parameters.
             */
            captureController() {
                // Clear the state.
                this.lastSelectedOverviewSlide = 1;

                if (this.viewController) {
                    this.customizerStore.selectView(this.viewController?.blueprint?.views?.[0]);
                } else {
                    this.customizerStore.selectView(null);
                }
            },

            /**
             * Select a view that matches the current step.
             */
            selectView() {
                if (this.overrideView) {
                    this.customizerStore.selectView(this.overrideView);

                    return;
                }

                // If there is a joint selected currently, check if there is a view that needs to be selected.
                if (this.currentStep === constants.STEP_EDIT_OPTIONS || this.currentStep === constants.STEP_EDIT_IMAGE) {
                    if (this.selectedJoint?.view) {
                        let jointView = null;

                        if (this.selectedJoint?.viewSuffix) {
                            if (jointView == null && this.small) {
                                const smallViewCode = `${this.selectedJoint.view} ${this.selectedJoint?.viewSuffix} Small`;

                                jointView = this.selectedJoint.controller.blueprint.views.find((v) => v.code === smallViewCode);
                            }

                            if (jointView == null) {
                                const viewCode = `${this.selectedJoint.view} ${this.selectedJoint?.viewSuffix}`;

                                jointView = this.selectedJoint.controller.blueprint.views.find((v) => v.code === viewCode);
                            }
                        }

                        if (jointView == null && this.small) {
                            const smallViewCode = `${this.selectedJoint.view} Small`;

                            jointView = this.selectedJoint.controller.blueprint.views.find((v) => v.code === smallViewCode);
                        }

                        if (jointView == null) {
                            const viewCode = this.selectedJoint.view;

                            jointView = this.selectedJoint.controller.blueprint.views.find((v) => v.code === viewCode);
                        }

                        if (jointView) {
                            this.customizerStore.selectView(jointView);
                        }

                        return;
                    }
                }

                // Otherwise use a static mapping between Step to View.

                let viewCode = constants.VIEW_INITIAL;

                if (this.currentStep === constants.STEP_EDIT
                    || this.currentStep === constants.STEP_EDIT_OPTIONS
                    || this.currentStep === constants.STEP_EDIT_IMAGE) {
                    viewCode = `${constants.VIEW_OVERVIEW} ${this.lastSelectedOverviewSlide}`;
                }

                let view = null;

                // Always use small view in the carousel.
                // Even on cases.

                let viewJoint = null;

                if (this.selectedJoint) {
                    viewJoint = this.selectedJoint;
                }

                let viewSuffix = viewJoint?.viewSuffix;

                if (viewSuffix == null) {
                    viewSuffix = this.lastViewSuffix;
                } else {
                    this.lastViewSuffix = viewSuffix;
                }

                if (view == null && viewSuffix) {
                    const smallViewCode = `${viewCode} ${viewSuffix} Small`;

                    view = this.viewController?.blueprint?.views?.find((v) => v.code === smallViewCode);
                }

                if (view == null) {
                    const smallViewCode = `${viewCode} Small`;

                    view = this.viewController?.blueprint?.views?.find((v) => v.code === smallViewCode);
                }

                if (view == null) {
                    view = this.viewController?.blueprint?.views?.find((v) => v.code === viewCode);
                }

                if (view) {
                    this.customizerStore.selectView(view);
                }
            },

            /**
             * Forward camera notifications.
             */
            renderTransformUpdated() {
                this.emitter.$emit('renderTransformUpdated');
            },

            /**
             * A joint was selected...
             */
            jointSelected(joint) {
                // Select a view corresponding to the joint/controller.
                if (this.showAsCarousel) {
                    let select = null;

                    if (joint.controller.blueprint.custom['ps-slide']) {
                        select = +joint.controller.blueprint.custom['ps-slide'];
                    }

                    if (select) {
                        this.lastSelectedOverviewSlide = select;

                        this.selectView();
                    }
                }

                if (joint.merged) {
                    // Is it the same joint as editView one?
                    if (this.editViewJoint && this.editViewJoint.id === joint.id) {
                        // Clear selected joints. editViewJoint would be the "preferred" one now.
                        this.customizerStore.selectJoint(null);
                    } else {
                        // Otherwise explicitly select it.
                        this.customizerStore.selectJoint(joint);
                    }
                    this.customizerStore.setCurrentStep(constants.STEP_EDIT);
                } else if (joint.type === 'options') {
                    this.customizerStore.selectJoint(joint);

                    this.customizerStore.setCurrentStep(constants.STEP_EDIT_OPTIONS);
                } else if (joint.type === 'image') {
                    // If another joint is selected...
                    //   this means we are on a view with multiple selectable joints...
                    //     for example a PopGrip with base and pop top and accordion.
                    //   select a joint, but don't drop into the edit mode.

                    if (this.isEditIcon(joint)) {
                        this.customizerStore.selectJoint(joint);

                        this.customizerStore.setCurrentStep(constants.STEP_EDIT_IMAGE);

                        this.customizerStore.trackAnalyticsEvent({
                            action: 'cyo_edit',
                        });
                    } else if (this.editViewJoint != null && this.editViewJoint.id === joint.id) {
                        this.customizerStore.selectJoint(null);
                        this.customizerStore.setCurrentStep(constants.STEP_EDIT);
                    } else {
                        this.customizerStore.selectJoint(joint);
                        this.customizerStore.setCurrentStep(constants.STEP_EDIT);
                    }
                }
            },

            /**
             * Main screen swiping.
             */
            swipeCamera(direction) {
                if (this.currentStep === constants.STEP_EDIT) {
                    if (this.showAsCarousel) {
                        let selectedView = this.lastSelectedOverviewSlide + direction;

                        if (selectedView < 1) {
                            selectedView = 1;
                        }

                        if (selectedView > this.overviewViewsCount) {
                            selectedView = this.overviewViewsCount;
                        }

                        this.lastSelectedOverviewSlide = selectedView;

                        // Clear selected joints. editViewJoint will be the default.
                        this.customizerStore.selectJoint(null);

                        this.selectView();
                    }
                }
            },

            /**
             * Update swiping interactions.
             */
            updateSwiping(mount) {
                interact(this.$refs.renderer);

                if (mount) {
                    interact(this.$refs.renderer).draggable({
                        onend: (event) => {
                            if (event.swipe) {
                                if (event.swipe.left) {
                                    this.swipeCamera(1);
                                }
                                if (event.swipe.right) {
                                    this.swipeCamera(-1);
                                }
                            }
                        },
                    });
                }
            },

            clearHighlights() {
                // this.lastHighlightJoint = null;
            },

            /**
             * Highlight editable meshes.
             */
            flashJointMeshes() {
                const highlightJoint = this.selectedJoint || this.editViewJoint;

                if (highlightJoint) {
                    if (this.lastHighlightJoint == null || this.lastHighlightJoint.id !== highlightJoint.id) {
                        this.lastHighlightJoint = highlightJoint;

                        this.emitter.$emit('clearHighlights');

                        let joint = highlightJoint;

                        // editViewJoint is actually a reference to a joint with a few extra pieces, step down to that reference if necessary.
                        if (joint.joint) {
                            joint = joint.joint;
                        }

                        // If joint was edited, don't highlight.
                        if (!joint.edited) {
                            const { meshes } = joint;

                            if (meshes) {
                                this.emitter.$emit('highlightMeshes', {
                                    controller: joint.controller,
                                    meshes: meshes.split(',').map((x) => x.trim()).filter((x) => x.length > 0),
                                    color: ThreeUtilities.toColor3('#bdff00'),
                                    delay: 300,
                                    duration: 2000,
                                    transition: 500,
                                });
                            }
                        }
                    }
                }
            },

            extendBy(center, p, by) {
                let dx = p.x - center.x;
                let dy = p.y - center.y;

                // Current distance.
                const d = Math.sqrt((dx * dx) + (dy * dy));

                // Normalize direction.
                dx /= d;
                dy /= d;

                return {
                    x: (dx * (d + by)) + center.x,
                    y: (dy * (d + by)) + center.y,
                };
            },

            shiftBy(pos, dx, dy) {
                return {
                    x: pos.x + dx,
                    y: pos.y + dy,
                };
            },

            renderSelectedLayerUpdated(selected) {
                if (selected == null) {
                    this.customizerStore.setSelectedLayer(null);
                    return;
                }

                const component = selected.controller.selectedAtPlacement(selected.layer.code);

                this.customizerStore.setSelectedLayer(selected);

                const handles = [];

                handles.push({
                    type: 'trash',
                    where: this.extendBy(selected.screen.center, selected.screen.ftl, 40),
                });

                handles.push({
                    type: 'size',
                    where: this.extendBy(selected.screen.center, selected.screen.ftr, 40),
                });

                if (component?.custom?.['ps-moved'] === 'true') {
                    handles.push({
                        type: 'reset',
                        where: this.extendBy(selected.screen.center, selected.screen.fbr, 40),
                    });

                    handles.push({
                        type: 'xray',
                        where: this.shiftBy(this.extendBy(selected.screen.center, selected.screen.fbr, 40), 0, 60),
                    });
                } else if (component.custom['ps-xray-id']) {
                    handles.push({
                        type: 'xray',
                        where: this.extendBy(selected.screen.center, selected.screen.fbr, 40),
                    });
                } else {
                    handles.push({
                        type: 'duplicate',
                        where: this.extendBy(selected.screen.center, selected.screen.fbr, 40),
                    });
                }

                handles.push({
                    type: 'rotate',
                    where: this.extendBy(selected.screen.center, selected.screen.fbl, 40),
                });

                handles.push({
                    type: 'size-top',
                    where: this.extendBy(selected.screen.center, selected.screen.fmt, 3),
                });

                handles.push({
                    type: 'size-right',
                    where: this.extendBy(selected.screen.center, selected.screen.fmr, 3),
                });

                handles.push({
                    type: 'size-left',
                    where: this.extendBy(selected.screen.center, selected.screen.fml, 3),
                });

                handles.push({
                    type: 'size-bottom',
                    where: this.extendBy(selected.screen.center, selected.screen.fmb, 3),
                });

                this.layerHandles = handles;

                const lines = [];

                lines.push({
                    type: 'trash',
                    where: this.extendBy(selected.screen.center, selected.screen.tl, 4),
                });

                lines.push({
                    type: 'size',
                    where: this.extendBy(selected.screen.center, selected.screen.tr, 4),
                });

                // Can't duplicate xray'ed components.
                // But instead we show the "un-xray" button.
                if (component.custom['ps-xray-id']) {
                    lines.push({
                        type: 'xray',
                        where: this.extendBy(selected.screen.center, selected.screen.br, 4),
                    });
                } else {
                    lines.push({
                        type: 'duplicate',
                        where: this.extendBy(selected.screen.center, selected.screen.br, 4),
                    });
                }

                lines.push({
                    type: 'rotate',
                    where: this.extendBy(selected.screen.center, selected.screen.bl, 4),
                });

                this.layerLines = lines;

                const fitLines = [];

                fitLines.push({
                    type: 'trash',
                    where: this.extendBy(selected.screen.center, selected.screen.ftl, 4),
                });

                fitLines.push({
                    type: 'size',
                    where: this.extendBy(selected.screen.center, selected.screen.ftr, 4),
                });

                fitLines.push({
                    type: 'duplicate',
                    where: this.extendBy(selected.screen.center, selected.screen.fbr, 4),
                });

                fitLines.push({
                    type: 'rotate',
                    where: this.extendBy(selected.screen.center, selected.screen.fbl, 4),
                });

                this.fitLayerLines = fitLines;

                this.layerOutside = selected.screen.outside;

                this.layerWidth = selected.corners.layerWidth;
                this.layerHeight = selected.corners.layerHeight;
            },

            clickView(e) {
                if (this.currentStep === constants.STEP_EDIT_IMAGE) {
                    this.emitter.$emit('selectLayerFromPoint', {
                        x: e.offsetX,
                        y: e.offsetY,
                    });
                }
            },

            isEditIcon(joint) {
                if (!joint.edited) {
                    return false;
                }

                if (this.selectedJoint) {
                    return this.selectedJoint.id === joint.id;
                }

                if (this.editViewJoint) {
                    if (this.editViewJoint.id === joint.id) {
                        return true;
                    }
                }

                return false;
            },
        },
    };
</script>
