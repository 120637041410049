<template>
    <div class="cz3__image-layers">
        <transition name="cz3__fade-slow">
            <span v-if="layersReversed.length === 1 && tooltipDragSingleRequested && delayedTooltipsVisible && tooltipDragSingleVisible"
                class="cz3__button-tooltip cz3__button-tooltip--above-left">
                <span class="cz3__button-tooltip__body">
                    {{$t('EDIT_TIP_SINGLE')}}
                </span>
            </span>
        </transition>

        <transition name="cz3__fade-slow">
            <span v-if="layersReversed.length > 1 && tooltipDragMultipleRequested && delayedTooltipsVisible && tooltipDragMultipleVisible"
                class="cz3__button-tooltip cz3__button-tooltip--above-left">
                <span class="cz3__button-tooltip__body">
                    {{$t('EDIT_TIP_MULTIPLE')}}
                </span>
            </span>
        </transition>

        <draggable
            class="cz3__image-layers__layers"
            :modelValue="layersReversed"
            :item-key="this.getItemKey"
            :group="{ name: 'layers', pull: 'clone', put: false }"
            :touch-start-threshold="5"
            :fallback-tolerance="5"
            :force-fallback="true"
            @update="updateOrder"
            @start="startDrag"
        >
            <template #item="{ element }">
                <button
                    class="cz3__image-layer-button"
                    :class="{
                        'cz3__image-layer-button--selected': selectedLayer?.layer?.code === element.state.placement.code,
                    }"
                    :style="getButtonStyle(element)"
                    @click.prevent="selectLayer(element)"
                >
                    <span v-if="element.type === 'image'"
                        class="cz3__image-layer-button__image"
                        :style="{
                            backgroundImage: `url(${element.thumbnail})`,
                        }"
                    >
                    </span>
                    <span v-else class="cz3__image-layer-button__text">{{element?.state?.component?.custom?.['personalization-text']}}</span>
                </button>
            </template>
        </draggable>

        <div class="cz3__image-layers__trash">
            <draggable
                class="cz3__image-layers__trash-layers"
                :list="trashed"
                :item-key="this.getItemKey"
                :force-fallback="true"
                :fallback-tolerance="5"
                :touch-start-threshold="5"
                group="layers"
                @start="startDrag"
            >
                <template #item="{ element }">
                    <button
                        class="cz3__image-layer-button"
                    >
                        <span v-if="element.type === 'image'"
                            class="cz3__image-layer-button__image"
                            :style="{
                                backgroundImage: `url(${element.thumbnail})`,
                            }"
                        >
                        </span>
                    </button>
                </template>
            </draggable>

            <button v-if="layersReversed.length > 0"
                id="delete-image"
                class="cz3__image-layer-button cz3__image-layer-button--trash"
                :aria-label="$t('LAYER_DELETE')"
                @click.prevent="deleteSelected"
            >
                <icon-delete/>
            </button>
        </div>
    </div>
</template>

<style lang="scss">
    @import "../styles/variables";

    #cz3.cz3 {
        .cz3__image-layers {
            position: absolute;

            right: 15px;
            // Bottom:
            // Navbar + padding.
            bottom: 72px + 16px;

            @include respond-above(sm) {
                bottom: 80px + 88px;
            }

            // Centered:
            // top: 50%;
            // transform: translate(0, -50%);

            display: flex;

            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;

            @include respond-above(sm) {
                right: auto;
                left: calc(50% + 280px);
            }
        }

        .cz3__image-layers__trash {
            position: relative;

            margin-top: 10px;

            width: 40px;

            @include respond-above(sm) {
                margin-top: 20px;

                width: 50px;
            }
        }

        .cz3__image-layers__layers {
            display: flex;

            flex-direction: column;

            gap: 10px;

            @include respond-above(sm) {
                gap: 20px;
            }
        }

        .cz3__image-layers__trash-layers {
            height: 40px;

            opacity: 0;

            @include respond-above(sm) {
                height: 50px;
            }
        }

        .cz3__image-layer-button--trash {
            position: absolute;

            left: 0;
            top: 0;

            svg {
                width: 30px;
                height: 30px;
            }
        }

        .cz3__image-layer-button {
            position: relative;

            display: block;

            width: 40px;
            height: 40px;

            padding: 0;

            border: 1px solid $color-gray-mid;
            background: transparent;

            border-radius: 100%;

            &:after {
                content: '';

                position: absolute;

                left: -3px;
                top: -3px;

                right: -3px;
                bottom: -3px;

                border-radius: 100%;

                border: 1px solid transparent;

                transition: border-color 0.25s ease-out;
            }

            &.cz3__image-layer-button--selected {
                &:after {
                    border: 1px solid $color-primary-black;
                }
            }

            @include respond-above(sm) {
                width: 50px;
                height: 50px;
            }

            &--trash {
                position: absolute;

                border: none;
            }
        }

        .cz3__image-layer-button__image {
            @include absolute-overlay();

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

            border-radius: 100%;
        }

        .cz3__image-layer-button__text {
            @include absolute-overlay();

            border-radius: 100%;

            font-size: 10px;

            overflow: hidden;

            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
        }
    }
</style>

<script>
    import draggable from 'vuedraggable';

    import allStores from '../stores/all';
    import tooltips from './tooltips';

    import IconDelete from '../assets/trash.svg';

    export default {
        mixins: [allStores, tooltips],

        components: {
            draggable,
            IconDelete,
        },

        data() {
            return {
                trashed: [],
            };
        },

        computed: {
            currentController() {
                return this.customizerStore.currentController;
            },

            layers() {
                return this.customizerStore.layers;
            },

            layersReversed() {
                const l = [...this.layers];

                l.reverse();

                return l;
            },

            selectedLayer() {
                return this.customizerStore.selectedLayer;
            },

            tooltipDragSingleVisible() {
                return this.tooltipVisible('tipDragSingle');
            },

            tooltipDragMultipleVisible() {
                return this.tooltipVisible('tipDragMultiple');
            },

            tooltipDragSingleRequested() {
                return this.tooltipRequested('tipDragSingle');
            },

            tooltipDragMultipleRequested() {
                return this.tooltipRequested('tipDragMultiple');
            },
        },

        watch: {
            trashed: {
                handler() {
                    this.removeLayers();
                },
                deep: true,
            },
        },

        methods: {
            getItemKey(e) {
                return e.state.placement.code;
            },

            removeLayers() {
                while (this.trashed.length > 0) {
                    const c = this.trashed.shift();

                    this.customizerStore.clearPlacement(this.currentController, c.state.placement.code);
                }
            },

            deleteSelected() {
                this.appStore.hideTooltips();

                let xrayId = null;

                if (this.selectedLayer != null) {
                    const component = this.currentController.selectedAtPlacement(this.selectedLayer.layer.code);

                    xrayId = component?.custom['ps-xray-id'];

                    this.customizerStore.clearPlacement(this.currentController, this.selectedLayer.layer.code);
                } else if (this.layersReversed.length > 0) {
                    const c = this.layersReversed[0];

                    const component = this.currentController.selectedAtPlacement(c.state.placement.code);

                    xrayId = component?.custom['ps-xray-id'];

                    this.customizerStore.clearPlacement(this.currentController, c.state.placement.code);
                }

                this.customizerStore.clearXRayed(xrayId);

                if (!this.appStore.anyLayerWasDeleted) {
                    this.customizerStore.trackAnalyticsEvent({
                        action: 'cyo_edit_deleted',
                    });
                }
            },

            updateOrder(e) {
                this.appStore.hideTooltips();

                const {
                    oldIndex,
                    newIndex,
                } = e;

                // The order of layers is reversed...

                this.customizerStore.moveLayer(this.currentController, this.layers.length - 1 - oldIndex, this.layers.length - 1 - newIndex);
            },

            selectLayer(layer) {
                this.appStore.hideTooltips();

                this.emitter.$emit('selectLayer', this.currentController, layer.state.placement.code);
            },

            startDrag() {
                this.appStore.hideTooltips();
            },

            getButtonStyle() {
                return {};
            },
        },
    };
</script>
